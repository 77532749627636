import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from "react-router-dom";
import axios from "axios";

import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

axios.interceptors.request.use(async (config) => {
    return {
        ...config,
        headers: {
            'X-Frame-Option': 'DENY',
            'Strict-Transport-Security': 'max-age=31536000; includeSubDomains; preload',
            'X-Content-Type-Options': 'DENY',
            'X-Powered-By': 'Hidden',
            'server': 'Hidden',
            'X-XSS-Protection': '1; mode=block',
            'client': process.env.REACT_APP_ENDPOINT,
            'Host': 'Hidden',
            'host': 'Hidden',
            'Content-Security-Policy': `default-src 'self' gyntools.com *.gyntools.com`,
            ...config.headers || {}
        }
    };
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});


ReactDOM.render(
    <Router>
      <App/>
    </Router>,
    document.getElementById("root")
  );
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
