import React, { Component } from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'

import './Modal.css'

class MyVerticallyCenteredModal extends Component {
  constructor(props){
    super(props)
    this.state = {}
  }
  render() {
    const closeOnly = this.props.closeonly === "true";
    const {handlemodalclose,handlemodalyes,handlemodalno,onHide,...rest} =  this.props;
      return (

        <Modal
          {...rest}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          onHide={() =>{}}
        >
          <Modal.Title className='modal-title'></Modal.Title>
          <Modal.Body className='modal-body'>
            {this.props.bodytext}
          </Modal.Body>
          <Modal.Footer>
            {
               closeOnly ?
                <div>
                  <Button className='close-button' onClick={this.props.handlemodalclose}>{this.props.closetext}</Button>
                </div>
                :
                <div className='modal-buttons'>
                  <Button className='agree-button' onClick={this.props.handlemodalyes}>{this.props.yestext}</Button>
                  <Button className='close-button' onClick={this.props.handlemodalno}>{this.props.notext}</Button>
                </div>
            }
  
          </Modal.Footer>
        </Modal>
      );
  }
}
export default MyVerticallyCenteredModal;
