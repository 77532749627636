import React, {useContext, useEffect, useState} from 'react';
import AppHeader from "./AppHeader/AppHeader";
import AppRouter from "./AppRouter/AppRouter";
import {useActor} from "@xstate/react";
import {BackendContext} from "../machines/BackendMachine";
import Test from "./Test/Test";
import Home from "./Home/Home";
import Login from "../RemasterComponents/Login/Login.jsx";
import bgrb from "../assets/bgrb.svg";
import bgrt from "../assets/bgrt.svg";
import bglt from "../assets/bglt.svg";
import {GlobalContext} from "../machines/GlobalStateMachine";
import {DeviceContext} from "../machines/DeviceMachine";
import {RouteContext} from "../machines/RoutingMachine";
import {TestContext} from "../machines/TestMachine";
import Config from "../services/Config";
import Forgot from "../pages/forgot";
import ForgotPassword from "./ForgotPassword/ForgotPassword";
import {useIdleTimer} from "react-idle-timer";

const Container = () => {


    const [isForgot,setIsForgot] = useState(false)

    const GContext = useContext(GlobalContext)
    const [GlobalCurrentState, UpdateGlobal] = useActor(GContext);

    const DContext = useContext(DeviceContext)
    const [DeviceCurrentStatus,SetStatus] = useActor(DContext);

    const BEContext = useContext(BackendContext)
    let BackendCurrent, BESend;
    [BackendCurrent, BESend] = useActor(BEContext);

    const RContext = useContext(RouteContext)
    const [RoutingCurrentState, Navigate] = useActor(RContext);

    const TContext = useContext(TestContext)
    const [TestCurrentState, UpdateTestState] = useActor(TContext);




    useEffect(()=>{
        const url = window.location.href;
        if(url.indexOf("forgot") > -1){
            setIsForgot(true)
        }else{
            setIsForgot(false)
        }
        localStorage.setItem("global-state",JSON.stringify(GlobalCurrentState));
        localStorage.setItem("device-state",JSON.stringify(DeviceCurrentStatus));
        localStorage.setItem("backend-state",JSON.stringify(BackendCurrent));
        localStorage.setItem("routing-state",JSON.stringify(RoutingCurrentState));
        localStorage.setItem("test-state",JSON.stringify(TestCurrentState));
    })

    return (
        <div >

            {
                Config.Debug
                ?
                    <>
                        <div className={"bg-white top-20 left-20 absolute text-red-500 text-20"}>!!! DEBUG !!!</div>
                        <div className={"bg-white top-20 right-20 absolute text-red-500 text-20"}>!!! DEBUG !!!</div>
                        <div className={"bg-white bottom-20 left-20 absolute text-red-500 text-20"}>!!! DEBUG !!!</div>
                        <div className={"bg-white bottom-20 right-20 absolute text-red-500 text-20"}>!!! DEBUG !!!</div>
                    </>
                :
                    null
            }

            {
                isForgot
                ?
                    <ForgotPassword/>
                :
                    !BackendCurrent.context.isAuthenticated
                    ?
                        <Login/>
                    :
                        <div>

                            {/*<div>*/}
                                <AppHeader/>
                                <AppRouter/>
                            {/*</div>*/}
                        </div>

            }
        </div>
    )
};
export default Container;
