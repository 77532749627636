
export default function CSV(){}

CSV.ExportToCSV = function ExportToCSV(title, tableId, fileName, AdditionalData) {

        // Variable to store the final csv data
        var csv_data = [];
        AdditionalData && AdditionalData.forEach(a => {
            csv_data.push(Object.keys(a)[0] + "," + a[Object.keys(a)[0]]);
        })
        let table = document.getElementById(tableId);
        // Get each row data
        var rows = table.rows

        for (var i = 0; i < rows.length; i++) {

            // Get each column data
            var cols = rows[i].querySelectorAll('td,th');

            // Stores each csv row data
            var csvrow = [];
            for (var j = 0; j < cols.length; j++) {

                // Get the text data of each cell of
                // a row and push it to csvrow
                csvrow.push(cols[j].innerHTML.replace(/<\/?[^>]+(>|$)/g, " "));
            }

            // Combine each column value with comma
            csv_data.push(csvrow.join(","));
        }


        // combine each row data with new line character
        csv_data = csv_data.join('\n');

        let CSVFile = new Blob([csv_data], {type: "text/csv"});

        // Create to temporary link to initiate
        // download process
        var temp_link = document.createElement('a');

        // Download csv file
        temp_link.download = title+".csv";
        var url = window.URL.createObjectURL(CSVFile);
        temp_link.href = url;

        // This link should not be displayed
        temp_link.style.display = "none";
        document.body.appendChild(temp_link);

        // Automatically click the link to trigger download
        temp_link.click();
        document.body.removeChild(temp_link);

        /* We will use this function later to download
        the data in a csv file downloadCSVFile(csv_data);
        */

}