
import {createMachine, MachineConfig, MachineOptions} from "xstate";
import {assign} from "xstate";
import React from "react";
import i18n from '../i18n.js'


//VALIDATIONS
const validatePhoneNumber = (input:any)=>{
    return !input.match(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/);
}
const validateNumber = (input:any)=>{
   return !input.match(/^[0-9]+$/);
}
const validateEmail = (email:any) => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email))
    {
        return (true)
    }
    return (false)
};
const emptyCheck =(inpt:string)=>{
    return inpt.length === 0
}
const isLetter =(c:string)=> {
    return (c >= 'a' && c <= 'z') ||
        (c >= 'A' && c <= 'Z')
}
const validatePassword =(password:string,rePassword:string,email:string)=>{
    let _isValid = false;
    let _minimumLength = password.length >= 8;
    let _containLetters = /[a-z]/i.test(password);
    let _containDigits = /[0-9]/i.test(password);
    let _containSpecialSymbols = /[!#$%&?]/.test(password)
    let _hasCapital = false;
    let _includeUserName = false;

    for(let i = 0 ; i < password.length ; i++){
        if(isLetter(password[i])){
            if (password[i] == password[i].toUpperCase()) {
                _hasCapital = true;
            }
        }
    }

    if(password.indexOf(email) > -1){
        _includeUserName = true
    }

    _isValid = _minimumLength && _containLetters && _containDigits && _containSpecialSymbols && _hasCapital && !_includeUserName;

    let _validationError = ""
    let _matchError = ""
    if(!_minimumLength){
        _validationError+= "Minimum 8 symbols"
    }
    else if(!_containLetters){
        _validationError+= "Must contain Letters"
    }
    else if(!_containDigits){
        _validationError+= "Must contain Digits"
    }
    else if(!_containSpecialSymbols){
        _validationError+= "Must Contain !#$%&?"
    }else if(!_hasCapital){
        _validationError+= "Must Contain Capital Letters"
    }else if(_includeUserName){
        _validationError+= "Must Not Contain Email"
    }

    let _isMatch = password === rePassword;

    if(!_isMatch){
        _matchError+= i18n.t('error.passwords-do-not-match')
    }
    if(!_isMatch || !_isValid){
        return {
            isError:true,
            notMatch:_isMatch,
            notValid:_isValid,
            validationError:_validationError,
            matchError:_matchError
        }
    }else{
        return {
            isError:false,
            notMatch:_isMatch,
            notValid:_isValid,
            validationError:"",
            matchError:""
        }
    }

}
//

//State machine schema
interface LoginSchema {
    initial:'login',
    states: {
        terms:{},
        login:{},
        forgotPassword:{},
        createUser:{},
    };
}
//Events
type LoginEvent =
    //Stages handler
    | { type : "NAV_TO_LOGIN"}
    | { type : "NAV_TO_CREATE_USER"}
    | { type : "NAV_TO_FORGOT_PASSWORD"}
    | { type : "NAV_TO_TERMS"}
    //Login
    | { type : "INPUT_LOGIN" ; data:object}
    | { type : "RESET_MACHINE"}
    | { type : "INPUT_PASSWORD" ; data:object}

    //Forgot Password
    | { type : "INPUT_EMAIL" ; data:object}

    //Create User
    | { type : "INPUT_RE_PASSWORD"; data:object}
    | { type : "INPUT_CLINIC_NAME"; data:object}
    | { type : "INPUT_CLINIC_ADDRESS"; data:object}
    | { type : "INPUT_CLINIC_CITY"; data:object}
    | { type : "INPUT_CLINIC_COUNTRY"; data:object}
    | { type : "INPUT_CLINIC_STATE"; data:object}
    | { type : "INPUT_CLINIC_ZIP"; data:object}
    | { type : "INPUT_CLINIC_PHONE"; data:object}
    | { type : "INPUT_FIRST_NAME"; data:object}
    | { type : "INPUT_LAST_NAME"; data:object}
    | { type : "CHECK_MANDATORY_FIELDS" , data:object}
//context
export interface LoginContextType{
    currentRoute:string,
    signUpPage:number,
    //login
    login:string,
    password:string,
    //forgot pass + create user
    rePassword:string,
    email:string,
    clinicName:string,
    clinicAddress:string,
    city:string,
    country:string,
    state:string,
    zip:string,
    phone:string,
    firstName:string,
    lastName:string,

    invalid_login           :any,
    invalid_password        :any,
    invalid_rePassword      :any,
    invalid_email           :any,
    invalid_clinicName      :any,
    invalid_clinicAddress   :any,
    invalid_city            :any,
    invalid_country         :any,
    invalid_state           :any,
    invalid_zip             :any,
    invalid_phone           :any,
    invalid_firstName       :any,
    invalid_lastName        :any,

    validation_error:boolean,
    validation_error_step_1:boolean,
    validation_error_step_2:boolean,
}

//machine config
const LoginMachineConfig : MachineConfig<any,any,any> =
    {
        id: "LoginMachine",
        context: {
            currentRoute:"login",
            signUpPage:0,
            login:"",
            password:"",
            rePassword:"",
            email:"",
            clinicName:"",
            clinicAddress:"",
            city:"",
            country:"",
            state:"None",
            zip:"",
            phone:"",
            firstName:"",
            lastName:"",

            invalid_login:null,
            invalid_password:null,
            invalid_rePassword:null,
            invalid_email:null,
            invalid_clinicName:null,
            invalid_clinicAddress:null,
            invalid_city:null,
            invalid_country:null,
            invalid_state:null,
            invalid_zip:null,
            invalid_phone:null,
            invalid_firstName:null,
            invalid_lastName:null,

            validation_error:false,
            validation_error_step_1:false,
            validation_error_step_2:false
        },
        initial: "login",
        states: {
            terms:{
                on:{
                    NAV_TO_CREATE_USER:{
                        actions:assign((ctx,evt)=>{
                            ctx.currentRoute = "createUser"
                        }),
                        target:"createUser"
                    }
                }
            },
            login:{
                on:{
                    RESET_MACHINE:{
                        actions:"resetContext"
                    },
                    INPUT_LOGIN : {
                        actions:assign((ctx,evt)=> {
                            ctx.invalid_login = null;
                            ctx.validation_error = false;
                            if(!validateEmail(evt.login)){
                                ctx.invalid_login = i18n.t("error.email-expected")
                            }
                            if(emptyCheck(evt.login)){
                                ctx.invalid_login = i18n.t('error.cannot-be-empty')
                                ctx.validation_error = true;
                            }
                            ctx.login = evt.login
                        })
                    },
                    INPUT_PASSWORD : {
                        actions:assign((ctx,evt)=> {
                            ctx.validation_error = null;
                            ctx.validation_error_step_2 = null;
                            ctx.invalid_password = null;
                            if(emptyCheck(evt.password)){
                                ctx.invalid_password = i18n.t('error.cannot-be-empty')
                                ctx.validation_error_step_2 = true;
                                ctx.validation_error = true;
                            }
                            ctx.password = evt.password
                        })
                    },
                    NAV_TO_FORGOT_PASSWORD:{
                        actions:assign((ctx,evt)=>{
                            ctx.currentRoute = "forgotPassword"
                        }),
                        target:"forgotPassword"
                    },
                    NAV_TO_CREATE_USER:{
                        actions:assign((ctx,evt)=>{
                            ctx.currentRoute = "createUser"
                        }),
                        target:"createUser"
                    },
                    NAV_TO_LOGIN:{
                        actions:assign((ctx,evt)=>{
                            ctx.currentRoute = "login"
                        }),
                        target:"login"
                    },
                }
            },
            forgotPassword:{
                on:{
                    RESET_MACHINE:{
                        actions:"resetContext"
                    },
                    INPUT_EMAIL:{
                        actions:assign((ctx,evt)=>{
                            ctx.invalid_email = null;
                            ctx.validation_error = false;
                            if(!validateEmail(evt.email)){
                                ctx.invalid_email = i18n.t("error.email-expected")
                            }
                            if(emptyCheck(evt.email)){
                                ctx.invalid_email = i18n.t('error.cannot-be-empty')
                                ctx.validation_error = true;
                            }
                            ctx.email = evt.email
                        })
                    },
                    NAV_TO_LOGIN:{
                        actions:assign((ctx,evt)=>{
                            ctx.currentRoute = "login"
                        }),
                        target:"login"
                    },
                    NAV_TO_CREATE_USER:{
                        actions:assign((ctx,evt)=>{
                            ctx.currentRoute = "createUser"
                        }),
                        target:"createUser"
                    }
                }
            },
            createUser:{

                on:{
                    RESET_MACHINE:{
                        actions:"resetContext"
                    },
                    CHECK_MANDATORY_FIELDS:
                        {
                            actions:assign((ctx,evt)=>
                            {
                                if(evt.data.pageNumber <= 1){
                                    if (emptyCheck(ctx.clinicName)) {
                                        ctx.invalid_clinicName = i18n.t('error.cannot-be-empty')
                                        ctx.validation_error_step_1 = true;
                                    }
                                    if (emptyCheck(ctx.clinicAddress)) {
                                        ctx.invalid_clinicAddress = i18n.t('error.cannot-be-empty')
                                        ctx.validation_error_step_1 = true;
                                    }
                                    if (emptyCheck(ctx.city)) {
                                        ctx.invalid_city = i18n.t('error.cannot-be-empty')
                                        ctx.validation_error_step_1 = true;
                                    }
                                    if (emptyCheck(ctx.country)) {
                                        ctx.invalid_country = i18n.t('error.cannot-be-empty')
                                        ctx.validation_error_step_1 = true;
                                    }
                                    if (emptyCheck(ctx.state)) {
                                        // ctx.invalid_state = i18n.t('error.cannot-be-empty')
                                        // ctx.validation_error_step_1 = true;
                                        ctx.state = "None"
                                    }
                                    if (validateNumber(ctx.zip)){
                                        ctx.invalid_zip = i18n.t('error.cannot-be-letters')
                                        ctx.validation_error_step_1 = true;
                                    }
                                    else if (emptyCheck(ctx.zip)) {
                                        ctx.invalid_zip = i18n.t('error.cannot-be-empty')
                                        ctx.validation_error_step_1 = true;
                                    }
                                    if (emptyCheck(ctx.phone)) {
                                        ctx.invalid_phone = i18n.t('error.cannot-be-empty')
                                        ctx.validation_error_step_1 = true;
                                    }
                                    if(!ctx.validation_error_step_1){
                                        ctx.signUpPage = evt.data.pageNumber;
                                    }
                                }
                                if(evt.data.pageNumber === 2)
                                {
                                    if (emptyCheck(ctx.firstName)) {
                                        ctx.invalid_firstName = i18n.t('error.cannot-be-empty')
                                        ctx.validation_error_step_2 = true;
                                    }
                                    if (emptyCheck(ctx.lastName)) {
                                        ctx.invalid_lastName = i18n.t('error.cannot-be-empty')
                                        ctx.validation_error_step_2 = true;
                                    }
                                    if(!validateEmail(ctx.email)){
                                        ctx.invalid_email = i18n.t("error.email-expected")
                                        ctx.validation_error_step_2 = true;
                                    }
                                    if (emptyCheck(ctx.email)) {
                                        ctx.invalid_email = i18n.t('error.cannot-be-empty')
                                        ctx.validation_error_step_2 = true;
                                    }

                                    if (emptyCheck(ctx.password)) {
                                        ctx.invalid_password = i18n.t('error.cannot-be-empty')
                                        ctx.validation_error_step_2 = true;
                                    }
                                    if (emptyCheck(ctx.rePassword)) {
                                        ctx.invalid_rePassword = i18n.t('error.cannot-be-empty')
                                        ctx.validation_error_step_2 = true;
                                    }
                                    let passwordMatchAndValid = validatePassword(ctx.password,ctx.rePassword,ctx.email);
                                    if(passwordMatchAndValid.isError){
                                        if(!passwordMatchAndValid.notMatch){
                                            ctx.invalid_rePassword = passwordMatchAndValid.matchError;
                                        }
                                        if(!passwordMatchAndValid.notValid){
                                            ctx.invalid_password = passwordMatchAndValid.validationError;
                                        }
                                        ctx.validation_error_step_2 = true;
                                    }
                                }
                            })
                        },
                    INPUT_CLINIC_NAME:{
                        actions:assign((ctx,evt)=>{
                            ctx.invalid_clinicName = null;
                            ctx.validation_error_step_1 = false;
                            if(emptyCheck(evt.clinicName)){
                                ctx.invalid_clinicName = i18n.t('error.cannot-be-empty')
                                ctx.validation_error_step_1 = true;
                            }
                            ctx.clinicName = evt.clinicName
                        })
                    },
                    INPUT_CLINIC_ADDRESS:{
                        actions:assign((ctx,evt)=>{
                            ctx.invalid_clinicAddress = null;
                            ctx.validation_error_step_1 = false;
                            if(emptyCheck(evt.clinicAddress)){
                                ctx.invalid_clinicAddress = i18n.t('error.cannot-be-empty')
                                ctx.validation_error_step_1 = true;
                            }
                            ctx.clinicAddress = evt.clinicAddress
                        })
                    },
                    INPUT_CITY:{
                        actions:assign((ctx,evt)=>{
                            ctx.invalid_city = null;
                            ctx.validation_error_step_1 = false;
                            if(emptyCheck(evt.city)){
                                ctx.invalid_city = i18n.t('error.cannot-be-empty')
                                ctx.validation_error_step_1 = true;
                            }
                            ctx.city = evt.city
                        })
                    },
                    INPUT_COUNTRY:{
                        actions:assign((ctx,evt)=>{
                            ctx.invalid_country = null;
                            ctx.validation_error_step_1 = false;
                            if(emptyCheck(evt.country)){
                                ctx.invalid_country = i18n.t('error.cannot-be-empty')
                                ctx.validation_error_step_1 = true;
                            }
                            ctx.country = evt.country
                        })
                    },
                    INPUT_STATE:{
                        actions:assign((ctx,evt)=>{
                            ctx.invalid_state = null;
                            ctx.validation_error_step_1 = false;
                            if(emptyCheck(evt.state)){
                                ctx.invalid_state = i18n.t('error.cannot-be-empty')
                                ctx.validation_error_step_1 = true;
                            }
                            ctx.state = evt.state
                        })
                    },
                    INPUT_ZIP:{
                        actions:assign((ctx,evt)=>{
                            ctx.invalid_zip = null;
                            ctx.validation_error_step_1 = false;
                            if (validateNumber(evt.zip)){
                                ctx.invalid_zip = i18n.t('error.cannot-be-letters')
                                ctx.validation_error_step_1 = true;
                            }
                            else if(emptyCheck(evt.zip)){
                                ctx.invalid_zip = i18n.t('error.cannot-be-empty')
                                ctx.validation_error_step_1 = true;
                            }
                            ctx.zip = evt.zip
                        })
                    },
                    INPUT_CLINIC_PHONE:{
                        actions:assign((ctx,evt)=>{
                            ctx.invalid_phone = null;
                            ctx.validation_error_step_1 = false;
                            if (validatePhoneNumber(evt.phone)){
                                ctx.invalid_phone = i18n.t('error.cannot-be-letters')
                                ctx.validation_error_step_1 = true;
                            }
                            if(emptyCheck(evt.phone)){
                                ctx.invalid_phone = i18n.t('error.cannot-be-empty')
                                ctx.validation_error_step_1 = true;
                            }
                            ctx.phone = evt.phone
                        })
                    },
                    INPUT_FIRST_NAME:{
                        actions:assign((ctx,evt)=>{
                            ctx.invalid_firstName = null;
                            ctx.validation_error_step_2 = false;
                            if(emptyCheck(evt.firstName)){
                                ctx.invalid_firstName = i18n.t('error.cannot-be-empty')
                                ctx.validation_error_step_2 = true;
                            }
                            ctx.firstName = evt.firstName
                        })
                    },
                    INPUT_LAST_NAME:{
                        actions:assign((ctx,evt)=>{
                            ctx.invalid_lastName = null;
                            ctx.validation_error_step_2 = false;
                            if(emptyCheck(evt.lastName)){
                                ctx.invalid_lastName = i18n.t('error.cannot-be-empty')
                                ctx.validation_error_step_2 = true;
                            }
                            ctx.lastName = evt.lastName
                        })
                    },
                    INPUT_EMAIL:{
                        actions:assign((ctx,evt)=>{
                            ctx.invalid_email = null;
                            ctx.validation_error_step_2 = false;

                            if(!validateEmail(evt.email)){
                                ctx.invalid_email = i18n.t("error.email-expected")
                                ctx.validation_error_step_2 = true;
                            }

                            if(emptyCheck(evt.email)){
                                ctx.invalid_email = i18n.t('error.cannot-be-empty')
                                ctx.validation_error_step_2 = true;
                            }
                            ctx.email = evt.email
                        })
                    },
                    INPUT_PASSWORD:{
                        actions:assign((ctx,evt)=>{
                            ctx.invalid_password = null;
                            ctx.validation_error = false;
                            ctx.validation_error_step_2 = false;
                            if(emptyCheck(evt.password)){
                                ctx.invalid_password = i18n.t('error.cannot-be-empty')
                                ctx.validation_error = true;
                                ctx.validation_error_step_2 = true;
                            }
                            ctx.password = evt.password
                        })
                    },
                    INPUT_RE_PASSWORD:{
                        actions:assign((ctx,evt)=>{
                            ctx.invalid_rePassword = null;
                            ctx.validation_error_step_2 = false;
                            if(emptyCheck(evt.rePassword)){
                                ctx.invalid_rePassword = i18n.t('error.cannot-be-empty')
                                ctx.validation_error_step_2 = true;
                            }
                            ctx.rePassword = evt.rePassword
                        })
                    },
                    NAV_TO_TERMS:{
                        actions:assign((ctx,evt)=>{
                            ctx.currentRoute = "terms"
                        }),
                        target:"terms"
                    },
                    NAV_TO_LOGIN:{
                        actions:assign((ctx,evt)=>{
                            ctx.currentRoute = "login"
                        }),
                        target:"login"
                    },
                }
            }
        }
    };

const LoginMachineOptions : MachineOptions<any,LoginEvent,any> =
    {
        activities: {},
        delays: {},
        guards: {},
        services: {},
        actions: {
            resetContext:assign((ctx)=>{
                ctx.login="";
                ctx.password="";
                ctx.rePassword="";
                ctx.email="";
                ctx.clinicName="";
                ctx.clinicAddress="";
                ctx.city="";
                ctx.country="";
                ctx.state="";
                ctx.zip="";
                ctx.phone="";
                ctx.firstName="";
                ctx.lastName="";
                ctx.invalid_login=null;
                ctx.invalid_password=null;
                ctx.invalid_rePassword=null;
                ctx.invalid_email=null;
                ctx.invalid_clinicName=null;
                ctx.invalid_clinicAddress=null;
                ctx.invalid_city=null;
                ctx.invalid_country=null;
                ctx.invalid_state=null;
                ctx.invalid_zip=null;
                ctx.invalid_phone=null;
                ctx.invalid_firstName=null;
                ctx.invalid_lastName=null;
                ctx.validation_error=false;
                ctx.validation_error_step_1=false;
                ctx.validation_error_step_2=false;
            })
        }
    }
//
export const LoginContext = React.createContext({});
export const LoginMachine = createMachine<LoginContextType,LoginEvent>(LoginMachineConfig,LoginMachineOptions)
