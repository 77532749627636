import React, {useContext} from 'react'
import GBigLabel from "./GBigLabel";
import myVideo from "../assets/gynd-movie.mp4";
import GSmallLabel from "./GSmallLabel";
import GButton from "./GButton";
import GTransparentButton from "./GTransparentButton";
import GModal from "./GModal";
import {GlobalContext} from "../machines/GlobalStateMachine";
import {useActor} from "@xstate/react";
import {useTranslation} from "react-i18next";
import PlayIcon from "../assets/play.svg"
import {BackendContext} from "../machines/BackendMachine";
import Config from "../services/Config";
import i18n from "i18next";
const TutorialVideo=()=>{
    const { t, i18n } = useTranslation()
    const GContext = useContext(GlobalContext)
    const [GlobalCurrentState, UpdateGlobal] = useActor(GContext);

    const BEContext = useContext(BackendContext)
    const [BackendCurrentState, Send] = useActor(BEContext);

    return(
        <GModal  handleClose={()=>{ UpdateGlobal({type:"CLOSE_TUTORIAL_VIDEO"})}}>
            <div className={" lg:w-64.375rem lg:pr-13.625rem lg:pl-13.625rem"}>
                <GBigLabel className={"sm:text-2rem mt-1.5rem text-center"}>
                    {t("tutorial.title")}
                </GBigLabel>
                <GBigLabel className={"sm:text-1.25rem text-center"}>
                    {t("tutorial.sub-title")}
                </GBigLabel>
                <div className={"lg:mt-3rem lg:h-22rem "}>
                    <video poster={PlayIcon}  className={"rounded-3xl shadow-gyni border"} width="100%"  controls="controls" preload="metadata">
                        <source  src={`https://${Config.S3PublicBucket}.s3.${Config.region}.amazonaws.com/gynd-+movie.mp4#t=1.5`}  poster="redled.png" type="video/mp4"/>
                    </video>
                </div>
                <div className={"pr-1.125rem pl-1.125rem mt-4 sm:mt-2.813rem"}>
                    <GSmallLabel className={"text-0.875rem "}>
                        {t("tutorial.p1")}
                    </GSmallLabel>
                    {/*<GSmallLabel className={"text-0.875rem"}>*/}
                    {/*    by clicking on the Help link above.*/}
                    {/*</GSmallLabel>*/}
                </div>
                {
                    GlobalCurrentState.context.currentGlobalState === "tutorialVideo"
                    ?
                        <div className={"mt-4 sm:mt-4.375rem sm:pl-10.5rem sm:pr-10.5rem"}>
                            {
                               BackendCurrentState.context.role.indexOf("SiteAdmin") > -1
                                ?
                                   <GButton onClick={()=>{
                                       UpdateGlobal({type:"CLOSE_TUTORIAL_VIDEO"})
                                       UpdateGlobal({type:"SET_CONNECT_TO_SCANNER"})
                                   }} className={"h-3.125rem"}>
                                       {i18n.t("general.connect-your-scanner")}
                                   </GButton>
                               :
                                   null
                            }

                            <GTransparentButton onClick={()=>UpdateGlobal({type:"CLOSE_TUTORIAL_VIDEO"})} className={"h-3.125rem"}>
                                {i18n.t("general.skip")}
                            </GTransparentButton>
                        </div>
                    :
                        null
                }

            </div>
        </GModal>
    )
}
export default TutorialVideo
