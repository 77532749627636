import React from 'react';
import GSmallLabel from "./GSmallLabel";

const GIconLabelButton = (props) => {
    return (
        <div onClick={props.onClick} className={" cursor-pointer flex "+props.className}>
            <img className={"w-1.103rem mt-auto mb-auto mr-0.636rem"} src={props.iconImage} alt={"icon m-auto"}/>
            <GSmallLabel  className={" mt-auto mb-auto "} color={props.textColor}>{props.text}</GSmallLabel>
        </div>
    )
};
export default GIconLabelButton;
