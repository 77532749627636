import React, {Component, useContext, useEffect, useState} from 'react'
import axios from "axios";
import Config from "../../services/Config";
import {BackendContext} from "../../machines/BackendMachine";
import {useActor} from "@xstate/react";
import GSmallLabel from "../../RemasterComponents/GSmallLabel";

const AdminSystemSettingsPage = () => {

    const BEContext = useContext(BackendContext)
    let BackendCurrent, BESend;
    [BackendCurrent, BESend] = useActor(BEContext);

    const [settings,SetSettings]=useState(null)
    const [selectedSetting,SetSelectedSetting] = useState(null)
    const [valueCandidate,SetValueCandidate] = useState(null)
    useEffect(()=>{
        ReadSettings();
    },[])

    const ReadSettings=()=>{

        axios.get(`${Config.globalUrl}:${Config.globalPort}/api/v1/webapp/general/d_update`,{headers: {'Authorization': BackendCurrent.context.authToken}})
            .then(async (response) => {
                SetSettings(response.data && response.data.sort((a,b) => {
                    if(a.Name > b.Name) return 1;
                    if(a.Name < b.Name) return -1;
                    return 0;
                }))
            })
            .catch( async (error) => {
                console.log(error);
            });
    }

    const UpdateSetting=(setting)=>{

        if(valueCandidate){
            setting.Val = valueCandidate
        }

        axios.post(`${Config.globalUrl}:${Config.globalPort}/api/v1/webapp/general/${BackendCurrent.context.profile.userId}/d_update`,setting,{headers: {'Authorization': BackendCurrent.context.authToken}})
            .then(async (response) => {
                ReadSettings()
                SetSelectedSetting(null)
                SetValueCandidate(null)
            })
            .catch( async (error) => {
                console.log(error);
            });
    }
    return(
        <div>
            <div className={"clinic-details-height-responsive pt-2"}>

                <div className={"  ml-10 sm:ml-6.25rem mr-10 sm:mr-6.25rem mb-3.75rem shadow-gyni rounded-2xl"}>
                    <div className={"lg:flex"}>
                        <div className={"pl-20 pr-20 pt-5 pb-5 w-full space-y-4"}>
                            <table className={"table-auto text-sm w-full border"}>
                                <thead className={"border-bottom"}>
                                    <tr>
                                        <th className={"bg-gray-200 border-white border-2 pl-1"}>Parameter</th>
                                        <th className={"bg-gray-200 border-white border-2 pl-1"}>Value</th>
                                        <th className={"bg-gray-200 border-white border-2 pl-1"}>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {
                                    settings && settings.map(p=>
                                        <tr className={" bg-gray-100  hover:bg-gray-300 cursor-pointer "+(  selectedSetting === p.Name  ? " bg-gray-300  " : " ")}>
                                            <td className={"border-white border-2 pl-1 hover:bg-gyniCyan"}>
                                                {
                                                    p.Name
                                                }
                                            </td>
                                            {
                                                selectedSetting !== p.Name
                                                ?
                                                    <>
                                                        <td className={"border-white border-2 pl-1 hover:bg-gyniCyan"}>
                                                            {
                                                                p.Val
                                                            }
                                                        </td>
                                                        <td className={"border-white border-2 pl-1 hover:bg-gyniCyan flex"}>
                                                            <div className={" cursor-pointer shadow-md border p-2 rounded-xl w-20 flex justify-content-center"} onClick={()=>{
                                                                SetSelectedSetting(p.Name)
                                                                SetValueCandidate(null)
                                                            }}>
                                                            Edit
                                                            </div>
                                                        </td>
                                                    </>

                                                :
                                                    <>
                                                        <td className={"border-white border-2 pl-1 hover:bg-gyniCyan"}>
                                                            <input type={"number"} className={"text-black"} defaultValue={p.Val} onChange={(e)=>SetValueCandidate(e.target.value.replace(/[^0-9]/g, ''))}/>
                                                        </td>
                                                        <td className={"border-white border-2 pl-1 hover:bg-gyniCyan flex space-x-2 m"} >
                                                            <div className={" border shadow-md cursor-pointer p-2 rounded-xl w-20 flex justify-content-center"} onClick={()=>UpdateSetting(p)}>
                                                                Save
                                                            </div>
                                                            <div className={" border shadow-md cursor-pointer p-2  rounded-xl w-20 flex justify-content-center"} onClick={()=>{
                                                                SetSelectedSetting(null)
                                                                SetValueCandidate(null)
                                                            }}>
                                                                Cancel
                                                            </div>
                                                        </td>

                                                    </>

                                            }

                                        </tr>)
                                }

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    )
}

export default AdminSystemSettingsPage;

