import React from "react";
import { Button } from "react-bootstrap";
import "./LoaderButton.css";
import Spinner from './Spinner';
// eslint-disable-next-line import/no-anonymous-default-export
export default ({
    isLoading,
    text,
    loadingText,
    className = "",
    disabled = false,
    ...props
}) =>
    <Button
        className={`LoaderButton ${className}`}
        disabled={disabled || isLoading}
        {...props}
    >
        {isLoading && <Spinner/>}
        {!isLoading ? text : loadingText}
    </Button>;
