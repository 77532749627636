import React, {useContext, useState} from 'react';
import CloseButton from "../assets/close_svg.svg"
import DeviceIssueIcon from "../assets/device_issue.png";
import GSmallLabel from "./GSmallLabel";
import GBigLabel from "./GBigLabel";
import GButton from "./GButton";
import GTransparentButton from "./GTransparentButton";

const GProgressModal = (props) => {

    const [isModalOpen,setModalOpen] = useState(true);
    return (
        <div>
            {
                isModalOpen
                    ?
                    <div style={{zIndex:10}} className={" z-10 h-full w-full fixed top-0 g-modal-overlay w-full   bg-#00163A backdrop-filter backdrop-blur flex"}>

                        <div className={"m-auto "+props.className}>
                            <GSmallLabel bold className={"text-1.125rem text-white flex   mb-3.063rem"}>
                                <div className={"text-center m-auto"}>
                                    {
                                        props.text
                                    }
                                </div>
                            </GSmallLabel>
                            <div className={"rounded-xl  h-0.688rem  bg-white"}>
                                <div style={{width:(props.value?props.value:0)+"%"}} className={"rounded-xl shadow-pbYellow h-full bg-#CAD453"}>

                                </div>
                                <div className={"w-full text-right text-1.125rem text-white mt-2"}>
                                    {
                                        (props.value?props.value:0)+"%"
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    null
            }
        </div>
    )
};
export default GProgressModal;

