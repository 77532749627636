
import {createMachine, MachineConfig, MachineOptions} from "xstate";
import {assign} from "xstate";
import React from "react";


//State machine schema
interface GlobalSchema {
    initial:'idle',
    states: {
        idle:{},
        inTest:{
            initial:"onTop",
            states:{
                onTop:{},
                minimized:{}
            }
        },

    };
}
//Events
type GlobalEvent =
    | { type : "SET_IDLE" }
    | { type : "SET_IN_TEST" }
    | { type : "SET_ON_TOP" }
    | { type : "SET_MINIMIZED" }
    | { type : "SET_FIRST_LOGIN" ,isFirstLogin:boolean}
    | { type : "SET_TUTORIAL_VIDEO"}
    | { type : "CLOSE_TUTORIAL_VIDEO"}
    | { type : "SET_EMAIL_POPUP",testSummaryNumber:number}
    | { type : "CLOSE_EMAIL_POPUP"}
    | { type : "SET_CONNECT_TO_SCANNER"}
    | { type : "CLOSE_CONNECT_TO_SCANNER"}
    | { type : "SET_LANGUAGE_SELECTOR"}
    | { type : "CLOSE_LANGUAGE_SELECTOR"}
    | { type : "SET_NEW_DEVICE_SERIAL_NUMBER" , NewDeviceSerialNumber:string}
    | { type : "SET_RESET_PASSWORD"}
    | { type : "CLOSE_MUST_RESET_PASSWORD"}
    | { type : "SET_MUST_RESET_PASSWORD"}
    | { type : "CLOSE_RESET_PASSWORD"}
    | { type : "RESET_MACHINE" }

//context
export interface GlobalContextType{
    backGlobalState:string,
    currentGlobalState:string,
    NewDeviceSerialNumber:string,
    isFirstLogin:boolean
}

//machine config
const GlobalMachineConfig : MachineConfig<any,GlobalSchema,GlobalEvent> =
    {
        id: "GlobalMachine",
        context: {
            currentGlobalState:"idle",
            isFirstLogin:false,
            NewDeviceSerialNumber:null
        },
        initial: "idle",
        states: {
            idle:{
                on:{
                    SET_FIRST_LOGIN:{
                      actions:assign((ctx,evt)=>{
                        ctx.isFirstLogin = evt.isFirstLogin;
                      })
                    },
                    SET_IN_TEST:{
                        actions:assign((ctx)=>{
                            ctx.currentGlobalState = "onTop"
                        }),
                        target:"inTest",
                    },
                    SET_TUTORIAL_VIDEO:{
                        actions:assign((ctx)=>{
                            ctx.backGlobalState = ctx.currentGlobalState
                            ctx.currentGlobalState = "tutorialVideo"
                        }),
                    },
                    CLOSE_TUTORIAL_VIDEO:{
                        actions:assign((ctx)=>{
                            ctx.currentGlobalState = ctx.backGlobalState
                        }),
                    },
                    SET_EMAIL_POPUP:{
                        actions:assign((ctx,evt)=>{
                            ctx.backGlobalState = ctx.currentGlobalState
                            ctx.currentGlobalState = "emailPopup"
                            ctx.testSummaryNumber = evt.testSummaryNumber
                        })
                    },
                    CLOSE_EMAIL_POPUP:{
                        actions:assign((ctx)=>{
                            ctx.currentGlobalState = ctx.backGlobalState
                            ctx.testSummaryNumber = null
                        }),
                    },
                    SET_LANGUAGE_SELECTOR:{
                        actions:assign((ctx)=>{
                            ctx.backGlobalState = ctx.currentGlobalState
                            ctx.currentGlobalState = "languageSelector"
                        }),
                    },
                    CLOSE_LANGUAGE_SELECTOR:{
                        actions:assign((ctx)=>{
                            ctx.currentGlobalState = ctx.backGlobalState
                        }),
                    },
                    SET_MUST_RESET_PASSWORD:{
                        actions:assign((ctx)=>{
                            ctx.backGlobalState = ctx.currentGlobalState
                            ctx.currentGlobalState = "mustResetPassword"
                        }),
                    },
                    CLOSE_MUST_RESET_PASSWORD:{
                        actions:assign((ctx)=>{
                            ctx.currentGlobalState = ctx.backGlobalState
                        }),
                    },
                    SET_RESET_PASSWORD:{
                        actions:assign((ctx)=>{
                            ctx.backGlobalState = ctx.currentGlobalState
                            ctx.currentGlobalState = "resetPassword"
                        }),
                    },
                    CLOSE_RESET_PASSWORD:{
                        actions:assign((ctx)=>{
                            ctx.currentGlobalState = ctx.backGlobalState
                        }),
                    },

                    SET_CONNECT_TO_SCANNER:{
                        actions:assign((ctx)=>{
                            ctx.backGlobalState = ctx.currentGlobalState
                            ctx.currentGlobalState = "connectToScanner"
                        }),
                    },
                    CLOSE_CONNECT_TO_SCANNER:{
                        actions:assign((ctx)=>{
                            ctx.currentGlobalState = ctx.backGlobalState
                        }),
                    },
                    SET_NEW_DEVICE_SERIAL_NUMBER:{
                        actions:assign((ctx,evt)=>{
                            ctx.NewDeviceSerialNumber = evt.NewDeviceSerialNumber
                        }),
                    },
                    RESET_MACHINE:{
                        actions:assign((ctx,evt)=>{
                            ctx.currentGlobalState = "idle"
                            ctx.isFirstLogin = false
                            ctx.NewDeviceSerialNumber = null
                        }),
                    }
                }
            },
            inTest:{
                initial:"onTop",
                on:{
                    SET_IDLE:{
                        actions:assign((ctx)=>{
                            ctx.currentGlobalState = "idle"
                        }),
                        target:"idle",
                    }
                },
                states:{
                    onTop: {
                        on: {
                            SET_MINIMIZED: {
                                actions: assign((ctx) => {
                                    ctx.currentGlobalState = "minimized"
                                }),
                                target: "minimized",
                            },
                            SET_LANGUAGE_SELECTOR:{
                                actions:assign((ctx)=>{
                                    ctx.backGlobalState = ctx.currentGlobalState
                                    ctx.currentGlobalState = "languageSelector"
                                }),
                            },
                            CLOSE_LANGUAGE_SELECTOR:{
                                actions:assign((ctx)=>{
                                    ctx.currentGlobalState = ctx.backGlobalState
                                }),
                            },
                            SET_MUST_RESET_PASSWORD:{
                                actions:assign((ctx)=>{
                                    ctx.backGlobalState = ctx.currentGlobalState
                                    ctx.currentGlobalState = "mustResetPassword"
                                }),
                            },
                            CLOSE_MUST_RESET_PASSWORD:{
                                actions:assign((ctx)=>{
                                    ctx.currentGlobalState = ctx.backGlobalState
                                }),
                            },
                            SET_RESET_PASSWORD:{
                                actions:assign((ctx)=>{
                                    ctx.backGlobalState = ctx.currentGlobalState
                                    ctx.currentGlobalState = "resetPassword"
                                }),
                            },
                            CLOSE_RESET_PASSWORD:{
                                actions:assign((ctx)=>{
                                    ctx.currentGlobalState = ctx.backGlobalState
                                }),
                            },


                            SET_CONNECT_TO_SCANNER:{
                                actions:assign((ctx)=>{
                                    ctx.backGlobalState = ctx.currentGlobalState
                                    ctx.currentGlobalState = "connectToScanner"
                                }),
                            },
                            CLOSE_CONNECT_TO_SCANNER:{
                                actions:assign((ctx)=>{
                                    ctx.currentGlobalState = ctx.backGlobalState
                                }),
                            },
                            SET_NEW_DEVICE_SERIAL_NUMBER:{
                                actions:assign((ctx,evt)=>{
                                    ctx.NewDeviceSerialNumber = evt.NewDeviceSerialNumber
                                }),
                            },
                            SET_TUTORIAL_VIDEO:{
                                actions:assign((ctx)=>{
                                    ctx.backGlobalState = ctx.currentGlobalState
                                    ctx.currentGlobalState = "tutorialVideo"
                                }),
                            },
                            CLOSE_TUTORIAL_VIDEO:{
                                actions:assign((ctx)=>{
                                    ctx.currentGlobalState = ctx.backGlobalState
                                }),
                            },
                            SET_EMAIL_POPUP:{
                                actions:assign((ctx,evt)=>{
                                    ctx.backGlobalState = ctx.currentGlobalState
                                    ctx.currentGlobalState = "emailPopup"
                                    ctx.testSummaryNumber = evt.testSummaryNumber
                                })
                            },
                            CLOSE_EMAIL_POPUP:{
                                actions:assign((ctx)=>{
                                    ctx.currentGlobalState = ctx.backGlobalState
                                    ctx.testSummaryNumber = null
                                }),
                            },
                        }
                    },
                    minimized:{
                        on:{
                            SET_ON_TOP: {
                                actions: assign((ctx) => {
                                    ctx.currentGlobalState = "onTop"
                                }),
                                target: "onTop",
                            }
                            ,
                            SET_LANGUAGE_SELECTOR:{
                                actions:assign((ctx)=>{
                                    ctx.backGlobalState = ctx.currentGlobalState
                                    ctx.currentGlobalState = "languageSelector"
                                }),
                            },
                            CLOSE_LANGUAGE_SELECTOR:{
                                actions:assign((ctx)=>{
                                    ctx.currentGlobalState = ctx.backGlobalState
                                }),
                            },
                            SET_MUST_RESET_PASSWORD:{
                                actions:assign((ctx)=>{
                                    ctx.backGlobalState = ctx.currentGlobalState
                                    ctx.currentGlobalState = "mustResetPassword"
                                }),
                            },
                            CLOSE_MUST_RESET_PASSWORD:{
                                actions:assign((ctx)=>{
                                    ctx.currentGlobalState = ctx.backGlobalState
                                }),
                            },
                            SET_RESET_PASSWORD:{
                                actions:assign((ctx)=>{
                                    ctx.backGlobalState = ctx.currentGlobalState
                                    ctx.currentGlobalState = "resetPassword"
                                }),
                            },
                            CLOSE_RESET_PASSWORD:{
                                actions:assign((ctx)=>{
                                    ctx.currentGlobalState = ctx.backGlobalState
                                }),
                            },


                            SET_CONNECT_TO_SCANNER:{
                                actions:assign((ctx)=>{
                                    ctx.backGlobalState = ctx.currentGlobalState
                                    ctx.currentGlobalState = "connectToScanner"
                                }),
                            },
                            CLOSE_CONNECT_TO_SCANNER:{
                                actions:assign((ctx)=>{
                                    ctx.currentGlobalState = ctx.backGlobalState
                                }),
                            },
                            SET_NEW_DEVICE_SERIAL_NUMBER:{
                                actions:assign((ctx,evt)=>{
                                    ctx.NewDeviceSerialNumber = evt.NewDeviceSerialNumber
                                }),
                            },
                            SET_TUTORIAL_VIDEO:{
                                actions:assign((ctx)=>{
                                    ctx.backGlobalState = ctx.currentGlobalState
                                    ctx.currentGlobalState = "tutorialVideo"
                                }),
                            },

                            CLOSE_TUTORIAL_VIDEO:{
                                actions:assign((ctx)=>{
                                    ctx.currentGlobalState = ctx.backGlobalState
                                }),
                            },
                            SET_EMAIL_POPUP:{
                                actions:assign((ctx,evt)=>{
                                    ctx.backGlobalState = ctx.currentGlobalState
                                    ctx.currentGlobalState = "emailPopup"
                                    ctx.testSummaryNumber = evt.testSummaryNumber
                                })
                            },
                            CLOSE_EMAIL_POPUP:{
                                actions:assign((ctx)=>{
                                    ctx.currentGlobalState = ctx.backGlobalState
                                    ctx.testSummaryNumber = null
                                }),
                            }
                        }
                    }
                }
            }
        }
    };

const GlobalMachineOptions : MachineOptions<GlobalContextType,GlobalEvent,any> =
    {
        activities: {},
        delays: {},
        guards: {},
        services: {},
        actions: {}
    }
//
export const GlobalContext = React.createContext({});
export const GlobalMachine = createMachine<GlobalContextType,GlobalEvent>(GlobalMachineConfig,GlobalMachineOptions)
