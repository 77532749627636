import React from 'react';

const Bullet = () => {
    return (
        <div className={"h-full mt-1.5 mb-auto pr-4"}>
            <div className={"w-0.313rem h-0.313rem bg-#50C2BC rounded-full"}/>
        </div>
    )
};
export default Bullet;
