import React, {useState} from "react"

const GRadioButton =(props)=>{

    return(
        <div className={"ml-0.188rem mr-0.188rem"} onClick={props.onClick}>
            {
                props.checked
                ?
                    <div className={"h-2 w-2 bg-#1396B4 rounded-full"}/>
                :
                    <div className={"h-2 w-2 bg-RadioGray rounded-full"}/>
            }
        </div>
    )
}
export default GRadioButton;
