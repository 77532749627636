
import React, {useEffect, useState} from 'react';
import GSmallLabel from "./GSmallLabel";

const GMessagesWindow = (props) => {

    const [Messages,setMessage] = useState([])
    const [messagesOpen,setMessagesOpen] = useState(true)
    const [Updates,setUpdates] = useState([])
    const [updatesOpen,setUpdatesOpen] = useState(true)
    const [Promos,setPromos] = useState([])
    const [promosOpen,setPromosOpen] = useState(true)

    const RenderMessageArray =(label,array,messageOpen,SetMessageOpen)=>{
        return <div>
            {
                array.length > 0
                    ?
                    <div>
                        <div className={"cursor-pointer border-gray-300 border-b"} onClick={()=>SetMessageOpen(!messageOpen)}>
                            {
                                array.filter(m=>m.isNew).length > 0
                                    ?
                                    <GSmallLabel bold >{label}({array.filter(m=>m.isNew).length})</GSmallLabel>
                                    :
                                    <GSmallLabel >{label}</GSmallLabel>
                            }
                        </div>
                        {
                            messageOpen
                                ?
                                <div className={"overflow-auto max-h-40 border-t bg-gray-100 border-b border-gray-300 p-2 space-y-4"}>
                                    {
                                        array.map(
                                            m=><div className={" flex place-items-center space-x-1"}>
                                                {
                                                    m.isNew
                                                        ?
                                                        <div className={"w-2 h-2 rounded-full bg-#1396B4"}/>
                                                        :
                                                        null
                                                }
                                                <GSmallLabel className={"w-full bg-gray-300 rounded-md pr-2 pl-2"}>{m.Message}</GSmallLabel>
                                            </div>
                                        )
                                    }
                                </div>
                                :
                                null
                        }

                    </div>
                    :
                    null
            }
        </div>
    }
    const CleanNew=(array)=>{
        let t_messages = array;
        let res = []
        t_messages.forEach(m=> {
            let t_m = m;
            t_m.isNew = false;
            res.push(t_m)
        })
        return res;
    }
    const MarkAllAsRead=()=>{
        setMessage(CleanNew(Messages))
        setUpdates(CleanNew(Updates))
        setPromos(CleanNew(Promos))
    }
    const ViewAll=()=>{
        setMessagesOpen(true)
        setUpdatesOpen(true)
        setPromosOpen(true)
    }
    const MarkAllAsUnread=()=>{
        localStorage.setItem('Messages',"[]")
        localStorage.setItem('Updates',"[]")
        localStorage.setItem('Promos',"[]")
    }

    const SyncMessagesLocally=(localStorageItemName,array,setHandler)=>{
        let t_messages   = JSON.parse(localStorage.getItem(localStorageItemName))
        if(t_messages){

            let _resMessages = []
            let deepClonedMessages = [];

            array.forEach(m=>{
                if(t_messages.filter(x=>x.Message === m.Message).length === 0){
                    m.isNew = true;
                }
                _resMessages.push(m)
            })

            deepClonedMessages = JSON.parse(JSON.stringify(_resMessages))

            setHandler(deepClonedMessages)

            _resMessages.forEach(x=>{
                if(x.isNew){
                    delete x.isNew;
                    t_messages.push(x)
                }
            })

            localStorage.setItem(localStorageItemName,JSON.stringify(_resMessages))

        }else{

            localStorage.setItem(localStorageItemName,JSON.stringify(array))

            let _msgArray = [];
            array.forEach(m=>{
                let _m = m;
                _m.isNew = true;
                _msgArray.push(_m)
            })
            setHandler(_msgArray)
        }
    }

    useEffect( ()=>{
        SyncMessagesLocally("Messages",props.messageArray.filter(x=>x.MessageType === "Message"),setMessage)
        SyncMessagesLocally("Updates",props.messageArray.filter(x=>x.MessageType === "Update"),setUpdates)
        SyncMessagesLocally("Promos",props.messageArray.filter(x=>x.MessageType === "Promo"),setPromos)
    },[props.messageArray])

    return (
        <div className={"resizable-both space-y-2 absolute right-0 top-20 border w-1/3 rounded-xl pt-4 pb-4 pr-3 pl-3  bg-white shadow-md"}>
            <div onClick={()=>props.close()} className={"cursor-pointer absolute right-5 "}>X</div>
            <div className={"space-y-4"}>
                {
                    RenderMessageArray("Messages", Messages,messagesOpen,setMessagesOpen)
                }
                {
                    RenderMessageArray("Updates",Updates,updatesOpen,setUpdatesOpen)
                }
                {
                    RenderMessageArray("Promos",Promos,promosOpen,setPromosOpen)
                }
            </div>
            {/*<GSmallLabel onClick={()=>MarkAllAsUnread()} className={"cursor-pointer w-full flex justify-content-start"} bold color={"text-#1396B4"}> Mark all as un-read</GSmallLabel>*/}

            <div className={"flex"}>
                {
                    Messages.filter(m=>m.isNew).length > 0 ||
                    Updates.filter(m=>m.isNew).length > 0 ||
                    Promos.filter(m=>m.isNew).length > 0
                    ?
                        <GSmallLabel onClick={()=>MarkAllAsRead()} className={"cursor-pointer w-full flex justify-content-start"} bold color={"text-#1396B4"}> Mark all as read</GSmallLabel>
                    :
                        null
                }
                {
                    !messagesOpen || !updatesOpen || !promosOpen
                    ?
                        <GSmallLabel onClick={()=>ViewAll()} className={"cursor-pointer w-full flex justify-content-end"} bold color={"text-#1396B4"}> View All </GSmallLabel>
                    :
                        null
                }
            </div>
        </div>
    )

};
export default GMessagesWindow;
