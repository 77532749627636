import React from 'react';
import GSmallLabel from "./GSmallLabel";
import iks from "../assets/x.svg"
const GBaloonDiv = (props) => {
    return (
            <div className={"flex mr-0.438rem mb-0.438rem whitespace-nowrap h-1.938rem max-w-min  pr-0.625rem pl-0.625rem bg-GBaloonDiv rounded-3xl"}>
                <GSmallLabel className={"text-0.875rem h-full leading-8"}>
                    {
                        props.children
                    }
                </GSmallLabel>
                {
                    props.handleClose
                    ?
                        <div  className={"w-2 m-auto"}>
                            <img onCLick={()=>props.handleClose()} className={"ml-1 cursor-pointer"} src={iks} alt={"close"}/>
                        </div>
                        :null
                }

            </div>

    )
};
export default GBaloonDiv;
