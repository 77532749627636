
import {createMachine, MachineConfig, MachineOptions} from "xstate";
import {assign} from "xstate";
import React from "react";

const INITIAL_STATE = "home";
//State machine schema
interface RouteSchema {
    // initial:'statistics',
    states: {
        login:{},
        home:{},
        statistics:{},
        privacyAndPolicy:{},
        termsAndConditions:{},
        contactUs:{},
        clinic:{} //clinical details
    };
}
//Events
type RouteEvent =
| { type : "NAV_TO_HOME" ; data:object}
| { type : "NAV_TO_STATISTICS" ; data:object}
| { type : "NAV_TO_ADMIN"; data:object}
| { type : "NAV_TO_CLINIC_DETAILS" ; data:object}
| { type : "NAV_TO_PRIVACY_AND_POLICY" ; data:object}
| { type : "NAV_TO_CONTACT_US" ; data:object}
| { type : "NAV_TO_TERMS_AND_CONDITIONS" ; data:object}
| { type : "RESTORE_ROUTING_MACHINE",context:object}
| { type : "RESET_MACHINE"}

//context
export interface RouteContextType{
    currentRoute:string
}

//machine config
const RoutingMachineConfig : MachineConfig<any,RouteSchema,RouteEvent> =
    {
        id: "RoutingMachine",
        context: {
            currentRoute:INITIAL_STATE,
        },
        initial: INITIAL_STATE,
        states: {

            login:{
                on:{
                    RESTORE_ROUTING_MACHINE:{
                      actions:assign((ctx,evt)=>{

                      })
                    },
                    NAV_TO_HOME:{
                        actions:assign((ctx)=>{
                            ctx.currentRoute = "home"
                        }),
                        target:"home",
                    },
                    RESET_MACHINE:{
                        actions:assign((ctx)=>{
                            ctx.currentRoute = INITIAL_STATE
                        }),
                    }
                }
            },
            home:{
                on:{
                    NAV_TO_ADMIN:{
                      actions:assign((ctx)=>{
                          ctx.currentRoute = "admin"
                      })
                    },
                    NAV_TO_STATISTICS:{
                        actions:assign((ctx)=>{
                            ctx.currentRoute = "statistics"
                        }),
                        target:"statistics",
                    },
                    NAV_TO_CLINIC_DETAILS:{
                        actions:assign((ctx)=>{
                            ctx.currentRoute = "clinic"
                        }),
                        target:"clinic",
                    },
                    NAV_TO_TERMS_AND_CONDITIONS:{
                        actions:assign((ctx)=>{
                            ctx.currentRoute = "termsAndConditions"
                        }),
                        target:"termsAndConditions",
                    },
                    NAV_TO_CONTACT_US:{
                        actions:assign((ctx)=>{
                            ctx.currentRoute = "contactUs"
                        }),
                        target:"contactUs",
                    },
                    NAV_TO_PRIVACY_AND_POLICY:{
                        actions:assign((ctx)=>{
                            ctx.currentRoute = "privacyAndPolicy"
                        }),
                        target:"privacyAndPolicy",
                    },
                    RESET_MACHINE:{
                        actions:assign((ctx)=>{
                            ctx.currentRoute = INITIAL_STATE
                        }),
                    }
                }
            },
            statistics:{
                on:{
                    NAV_TO_ADMIN:{
                        actions:assign((ctx)=>{
                            ctx.currentRoute = "admin"
                        })
                    },
                    NAV_TO_HOME:{
                        actions:assign((ctx)=>{
                            ctx.currentRoute = "home"
                        }),
                        target:"home",
                    },
                    NAV_TO_CLINIC_DETAILS:{
                        actions:assign((ctx)=>{
                            ctx.currentRoute = "clinic"
                        }),
                        target:"clinic",
                    },
                    NAV_TO_STATISTICS:{
                        actions:assign((ctx)=>{
                            ctx.currentRoute = "statistics"
                        }),
                        target:"statistics",
                    },
                    NAV_TO_TERMS_AND_CONDITIONS:{
                        actions:assign((ctx)=>{
                            ctx.currentRoute = "termsAndConditions"
                        }),
                        target:"termsAndConditions",
                    },
                    NAV_TO_CONTACT_US:{
                        actions:assign((ctx)=>{
                            ctx.currentRoute = "contactUs"
                        }),
                        target:"contactUs",
                    },
                    NAV_TO_PRIVACY_AND_POLICY:{
                        actions:assign((ctx)=>{
                            ctx.currentRoute = "privacyAndPolicy"
                        }),
                        target:"privacyAndPolicy",
                    },
                    RESET_MACHINE:{
                        actions:assign((ctx)=>{
                            ctx.currentRoute = INITIAL_STATE
                        }),
                    }

                }
            },
            clinic:{

                on:{
                    NAV_TO_CLINIC_DETAILS:{
                        actions:assign((ctx)=>{
                            ctx.currentRoute = "clinic"
                        }),
                        target:"clinic",
                    },
                    NAV_TO_HOME:{
                        actions:assign((ctx)=>{
                            ctx.currentRoute = "home"
                        }),
                        target:"home",
                    },
                    NAV_TO_ADMIN:{
                        actions:assign((ctx)=>{
                            ctx.currentRoute = "admin"
                        })
                    },
                    NAV_TO_STATISTICS:{
                        actions:assign((ctx)=>{
                            ctx.currentRoute = "statistics"
                        }),
                        target:"statistics",
                    },
                NAV_TO_TERMS_AND_CONDITIONS:{
                    actions:assign((ctx)=>{
                        ctx.currentRoute = "termsAndConditions"
                    }),
                    target:"termsAndConditions",
                },
                    NAV_TO_CONTACT_US:{
                        actions:assign((ctx)=>{
                            ctx.currentRoute = "contactUs"
                        }),
                        target:"contactUs",
                    },
                NAV_TO_PRIVACY_AND_POLICY:{
                    actions:assign((ctx)=>{
                        ctx.currentRoute = "privacyAndPolicy"
                    }),
                    target:"privacyAndPolicy",
                },
                    RESET_MACHINE:{
                        actions:assign((ctx)=>{
                            ctx.currentRoute = INITIAL_STATE
                        }),
                    }
                }
            },
            privacyAndPolicy:{
                on:{
                    NAV_TO_HOME:{
                        actions:assign((ctx)=>{
                            ctx.currentRoute = "home"
                        }),
                        target:"home",
                    },
                    NAV_TO_ADMIN:{
                        actions:assign((ctx)=>{
                            ctx.currentRoute = "admin"
                        })
                    },
                    NAV_TO_STATISTICS:{
                        actions:assign((ctx)=>{
                            ctx.currentRoute = "statistics"
                        }),
                        target:"statistics",
                    },
                    NAV_TO_CLINIC_DETAILS:{
                        actions:assign((ctx)=>{
                            ctx.currentRoute = "clinic"
                        }),
                        target:"clinic",
                    },
                    NAV_TO_TERMS_AND_CONDITIONS:{
                        actions:assign((ctx)=>{
                            ctx.currentRoute = "termsAndConditions"
                        }),
                        target:"termsAndConditions",
                    },
                    NAV_TO_CONTACT_US:{
                        actions:assign((ctx)=>{
                            ctx.currentRoute = "contactUs"
                        }),
                        target:"contactUs",
                    },
                    NAV_TO_PRIVACY_AND_POLICY:{
                        actions:assign((ctx)=>{
                            ctx.currentRoute = "privacyAndPolicy"
                        }),
                        target:"privacyAndPolicy",
                    },
                    RESET_MACHINE:{
                        actions:assign((ctx)=>{
                            ctx.currentRoute = INITIAL_STATE
                        }),
                    }
                }
            },
            termsAndConditions:{
                on:{
                    NAV_TO_HOME:{
                        actions:assign((ctx)=>{
                            ctx.currentRoute = "home"
                        }),
                        target:"home",
                    },
                    NAV_TO_ADMIN:{
                        actions:assign((ctx)=>{
                            ctx.currentRoute = "admin"
                        })
                    },
                    NAV_TO_STATISTICS:{
                        actions:assign((ctx)=>{
                            ctx.currentRoute = "statistics"
                        }),
                        target:"statistics",
                    },
                    NAV_TO_CLINIC_DETAILS:{
                        actions:assign((ctx)=>{
                            ctx.currentRoute = "clinic"
                        }),
                        target:"clinic",
                    },
                    NAV_TO_PRIVACY_AND_POLICY:{
                        actions:assign((ctx)=>{
                            ctx.currentRoute = "privacyAndPolicy"
                        }),
                        target:"privacyAndPolicy",
                    },
                    NAV_TO_CONTACT_US:{
                        actions:assign((ctx)=>{
                            ctx.currentRoute = "contactUs"
                        }),
                        target:"contactUs",
                    },
                    RESET_MACHINE:{
                        actions:assign((ctx)=>{
                            ctx.currentRoute = INITIAL_STATE
                        }),
                    }
                }
            },
            contactUs:{
                on:{
                    NAV_TO_HOME:{
                        actions:assign((ctx)=>{
                            ctx.currentRoute = "home"
                        }),
                        target:"home",
                    },
                    NAV_TO_ADMIN:{
                        actions:assign((ctx)=>{
                            ctx.currentRoute = "admin"
                        })
                    },
                    NAV_TO_STATISTICS:{
                        actions:assign((ctx)=>{
                            ctx.currentRoute = "statistics"
                        }),
                        target:"statistics",
                    },
                    NAV_TO_CLINIC_DETAILS:{
                        actions:assign((ctx)=>{
                            ctx.currentRoute = "clinic"
                        }),
                        target:"clinic",
                    },
                    NAV_TO_PRIVACY_AND_POLICY:{
                        actions:assign((ctx)=>{
                            ctx.currentRoute = "privacyAndPolicy"
                        }),
                        target:"privacyAndPolicy",
                    },
                    NAV_TO_TERMS_AND_CONDITIONS:{
                        actions:assign((ctx)=>{
                            ctx.currentRoute = "termsAndConditions"
                        }),
                        target:"termsAndConditions",
                    },
                    RESET_MACHINE:{
                        actions:assign((ctx)=>{
                            ctx.currentRoute = INITIAL_STATE
                        }),
                    }
                }
            }
        }
    };

const RoutingMachineOptions : MachineOptions<RouteContextType,RouteEvent,any> =
    {
        activities: {},
        delays: {},
        guards: {
            // // input guards
            // isNoUsername: (context, event) => context.username.length === 0,
            // isUsernameBadFormat: context =>  {
            //     // let re = /\S+@\S+\.\S+/;
            //     // return !re.test(context.username);
            //     return false
            // },
            // isNoPhoneNumber: (context, event) => context.phoneNumber.length === 0,
            // isNoLoginCode: (context, event) => context.loginCode.length === 0,
            // isPhoneNumberShort: (context, event) => context.phoneNumber.length < 5,
            // //be guards
            // isNoAccount: (context, evt) => evt.data.code === 1,
            // isWrongLoginCode: (context, evt) => evt.data.code === 1,
            // isIncorrectPassword: (context, evt) => evt.data.code === 2,
            // isNoResponse: (context, evt) => evt.data.code === 3,
            // isInternalServerErr: (context, evt) => evt.data.code === 4

        },
        services: {
            // requestLoginCode: ctx => contactFirstStepAuthService(ctx.username, ctx.phoneNumber),
            // requestLogin: ctx => contactSecondStepAuthService(ctx.token),
            // requestLogout : ctx => Logout(),
        },
        actions: {
            // BeforeStatisticsEnter:assign((ctx,evt)=>{
            //     data:{
            //         currentScreen:evt.data
            //     }
            // }),
            //
            //
            // setAuthToken:assign((ctx, evt) => ({
            //     token: evt.loginObject.accessToken
            // })),
            // focusUsernameInput: (ctx) => {
            //     console.log("focusUsernameInput ctx.username >",ctx.username)
            // },
            // focusPasswordInput: (ctx) => {
            //     console.log("focusPasswordInput ctx.username >",ctx.phoneNumber)
            // },
            // focusLoginCodeInput: (ctx) => {
            //     console.log("focusLoginCodeInput ctx.loginCode >",ctx.loginCode)
            // },
            // cacheUsername: assign((ctx, evt) => ({
            //     username: evt.username
            // })),
            // cachePassword: assign((ctx, evt) => ({
            //     phoneNumber: evt.phoneNumber
            // })),
            // redirectToSignIn: ()=>{
            //     console.log("AZURE REDIRECTION");
            // },
            // cacheLoginCode:assign((ctx, evt) => ({
            //     loginCode: evt.loginCode
            // })),
            // onLoginCode:assign((ctx, evt) => ({
            //     isLoginCodeSent: evt.data.codeSent,
            // })),
            // onLoggedIn:assign((ctx, evt) => ({
            //     isLoggedIn: true,
            //     token:evt.data.token,
            //     role:evt.data.role
            // })),
            // onLoggedOut:assign((ctx, evt) => ({
            //     isLoggedIn: false,
            //     isLoginCodeSent:false,
            //     token:"",
            //     role:"",
            // })),

        }
    }
//
export const RouteContext = React.createContext({});
export const RoutingMachine = createMachine<RouteContextType,RouteEvent>(RoutingMachineConfig,RoutingMachineOptions)
