import React, {useState} from 'react';
import GSmallLabel from "./GSmallLabel";

const GSelect = (props) => {

    let commonStyle = "h-14 pl-0.938rem pr-0.938rem"
    let fontStyle = " text-base text-left pl-10  text-gray-400 "
    let borderStyle = " border-2 rounded-2xl " + (props.error && props.error.length > 0 ? " border-red-500 ":" border-#1396B433 ");
    let [selectedIndex,setSelectedIndex] = useState(0)
    const handleSelected =(e)=>{
        setSelectedIndex(props.series.indexOf(e.target.value))
        props.onChange(e.target.value)
    }
    return (
        <div className={""}>
            {
                props.title
                    ?
                    <GSmallLabel color={"text-gray-400"} className={"text-0.875rem "}>
                        {
                            props.title
                        }
                    </GSmallLabel>
                    :
                    null
            }
            <select required
                    onChange={(e)=>handleSelected(e)}
                    className={(selectedIndex === 0 ? " text-gray-400 " : " text-gray-600 ")+ commonStyle+fontStyle+(props.borderStyle ? props.borderStyle : borderStyle)+" outline-none "+props.className}
                    selected={props.selected}
                    defaultValue={props.selected}

            >
                <option value="" disabled selected hidden>{props.placeHolder}</option>
                {
                    props.series.map((s,i)=>
                        <option key={"s-opt-"+i}  value={s}>{s}</option>
                    )
                }
            </select>
            {
                props.validation
                    ?
                    <GSmallLabel color={"text-red-500 text-0.75rem"} className={"absolute"}>{
                        props.error
                    }</GSmallLabel>
                    :
                    null
            }
        </div>

    )
};
export default GSelect;
