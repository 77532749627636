import React from 'react';

const GBigLabel = (props) => {
    let commonStyle = " "
    let fontStyle = "font-ghotamBold " + ( props.color ? props.color : " text-#666767 ")
    let borderStyle = " ";
    return (
        <div onClick={()=>props.onClick ? props.onClick() : null} className={commonStyle+fontStyle+borderStyle+" "+props.className+"  g-big-label  "}>
            {
                props.children
            }
        </div>
    )
};
export default GBigLabel;
