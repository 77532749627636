import React, { Component } from 'react'
import '../../style/Admin/SystemSetupPage.css'
// import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
// import BootstrapTable from 'react-bootstrap-table-next';
import axios from "axios";
import Config from "../../services/Config";

import MyVerticallyCenteredModal from "../../components/MyVerticallyCenteredModal";
import Form from "react-bootstrap/Form";

class SystemSetupPage extends Component {

    constructor(props) {
        super(props);
        this.state={
            data:[],
            selectedRow:{Name:'',Val:''},
            modalShow:false
        }
    }
    componentDidMount() {
        this.getSettings();
    }

    async getSettings(){
        await axios.get(`${Config.globalUrl}:${Config.globalPort}/api/v1/webapp/general/d_update`,Config.token)
            .then(async (response) => {
                // console.log('response '+ (new Date()));
                await this.setState({data:response.data});
            })
            .catch( async (error) => {
                console.log(error);
            });
    }
    setSettings(){
        var i = this.state.data.length;
        var j = 0;
        this.state.data.forEach(ele=>{
            axios.post(`${Config.globalUrl}:${Config.globalPort}/api/v1/webapp/general/${Config.UserID}/d_update`,ele,Config.token)
                .then(async (response) => {
                    j++;
                    if(j===i){
                        alert('settings updated');
                        this.getSettings();
                    }
                })
                .catch( async (error) => {
                    console.log(error);
                });
        })

    }

     rowEvents = {
        onClick: async (e, row, rowIndex) => {
            await this.setState({selectedRow:row})
        }
    };

    async openModal(){
        await this.setState({modalShow:true});
        var selected = this.state.selectedRow;
        const form_ref = this.refs['settingFormRef'];
        form_ref.elements[this.state.selectedRow.Name].value  =selected.Val;
    }

    static valueFormatter(cell){
        return (
            <div onClick={this.openModal.bind(this)} >{cell}</div>
        )
    }
    async handlemodalclose(){
        await this.setState({modalShow:false});
        await this.setState({selectedSetting:null});
    }
    async handlemodalyes(){
        const form_ref = this.refs['settingFormRef'];
        var setting  = form_ref.elements[this.state.selectedRow.Name].value;

        var _data = this.state.data;
        _data[_data.indexOf(this.state.selectedRow)].Val = setting;
        await this.setState({data:_data,modalShow:false})
    }
    render(){

        // const columns = [
        //     {
        //         dataField: 'Name',
        //         text: 'Setting'
        //     },
        //     {
        //         dataField: 'Val',
        //         text:'Value',
        //         formatter:SystemSetupPage.valueFormatter.bind(this)
        //     }
        // ];

        return (
            <div>
                <MyVerticallyCenteredModal
                    show={this.state.modalShow}
                    headertext=''
                    bodytext={
                        <div>
                            <Form  onSubmit = {(event)=>{ event.preventDefault();}}  className = 'my-custom-form' ref={'settingFormRef'}>
                                <div/>
                                <Form.Group controlId={this.state.selectedRow.Name}>
                                    <Form.Label>{this.state.selectedRow.Name}</Form.Label>
                                    <Form.Control type="text" placeholder="Enter email" />
                                </Form.Group>
                                <div/>
                            </Form>

                        </div>
                    }
                    closeonly="false"
                    handlemodalclose={this.handlemodalclose.bind(this)}
                    closetext='Close'
                    handlemodalyes={this.handlemodalyes.bind(this)}
                    yestext='Save'
                    handlemodalno={this.handlemodalclose.bind(this)}
                    notext='Cancel'
                />
                <label className="title">
                       System Setup
                </label>
                <div>
                    <div>
                        <div className='clinic-control-panel'>
                            <div className='my-custom-button' onClick={this.setSettings.bind(this)}>Save</div>
                            <div></div>
                            <div/>
                            <div/>
                            <div/>
                            <div/>
                            <div/>
                            <div/>
                            <div/>
                            <div/>
                        </div>
                    </div>
                    {/*<BootstrapTable  keyField='Name' data={ this.state.data } columns={ columns } rowEvents={ this.rowEvents }/>*/}
                </div>
            </div>
        );
    }
}

export default SystemSetupPage ;

