export default function Config() {}

//PRE_RELEASE_CONFIG
Config.ENV = process.env.REACT_APP_ENV;
//DEBUGGING
Config.Debug = false;
Config.NoDeviceTestStart = false;
Config.NoUploadTestData = false;

//REGION BACKEND
Config.globalUrl = process.env.REACT_APP_BE_URL;
Config.globalUrlForgot = process.env.REACT_APP_BE_URL;
Config.S3PublicBucket = process.env.REACT_APP_PUBLIC_BUCKET;
Config.globalPort = `${process.env.REACT_APP_BE_PORT}`;
Config.UserID = '';
Config.deviceName = '';
Config.region = process.env.REACT_APP_BE_REGION
Config.deviceEndpoint = process.env.REACT_APP_IOT_ENDPOINT;
Config.accessKeyId = "";
Config.secretAccessKey = "";
Config.token = {
    headers: {
      'Authorization': ''
    }
}
Config.hideSiteAdmin = false;
Config.role = []
Config.getUserDataFromCache = true;
//ENDREGION BACKEND

//LOGS
Config.logLevel = 1;
//END LOGS

//UI CONFIG
Config.subStatusEnabled = false;
Config.closeAlertEnabled= false;
Config.dontShowLabResults=false;
Config.getImagesFromZip = false;
//END UI CONFIG
Config.ReCaptchaKey = process.env.REACT_APP_RECAPTCHA_KEY
Config.FailedLoginCount = 0;