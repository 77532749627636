import {createMachine, MachineConfig, MachineOptions} from "xstate";
import React from "react";
import i18n from "i18next";

const parseDeviceStatus =(c:number)=> {
    switch (c) {
        case 999:
            return {
                statusCode: c,
                isOnline:false,
                isReady:false,
                indicatorColor: "red",
                isBlinking:false,
                statusMessage: i18n.t("scanner.offline"),
                statusSubMessage: i18n.t("scanner.scanner-is-off"),
                processValue: 0,
            };
        case 1000:
            return {
                statusCode: c,
                isOnline:true,
                isReady:true,
                indicatorColor: "blue",
                isBlinking:false,
                statusMessage: i18n.t("scanner.online"),
                statusSubMessage: i18n.t("scanner.ready"),
                processValue: 0,
            };
        case 1:
            return {
                statusCode: c,
                isOnline:true,
                isReady:false,
                indicatorColor: "white",
                isBlinking:true,
                statusMessage:  i18n.t("scanner.online"),
                statusSubMessage: i18n.t("scanner.cradle-is-open"),
                processValue: 0,
            };
        case 3:
            return {
                statusCode: c,
                isOnline:true,
                isReady:true,
                indicatorColor: "blue",
                isBlinking:false,
                statusMessage: i18n.t("scanner.online"),
                statusSubMessage: i18n.t("scanner.cradle-is-closed"),
                processValue: 0,
            };
        case 31:
            return {
                statusCode: c,
                isOnline:true,
                isReady:false,
                indicatorColor: "blue",
                isBlinking:false,
                statusMessage: i18n.t("scanner.online"),
                statusSubMessage: i18n.t("scanner.no-cartridge"),
                processValue: 0,
            };
        case 32:
            return {
                statusCode: c,
                isOnline:true,
                isReady:true,
                indicatorColor: "blue",
                isBlinking:false,
                statusMessage: i18n.t("scanner.online"),
                statusSubMessage: i18n.t("scanner.new-cartridge"),
                processValue: 0,
            };
        case 33:
            return {
                statusCode: c,
                isOnline:true,
                isReady:false,
                indicatorColor: "yellow",
                isBlinking:false,
                statusMessage: i18n.t("scanner.online"),
                statusSubMessage: i18n.t("scanner.sample-was-not-removed"),
                processValue: 0,
            };
        case 4:
            return {
                statusCode: c,
                isOnline:true,
                isReady:true,
                indicatorColor: "blue",
                isBlinking:true,
                statusMessage: i18n.t("scanner.online"),
                statusSubMessage: i18n.t("scanner.scanning"),
                processValue: 0,
            };
        case 41:
            return {
                statusCode: c,
                isOnline:true,
                isReady:true,
                indicatorColor: "blue",
                isBlinking:true,
                statusMessage: i18n.t("scanner.online"),
                statusSubMessage: i18n.t("scanner.scanning"),
                processValue: 25,
            };
        case 42:
            return {
                statusCode: c,
                isOnline:true,
                isReady:true,
                indicatorColor: "blue",
                isBlinking:true,
                statusMessage: i18n.t("scanner.online"),
                statusSubMessage: i18n.t("scanner.scanning"),
                processValue: 50,
            };
        case 43:
            return {
                statusCode: c,
                isOnline:true,
                isReady:true,
                indicatorColor: "blue",
                isBlinking:true,
                statusMessage: i18n.t("scanner.online"),
                statusSubMessage: i18n.t("scanner.scanning"),
                processValue: 75,
            };
        case 44:
            return {
                statusCode: c,
                isOnline:true,
                isReady:true,
                indicatorColor: "blue",
                isBlinking:true,
                statusMessage: i18n.t("scanner.online"),
                statusSubMessage: i18n.t("scanner.scanning-algorithm-run"),
                processValue: 85,
            };
        case 45:
            return {
                statusCode: c,
                isOnline:true,
                isReady:false,
                indicatorColor: "blue",
                isBlinking:true,
                statusMessage: i18n.t("scanner.online"),
                statusSubMessage:i18n.t("scanner.scanning-end") ,
                processValue: 100,
            };
        case 46:
            return {
                statusCode: c,
                isOnline:true,
                isReady:false,
                indicatorColor: "red",
                isBlinking:false,
                statusMessage: i18n.t("scanner.online"),
                statusSubMessage: i18n.t("scanner.scan-failed"),
                processValue: 0,
            };
        case 47:
            return {
                statusCode: c,
                isOnline:true,
                isReady:false,
                indicatorColor: "red",
                isBlinking:false,
                statusMessage: i18n.t("scanner.online"),
                statusSubMessage: i18n.t("scanner.algorithm-failed"),
                processValue: 0,
            };
        case 48:
            return {
                statusCode: c,
                isOnline:true,
                isReady:false,
                indicatorColor: "red",
                isBlinking:false,
                statusMessage: i18n.t("scanner.online"),
                statusSubMessage: i18n.t("scanner.scan-failed") ,
                processValue: 0,
            };
        case 5:
            return {
                statusCode: c,
                isOnline:true,
                isReady:false,
                indicatorColor: "red",
                isBlinking:false,
                statusMessage: i18n.t("scanner.online"),
                statusSubMessage: i18n.t("scanner.malfunction"),
                processValue: 0,
            };
        case -1:
            return {
                statusCode: c,
                isOnline:true,
                isReady:false,
                indicatorColor: "red",
                isBlinking:false,
                statusMessage: i18n.t("scanner.online"),
                statusSubMessage:  i18n.t("scanner.unknown-status") ,
                processValue: 0,
            };
        case 6:
            return {
                statusCode: c,
                isOnline:true,
                isReady:false,
                indicatorColor: "green",
                isBlinking:true,
                statusMessage: i18n.t("scanner.online"),
                statusSubMessage: i18n.t("scanner.self-test") ,
                processValue: 0,
            };
        case 7:
            return {
                statusCode: c,
                isOnline:true,
                isReady:false,
                indicatorColor: "yellow",
                isBlinking:false,
                statusMessage: i18n.t("scanner.online"),
                statusSubMessage: i18n.t("scanner.fota"),
                processValue: 0,
            };
        default:
            return {
                statusCode: c,
                isOnline:false,
                isReady:false,
                indicatorColor: "white",
                isBlinking:false,
                statusMessage:"",
                statusSubMessage: "",
                processValue: 0,
            };
    }
}

interface DeviceSchema {
    initial: 'operate',
    states: {
        operate: {},
    };
}

//Events
type DeviceEvent =
    | { type: "SET_CURRENT_DEVICE_STATUS", status: number }
    | { type: "RESET_MACHINE" }

//context
export interface DeviceContextType {
    statusCode: number,
    isOnline:boolean,
    isReady:boolean,
    indicatorColor: string,
    isBlinking:boolean,
    statusMessage: string,
    statusSubMessage: string,
    processValue: number,
}

//machine config
const DeviceMachineConfig: MachineConfig<any, DeviceSchema, any> =
    {
        id: "DeviceMachine",
        context: {
            statusCode: 987,
            isOnline:false,
            isReady:false,
            indicatorColor: "",
            isBlinking:false,
            statusMessage: "",
            statusSubMessage: "No Scanner Is Connected",
            processValue: 0,
        },
        initial: "operate",
        states: {
            operate: {
                on: {
                    SET_CURRENT_DEVICE_STATUS: {
                        actions: "parseDeviceStatus",
                        target: "operate",
                    },
                    RESET_MACHINE:{
                        actions:"resetMachine",
                        target: "operate",
                    }
                }
            }
        }
    };

const DeviceMachineOptions: MachineOptions<DeviceContextType, any, any> =
    {
        activities: {},
        delays: {},
        guards: {},
        services: {
            // requestLoginCode: ctx => contactFirstStepAuthService(ctx.username, ctx.phoneNumber),
            // requestLogin: ctx => contactSecondStepAuthService(ctx.token),
            // requestLogout : ctx => Logout(),
        },
        actions: {
            resetMachine:(ctx=>{
                ctx.statusCode =  987;
                ctx.isOnline = false;
                ctx.isReady = false;
                ctx.indicatorColor =  "";
                ctx.isBlinking = false;
                ctx.statusMessage = "";
                ctx.statusSubMessage = "No Scanner Is Connected";
                ctx.processValue =  0 ;
            }),
            parseDeviceStatus: ((ctx, evt) => {
                let _deviceInfo = parseDeviceStatus(evt.status);
                ctx.statusCode = _deviceInfo.statusCode;
                ctx.isOnline = _deviceInfo.isOnline;
                ctx.isReady = _deviceInfo.isReady;
                ctx.isBlinking = _deviceInfo.isBlinking;
                ctx.indicatorColor = _deviceInfo.indicatorColor;
                ctx.statusMessage = _deviceInfo.statusMessage;
                ctx.statusSubMessage = _deviceInfo.statusSubMessage;
                ctx.processValue = _deviceInfo.processValue;
            })
        }
    }
//
export const DeviceContext = React.createContext({});
export const DeviceMachine = createMachine<DeviceContextType, DeviceEvent>(DeviceMachineConfig, DeviceMachineOptions)
