import React, {useState} from 'react';
import Eye from "../assets/eye_svg.svg";
import GSmallLabel from "./GSmallLabel";

const GTextInput = (props) => {

    let fontStyle = " text-base text-left text-#666767 "
    let borderStyle = " border-2 rounded-2xl border-#1396B433 ";
    let [passVisible,setPassVisible] = useState(false)
    let [doValidate,setDoValidate]=useState(false);
    const mouseDown=()=>{
        setPassVisible(true)
    }
    const mouseUp=()=>{
        setPassVisible(false)
    }

    return (
        <div >
            {
                props.title
                ?
                    <GSmallLabel color={"text-gray-400"} className={"text-0.875rem "}>
                        {
                            props.title
                        }
                    </GSmallLabel>
                :
                    null
            }
            <div className={" relative flex w-full "  } >

                <input
                    min={props.min ? props.min : null}
                    max={props.max ? props.max : null}
                    type={props.password && !passVisible ? "password" : props.type ? props.type : "text"}
                    onChange={props.onChange}
                    placeHolder={props.placeHolder}
                    className={" outline-none  pl-0.938rem w-full "+fontStyle+" "+props.className+" "  + ((props.error && doValidate) ? "border-2 rounded-2xl border-red-500  ": (props.borderStyle ? props.borderStyle :  borderStyle))}
                    value={props.value}
                    onKeyPress={(e)=> props.onKeyPress ? props.onKeyPress(e) : null}
                    onFocus={()=>setDoValidate(false)}
                    onBlur={()=>{
                        setDoValidate(true)
                    }}
                />
                {
                    props.password
                        ?
                        <div className={"absolute right-0 cursor-pointer m-auto flex pr-0.938rem justify-end "+props.iconPosition}>
                            <img onDrag={mouseUp} onDrop={mouseUp} onMouseDown={mouseDown} onMouseUp={mouseUp} className={""} src={Eye} alt={"eye"}/>
                        </div>
                        :
                        <></>
                }
            </div>

            {
                props.validation && doValidate
                ?
                    <GSmallLabel color={"text-red-500 text-0.75rem"} className={"absolute"}>
                        {
                        props.error
                    }</GSmallLabel>
                :
                    null
            }


        </div>
    )
};
export default GTextInput;
