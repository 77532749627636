
import {createMachine, MachineConfig, MachineOptions} from "xstate";
import {assign} from "xstate";
import React from "react";
import i18n from "i18next";


//State machine schema
interface TestSchema {
    initial:'home',
    states: {
        error:{},//Unusable cartridge
        step1:{},//please do not test in these cases
        step2:{},//is this your cartridge
        step3:{},//complaints [Inputs/Guards/Validations]
        step4:{},//progressbar , only on MQTT approve
        step5:{},//test result
        step6:{},//scan complete
    };
}
//Events
type TestEvent =
    | { type : "SET_ERROR"; data:object}
    | { type : "SET_STEP_1"; data:object}
    | { type : "SET_STEP_2"; data:object}
    | { type : "SET_STEP_3"; data:object}
    | { type : "SET_STEP_4"; data:object}
    | { type : "INPUT_BURNING"      , value:boolean}
    | { type : "INPUT_DISCHARGE"    , value:boolean}
    | { type : "INPUT_ITCH"         , value:boolean}
    | { type : "INPUT_FISHY_ODOR"   , value:boolean}
    | { type : "INPUT_PAIN"         , value:boolean}
    | { type : "INPUT_DRYNESS"      , value:boolean}
    | { type : "INPUT_OTHER_MALODOR" , value:boolean}
    | { type : "INPUT_OTHER"        , value:boolean}
    | { type : "INPUT_OTHER_TEXT"   , value:string}
    | { type : "INPUT_YOB"   , value:number}
    | { type : "SET_STEP_5"; data:object}
    | { type : "UPDATE_TEST_PROGRESS",value:number}
    | { type : "SET_STEP_6"; data:object}
    | { type : "RESET_TEST_MACHINE"}

//context
export interface TestContextType{
    currentStep:string,
    testProgress:number,
    complaints: {
        burning     : {
            Name:string,
            Val:boolean,
        },
        discharge   : {
            Name:string,
            Val:boolean,
        },
        itch        : {
            Name:string,
            Val:boolean,
        },
        fishyOdor   : {
            Name:string,
            Val:boolean,
        },
        pain        : {
            Name:string,
            Val:boolean,
        },
        dryness     : {
            Name:string,
            Val:boolean,
        },
        otherMalodor: {
            Name:string,
            Val:boolean,
        },
        other       : {
            Name:string,
            Val:boolean,
        },
        otherText   : string,
    },
    patientYearOfBirth:number,
}
const validateNumber = (input:any)=>{
    return !input.match(/^[0-9]+$/);
}
//machine config
const TestMachineConfig : MachineConfig<any,TestSchema,TestEvent> =
    {
        id: "TestMachine",
        context: {
            currentStep: localStorage.getItem("gyni-step-1-skip") === "true" ? "step2" : "step1",
            testProgress:0,
            complaints: {
                burning     : {
                    ObjName:"Burning",
                    Name:i18n.t("complaints.burning"),Val:false
                },
                discharge   : {
                    ObjName:"Discharge",
                    Name:i18n.t("complaints.discharge"),Val:false
                },
                itch        : {
                    ObjName:"Itch",
                    Name:i18n.t("complaints.itch"),Val:false
                },
                fishyOdor   : {
                    ObjName:"Fishy Odor",
                    Name:i18n.t("complaints.fishy-odor"),Val:false
                },
                pain        : {
                    ObjName:"Pain",
                    Name:i18n.t("complaints.pain"),Val:false
                },
                dryness     : {
                    ObjName:"Dryness",
                    Name:i18n.t("complaints.dryness"),Val:false
                },
                otherMalodor       : {
                    ObjName:"Other Malodor",
                    Name:i18n.t("complaints.other-malodor"),Val:false
                },
                other       : {
                    ObjName:"Other",
                    Name:i18n.t("complaints.other"),Val:false
                },
                otherText   : "",
            },
            patientYearOfBirth:"",
            patientYearOfBirthError:"",
        },
        initial: localStorage.getItem("test_first_step_skip") === "true" ? "step2" : "step1" ,
        states: {
            error:{
                on: {
                    RESET_TEST_MACHINE:{
                        actions:"resetTestMachine",
                    },
                    SET_STEP_1: {
                        actions:"setCurrentStateContext",
                        target: "step1",
                    },
                    SET_STEP_2: {
                        actions:"setCurrentStateContext",
                        target: "step2",
                    },
                    SET_STEP_3: {
                        actions:"setCurrentStateContext",
                        target: "step3",
                    },
                    SET_STEP_4: {
                        actions:"setCurrentStateContext",
                        target: "step4",
                    },
                    SET_STEP_5: {
                        actions:"setCurrentStateContext",
                        target: "step5",
                    },
                    SET_STEP_6: {
                        actions:"setCurrentStateContext",
                        target: "step6",
                    },
                }
            },
            step1:{
                on:{
                    RESET_TEST_MACHINE:{
                        actions:"resetTestMachine",
                    },
                    SET_STEP_3:{
                        actions:"setCurrentStateContext",
                        target:"step3",
                    },
                    SET_STEP_2:{
                        actions:"setCurrentStateContext",
                        target:"step2",
                    },
                    SET_ERROR:{
                        actions:"setCurrentStateContext",
                        target:"error"
                    },
                    SET_STEP_4: {
                        actions:"setCurrentStateContext",
                        target: "step4",
                    },
                }
            },
            step2:{
                on:{
                    RESET_TEST_MACHINE:{
                        actions:"resetTestMachine",
                    },
                    SET_STEP_3:{
                        actions:"setCurrentStateContext",
                        target:"step3",
                    },
                    SET_ERROR:{
                        actions:"setCurrentStateContext",
                        target:"error"
                    },
                    SET_STEP_4: {
                        actions:"setCurrentStateContext",
                        target: "step4",
                    },
                }
            },
            step3:{
                on:{
                    RESET_TEST_MACHINE:{
                        actions:"resetTestMachine",
                    },
                    SET_STEP_4:{
                        actions:"setCurrentStateContext",
                        target:"step4",
                    },
                    SET_ERROR:{
                        actions:"setCurrentStateContext",
                        target:"error"
                    },
                    INPUT_BURNING:{
                        actions:assign((ctx,evt)=>{
                            ctx.complaints.burning.Val = evt.value
                        })
                    },
                    INPUT_DISCHARGE:{
                        actions:assign((ctx,evt)=>{
                            ctx.complaints.discharge.Val = evt.value
                        })
                    },
                    INPUT_ITCH:{
                        actions:assign((ctx,evt)=>{
                            ctx.complaints.itch.Val = evt.value
                        })
                    },
                    INPUT_FISHY_ODOR:{
                        actions:assign((ctx,evt)=>{
                            ctx.complaints.fishyOdor.Val = evt.value
                        })
                    },
                    INPUT_PAIN:{
                        actions:assign((ctx,evt)=>{
                            ctx.complaints.pain.Val = evt.value
                        })
                    },
                    INPUT_OTHER_MALODOR:{
                        actions:assign((ctx,evt)=>{
                            ctx.complaints.otherMalodor.Val = evt.value
                        })
                    },
                    INPUT_DRYNESS:{
                        actions:assign((ctx,evt)=>{
                            ctx.complaints.dryness.Val = evt.value
                        })
                    },
                    INPUT_OTHER:{
                        actions:assign((ctx,evt)=>{
                            ctx.complaints.other.Val = evt.value
                        })
                    },
                    INPUT_OTHER_TEXT:{
                        actions:assign((ctx,evt)=>{
                            ctx.complaints.otherText = evt.value
                        })
                    },
                    INPUT_YOB:{
                        actions:assign((ctx,evt)=>{
                            ctx.patientYearOfBirthError = ""
                            if (validateNumber(evt.value)){
                                ctx.patientYearOfBirthError = i18n.t('error.cannot-be-letters')
                            }
                            let age = ((new Date).getFullYear() - evt.value);

                            if (age < 0 || age > 120) {
                                ctx.patientYearOfBirthError = "Wrong Value"
                            } else if (age > 0 && age < 18) {
                                ctx.patientYearOfBirthError = "Must Be 18"
                            }
                            ctx.patientYearOfBirth = evt.value

                        })
                    },
                }
            },
            step4:{
                on:{
                    RESET_TEST_MACHINE:{
                        actions:"resetTestMachine",
                    },
                    SET_STEP_5:{
                        actions:"setCurrentStateContext",
                        target:"step5",
                    },
                    SET_ERROR:{
                        actions:"setCurrentStateContext",
                        target:"error"
                    }
                }
            },
            step5:{
                on:{
                    RESET_TEST_MACHINE:{
                        actions:"resetTestMachine",
                    },
                    SET_STEP_1: {
                        actions:"setCurrentStateContext",
                        target: "step1",
                    },
                    SET_STEP_6:{
                        actions:"setCurrentStateContext",
                        target:"step6",
                    },
                    SET_ERROR:{
                        actions:"setCurrentStateContext",
                        target:"error"
                    }
                }
            },
            step6:{
                on:{
                    RESET_TEST_MACHINE:{
                        actions:"resetTestMachine",
                    },
                    SET_STEP_1:{
                        actions:"setCurrentStateContext",
                        target:"step1",
                    },
                    SET_ERROR:{
                        actions:"setCurrentStateContext",
                        target:"error"
                    }
                }
            },
        }
    };

const TestMachineOptions : MachineOptions<any,any,any> =
    {
        activities: {},
        delays: {},
        guards: {
            // // input guards
            // isNoUsername: (context, event) => context.username.length === 0,
            // isUsernameBadFormat: context =>  {
            //     // let re = /\S+@\S+\.\S+/;
            //     // return !re.test(context.username);
            //     return false
            // },
            // isNoPhoneNumber: (context, event) => context.phoneNumber.length === 0,
            // isNoLoginCode: (context, event) => context.loginCode.length === 0,
            // isPhoneNumberShort: (context, event) => context.phoneNumber.length < 5,
            // //be guards
            // isNoAccount: (context, evt) => evt.data.code === 1,
            // isWrongLoginCode: (context, evt) => evt.data.code === 1,
            // isIncorrectPassword: (context, evt) => evt.data.code === 2,
            // isNoResponse: (context, evt) => evt.data.code === 3,
            // isInternalServerErr: (context, evt) => evt.data.code === 4

        },
        services: {
            // requestLoginCode: ctx => contactFirstStepAuthService(ctx.username, ctx.phoneNumber),
            // requestLogin: ctx => contactSecondStepAuthService(ctx.token),
            // requestLogout : ctx => Logout(),
        },
        actions: {
            resetTestMachine:assign((ctx,evt)=>{
                ctx.currentStep = localStorage.getItem("gyni-step-1-skip") === "true" ? "step2" : "step1";
                ctx.testProgress = 0;
                ctx.complaints =  {
                    burning     : {
                        ObjName:"Burning",
                            Name:i18n.t("complaints.burning"),Val:false
                    },
                    discharge   : {
                        ObjName:"Discharge",
                            Name:i18n.t("complaints.discharge"),Val:false
                    },
                    itch        : {
                        ObjName:"Itch",
                            Name:i18n.t("complaints.itch"),Val:false
                    },
                    fishyOdor   : {
                        ObjName:"Fishy Odor",
                            Name:i18n.t("complaints.fishy-odor"),Val:false
                    },
                    pain        : {
                        ObjName:"Pain",
                            Name:i18n.t("complaints.pain"),Val:false
                    },
                    dryness     : {
                        ObjName:"Dryness",
                            Name:i18n.t("complaints.dryness"),Val:false
                    },
                    otherMalodor : {
                        ObjName:"Other Malodor",
                        Name:i18n.t("complaints.other-malodor"),Val:false
                    },
                    other       : {
                        ObjName:"Other",
                            Name:i18n.t("complaints.other"),Val:false
                    },
                    otherText   : "",
                };
                ctx.patientYearOfBirth = "";
                ctx.patientYearOfBirthError = "";
            }),
            setCurrentStateContext:assign((ctx,evt)=>{
                ctx.currentStep = evt.data
            }),
            // BeforeStatisticsEnter:assign((ctx,evt)=>{
            //     data:{
            //         currentScreen:evt.data
            //     }
            // }),
            //
            //
            // setAuthToken:assign((ctx, evt) => ({
            //     token: evt.loginObject.accessToken
            // })),
            // focusUsernameInput: (ctx) => {
            //     console.log("focusUsernameInput ctx.username >",ctx.username)
            // },
            // focusPasswordInput: (ctx) => {
            //     console.log("focusPasswordInput ctx.username >",ctx.phoneNumber)
            // },
            // focusLoginCodeInput: (ctx) => {
            //     console.log("focusLoginCodeInput ctx.loginCode >",ctx.loginCode)
            // },
            // cacheUsername: assign((ctx, evt) => ({
            //     username: evt.username
            // })),
            // cachePassword: assign((ctx, evt) => ({
            //     phoneNumber: evt.phoneNumber
            // })),
            // redirectToSignIn: ()=>{
            //     console.log("AZURE REDIRECTION");
            // },
            // cacheLoginCode:assign((ctx, evt) => ({
            //     loginCode: evt.loginCode
            // })),
            // onLoginCode:assign((ctx, evt) => ({
            //     isLoginCodeSent: evt.data.codeSent,
            // })),
            // onLoggedIn:assign((ctx, evt) => ({
            //     isLoggedIn: true,
            //     token:evt.data.token,
            //     role:evt.data.role
            // })),
            // onLoggedOut:assign((ctx, evt) => ({
            //     isLoggedIn: false,
            //     isLoginCodeSent:false,
            //     token:"",
            //     role:"",
            // })),

        }
    }
//
export const TestContext = React.createContext({});
export const TestMachine = createMachine<TestContextType,TestEvent>(TestMachineConfig,TestMachineOptions)
