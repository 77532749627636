import React, { Component } from 'react'
import '../../style/Admin/TestReportPage.css'
// import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
// import BootstrapTable from 'react-bootstrap-table-next';

class TestReportPage extends Component {

    constructor(props) {
        super(props);
        this.state={
            data:[]
        }
    }
    componentDidMount() {
    }
    exportAs(type){
        switch (type){
            case 'pdf':
                break;
            case 'xls':
                break;
            default:
                break;
        }
    }
    render() {
        // const columns = [
        //     {
        //     dataField: 'DeviceID',
        //     text: 'Scanner #'
        //     }
        //     ,
        //     {
        //         dataField: 'TestID',
        //         text: 'Test #'
        //     }
        //     ,
        //     {
        //         dataField: 'Teststate',
        //         text: 'Test state'
        //     }
        //     ,
        //     {
        //         dataField: 'Date',
        //         text: 'Date'
        //     }
        //     ,
        //     {
        //         dataField: 'Diagnosis',
        //         text: 'Diagnosis'
        //     }
        //     ,
        //     {
        //         dataField: 'Length',
        //         text: 'Length'
        //     }
        //     ,
        //     {
        //         dataField: 'Errors',
        //         text: 'Errors'
        //     }
        //     ,
        //     {
        //         dataField: 'images',
        //         text: 'Images'
        //     }
        //     ,
        //     {
        //         dataField: 'result',
        //         text: 'Result'
        //     }
        // ];
        return (
            <div>
                <label className="title">
                        Test Report Page
                </label>
                <div>
                    {/*<BootstrapTable  keyField='id' data={ this.state.data } columns={ columns } />*/}
                    <div className='pdf-export-button' onClick={this.exportAs.bind(this,'pdf')}>
                        Export all selected tests (PDF)
                    </div>
                </div>
            </div>
        );
    }
}

export default TestReportPage ;

// 3.1.1.	Scanner #
// 3.1.2.	Test # that was selected
// 3.1.3.	Test state: Did not start/Ongoing/Finished (if a specific scanner was selected)
// 3.1.4.	Date and time of the test
// 3.1.5.	Gyni Diagnosis per test
// 3.1.6.	Length of test
// 3.1.7.	Errors : yes(+ link to a file with errors log)/no
// 3.1.8.	Link to raw images in the database
// 3.1.9.	Link to result information (same as on the results screen of the doctor)
// 3.1.10.	Total # of tests (per scanner)
// 3.1.11.	Pathogen/Condition name vs number of patients up to current date : per scanner/for all scanner accumulated
