import React, {useState} from 'react';
import GSmallLabel from "./GSmallLabel";

const GMultiSelect = (props) => {

    let commonStyle = "h-14 pl-0.938rem pr-0.938rem"
    let fontStyle = ""
    let borderStyle = "";
    const [DropDownIsOpen,toggleDropDown] = useState(false);
    const [selectedItems,setSelectedItems] = useState(props.selectedItems)

    const AddSelectedItems=(index)=>{

        let _fList = selectedItems;
        if(_fList.indexOf(index) > -1){
            _fList = _fList.filter(t=>t !== index);
            console.log(_fList)
        }
        else{
            _fList.push(index)
        }
        setSelectedItems(bigTestViews => [..._fList])
        props.onChange(_fList)
    }

    return (
        //
        <div tabIndex="0"  onBlur={()=>toggleDropDown(false)}  className={"bg-white  select-none "+props.borderStyle+ props.className}>
            <div  className={" h-full cursor-pointer flex space-x-0.456rem"} onClick={()=>toggleDropDown(!DropDownIsOpen)}>
                {
                    selectedItems.length === 0
                    ?
                        <GSmallLabel className={"mt-auto mb-auto w-full p-3"} >
                            {
                                props.placeholder
                            }
                        </GSmallLabel>
                    :
                        selectedItems.length === props.series.length
                        ?
                                <GSmallLabel className={"mt-auto mb-auto w-full p-3"} >
                                    All
                                </GSmallLabel>
                            :
                                selectedItems.length > 1
                                ?
                                    <div className={"ml-3 flex w-full overflow-x-hidden"}>
                                        <GSmallLabel className={"mt-auto mb-auto  whitespace-nowrap mr-1"}>
                                            Mixed ({selectedItems.length}) :
                                        </GSmallLabel>
                                        <GSmallLabel className={"mt-auto mb-auto  whitespace-nowrap mr-1"} >
                                            {
                                                selectedItems[0]
                                            }
                                            ,
                                        </GSmallLabel>
                                        <GSmallLabel className={"mt-auto mb-auto  whitespace-nowrap mr-1"} >
                                            {
                                                selectedItems[1]
                                            }
                                        </GSmallLabel>
                                    </div>
                                :
                                    selectedItems.map(item=>
                                        <GSmallLabel className={"mt-auto mb-auto w-full p-3"} >
                                            {
                                                item
                                            }
                                        </GSmallLabel>)
                }

                <div className={"mt-auto mb-auto p-2"}>
                    <svg className={DropDownIsOpen ? " transform rotate-180 " :" " } width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.30273 5.77149L6.0371 1.03711L10.7715 5.77148" stroke="#666767" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </div>
            </div>


            {
                DropDownIsOpen
                    ?
                    <div style={{zIndex:1}} className={"shadow absolute cursor-pointer pb-2.5 pt-2.5 rounded-lg bg-white"}>
                        {
                            props.series.map((s, i) =>
                                <div onClick={()=>AddSelectedItems(s)} className={"pl-7 w-14.375rem h-2.75rem flex hover:bg-#1396B41A "}>
                                    <input checked={selectedItems.indexOf(s)>-1} className={"mt-auto mb-auto mr-2"} type={"checkbox"}/>
                                    <GSmallLabel color={(selectedItems.indexOf(s)>-1 ? "text-#1396B4" : " text-#666767 ")}  className={(selectedItems.indexOf(s)>-1 ? "font-bold" : " ") + " mt-auto mb-auto "} key={"s-opt-" + i} value={s}>{s}</GSmallLabel>
                                </div>
                            )
                        }
                    </div>
                    :null
            }
        </div>
    )
};
export default GMultiSelect;
