import React, {Component, useContext, useEffect, useState} from "react";
import {  FormGroup, FormControl } from "react-bootstrap";
import "../../style/login.css";
import axios                     from 'axios';
import Config                    from '../../services/Config';
import {BackendContext} from "../../machines/BackendMachine";
import {useActor} from "@xstate/react";
import bgrb from "../../assets/bgrb.svg";
import bgrt from "../../assets/bgrt.svg";
import bglt from "../../assets/bglt.svg";
import logo from "../../assets/logo_svg.svg";
import GBigLabel from "../GBigLabel";
import GSmallLabel from "../GSmallLabel";
import GTextInput from "../GTextInput";
import GButton from "../GButton";
import {useTranslation, withTranslation} from "react-i18next";
import ParseError from "../../services/ErrorParser";
import {LoginContext, LoginMachine} from "../../machines/LoginMachine";
import {Redirect} from "react-router-dom";
import i18n from "../../i18n";

const ForgotPassword =(props)=> {

    const { t, i18n } = useTranslation()

    const BEContext = useContext(BackendContext)
    let BackendCurrent, BESend;
    [BackendCurrent, BESend] = useActor(BEContext);

    useEffect(()=>{
        if(BackendCurrent.context.isPasswordReseted){
            if(props.isModal){
                console.log("Reseted")
                props.handleClose()
                //TBD
            }else{
                setTimeout(()=>{
                    window.location = window.location.href.split("/forgot")[0]
                },3000)
            }
        }
    },[BackendCurrent.context.isPasswordReseted])

    const [Password,SetPassword] = useState("")
    const [RePassword,SetRePassword] = useState("")
    const [IsMatch,SetIsMatch] = useState(true)
    const [IsValid,SetIsValid] = useState(true)
    const [ValidationError,SetValidationError] = useState("")

    const HandlePasswordChange =(e)=>{
        SetPassword(e.target.value)
    }
    const HandleRePasswordChange=(e)=>{
        SetRePassword(e.target.value)
    }
    const isLetter =(c)=> {
        return (c >= 'a' && c <= 'z') ||
            (c >= 'A' && c <= 'Z')
    }
    const handleResetPassword = async (event) => {

        let _hasCapital = false;
        let _includeUserName = false;
        let _isValid = false;
        let _minimumLength = Password.length >= 8;
        let _containLetters = /[a-z]/i.test(Password);
        let _containDigits = /[0-9]/i.test(Password);
        let _containSpecialSymbols = /[!#$%&?]/.test(Password)

        for(let i = 0 ; i < Password.length ; i++){
            if(isLetter(Password[i])){
                if (Password[i] == Password[i].toUpperCase()) {
                    _hasCapital = true;
                }
            }
        }

        if(Password.indexOf(BackendCurrent.context.userProfile.Email) > -1){
            _includeUserName = true
        }

        _isValid = _minimumLength && _containLetters && _containDigits && _containSpecialSymbols && _hasCapital && !_includeUserName;


        let _validationError = ""
        if(!_minimumLength){
            _validationError+= "Minimum 8 symbols"
        }
        else if(!_containLetters){
            _validationError+= "Must contain Letters"
        }
        else if(!_containDigits){
            _validationError+= "Must contain Digits"
        }
        else if(!_containSpecialSymbols){
            _validationError+= "Must Contain !#$%&?"
        }else if(!_hasCapital){
            _validationError+= "Must Contain Capital Letters"
        }else if(_includeUserName){
            _validationError+= "Must Not Contain Email"
        }
        SetValidationError(_validationError)

        let _isMatch = RePassword === Password;
        await SetIsMatch(_isMatch);
        await SetIsValid(_isValid);

        if(!_isMatch || !_isValid){
            return;
        }

        BESend({ type : "REQUEST_RESET_PASSWORD" , password:Password,rePassword:RePassword})

    };

    const showFeedback = ((val, text, icon = true) => {
        return <div>
            {
                val
                    ?
                    <div />
                    :
                    <div className={icon ? 'IsValidation-error shifted' : 'IsValidation-error'}>{text}</div>
            }
        </div>
    })

    return(
        <div className={"pt-6.994rem "}>
            {
                props.isModal
                ?
                    <>
                        <img className={"h-3.938rem m-auto"} src={logo} alt={"logo"}/>
                        <GBigLabel className={"mt-3.813rem leading-1.914rem text-2rem w-full flex justify-center"}> {t('login.password.must-reset')}</GBigLabel>
                    </>
                :
                    <>
                        <img style={{zIndex:-1}} className={"login-bg opacity-0 sm:opacity-25 md:opacity-50 lg:opacity-75 xl:opacity-100 absolute right-0 bottom-0"} alt="bgrb" src={bgrb}/>
                        <img style={{zIndex:-1}} className={"login-bg opacity-0 sm:opacity-25 md:opacity-50 lg:opacity-75 xl:opacity-100 absolute right-0 top-0"} alt="bgrt" src={bgrt}/>
                        <img style={{zIndex:-1}} className={"login-bg opacity-0 sm:opacity-25 md:opacity-50 lg:opacity-75 xl:opacity-100 absolute top-1/4"} alt="bglt" src={bglt}/>

                        <img className={"h-3.938rem m-auto"} src={logo} alt={"logo"}/>
                        <GBigLabel className={"mt-3.813rem leading-1.914rem text-2rem w-full flex justify-center"}> {t('login.password.reset')}</GBigLabel>

                    </>
            }

            {/*<div className={"mt-2 flex justify-center space-x-0.313rem"}>*/}
            {/*    /!*<GSmallLabel className={"leading-1.076rem"}>{t('login.forgot-password')}</GSmallLabel>*!/*/}
            {/*    /!*<GSmallLabel color={"text-#50C2BC"} bold className={"leading-1.076rem "} onClick={()=>LoginSend({type:"NAV_TO_FORGOT_PASSWORD"})} >{t('login.reset')}</GSmallLabel>*!/*/}
            {/*</div>*/}
            {/*<div className={"mt-2 flex justify-center space-x-0.313rem"}>*/}
            {/*    /!*<GSmallLabel className={"leading-1.076rem"}>{t('login.dont-have-an-account')}</GSmallLabel>*!/*/}
            {/*    /!*<GSmallLabel color={"text-#50C2BC"} bold className={"leading-1.076rem "} onClick={()=>LoginSend({type:"NAV_TO_CREATE_USER"})} >{t('login.sign-up')}</GSmallLabel>*!/*/}
            {/*</div>*/}
            <div className={"w-full text-center ml-auto mr-auto absolute"}>
                {
                    BackendCurrent.context.isPasswordReseted
                        ?
                            <GSmallLabel bold className={"text-black"}>{t("login.sign-up.password-reseted")}</GSmallLabel>
                        :
                            null
                }
            </div>
            <div className={"mt-4.438rem w-21.875rem ml-auto mr-auto space-y-1.875rem relative"}>

                <GTextInput
                    max={50}
                    password
                    iconPosition={"top-5"}
                    validation
                    error={ IsValid === false ? ValidationError : "" }
                    value={Password}
                    onChange={(e)=>HandlePasswordChange(e)}
                    className={"bg-gyni-textBox h-3.5rem rounded-lg"} placeHolder={t('login.placeholder.password')}/>
                <GTextInput
                    max={50}
                    password
                    iconPosition={"top-5"}
                    validation
                    error={ IsMatch === false ? t('error.passwords-do-not-match') : "" }
                    value={RePassword}
                    onChange={(e)=>{HandleRePasswordChange(e)}}
                    className={"bg-gyni-textBox h-3.5rem rounded-lg"}  placeHolder={t('login.placeholder.re-enter-password')}/>
                {
                    BackendCurrent.context.errorsList.length > 0
                        ?
                        BackendCurrent.context.errorsList.map(
                            (err,index)=>
                                <div key={"fperr"+index} className={"absolute w-full text-center text-red-500"}>{err}</div>
                        )

                        :
                        <div></div>
                }
            </div>
            <GButton
                onClick={()=>handleResetPassword()}
                className={"mt-4.563rem h-10 w-14rem ml-auto mr-auto"}>
                {t('login.reset')}
            </GButton>
        </div>
    )
}
export default withTranslation()(ForgotPassword);
