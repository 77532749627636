import React, {useContext, useEffect, useState} from "react"
import GBigLabel from "../GBigLabel";
import ReactApexChart from "react-apexcharts"
import GSmallLabel from "../GSmallLabel";
import {BackendContext} from "../../machines/BackendMachine";
import {useActor} from "@xstate/react";
import {useTranslation} from "react-i18next";
import axios from "axios";
import Config from "../../services/Config";

const Statistics=(props)=>{
    const { t, i18n } = useTranslation()
    const BEContext = useContext(BackendContext)
    let BackendCurrent, BESend;
    [BackendCurrent, BESend] = useActor(BEContext);
    let [maxX,SetMaxX] = useState(100);
    const apexChartOptions = {

        chart: {
            type: 'bar',
            offsetX: -20,
            toolbar:{
                show:false
            },

        },
        grid:{
            show: true,
            borderColor: '#1396B41A',
            strokeDashArray: 0,
            position: 'back',
            xaxis: {
                lines: {
                    show: true,
                }
            },
            yaxis: {
                lines: {
                    show: false
                }
            },
            row: {
                colors: undefined,
                opacity: 0.1
            },
            column: {
                colors: undefined,
                opacity: 0.1
            },
        },
        fill: {
            type: "gradient",
            gradient: {
                shadeIntensity: 1,
                opacityFrom: 0.7,
                opacityTo: 0.9,
                colorStops: [
                    {
                        offset: 0,
                        color: "#1396B4",
                        opacity: 1
                    },
                    {
                        offset: 100,
                        color: "#40B3AB",
                        opacity: 1
                    },

                ]
            }
        },
        plotOptions: {
            bar: {
                borderRadius: 4,
                horizontal: true,
                dataLabels: {
                    position: 'top'
                },
                barHeight:"32px",
                rowWidth:"10%"
            },

        },
        dataLabels: {
            enabled: true,

            style: {
                colors: ['gray'],
                fontSize: '12px',
                fontWeight: 'light',
            },
            offsetX: 30,
        },
        yaxis:{
            labels:{
                align:'left'
            }
        },
        xaxis: {
            max:maxX,
            categories: [
                t("diagnosis.bacterial-vaginosis"),
                t("diagnosis.cytolitic-vaginitis"),
                t("diagnosis.div"),
                t("diagnosis.trichomoniasis"),
                t("diagnosis.candida-albicans"),
                t("diagnosis.candida-non-albicans"),
                t("diagnosis.vaginal-atrophy"),
                t("diagnosis.normal-vaginal-secretions"),
                t("diagnosis.unknown"),
            ],
        }
    }
    const smApexChartOptions = JSON.parse(JSON.stringify(apexChartOptions));
    smApexChartOptions.xaxis.categories = [
        t("diagnosis.short.bacterial-vaginosis"),
        t("diagnosis.short.cytolitic-vaginitis"),
        t("diagnosis.short.div"),
        t("diagnosis.short.trichomoniasis"),
        t("diagnosis.short.candida-albicans"),
        t("diagnosis.short.candida-non-albicans"),
        t("diagnosis.short.vaginal-atrophy"),
        t("diagnosis.short.normal-vaginal-secretions"),
        t("diagnosis.short.unknown"),
    ]
    smApexChartOptions.chart.offsetX = 0;
    const [series,setSeries] = useState(
        [{
            data: [
                null,//Bacterial Vaginosis
                null,//Cytolytic Vaginosis
                null,//DIV
                null,//Trichomonas Vaginalis
                null,//Candida Albicans
                null,//Candida Non-Albicans
                null,//Vaginal Atrophy
                null,//Normal
                null,//Unknown
            ]
        }]
    )

    useEffect(()=>{
        axios.post(`${Config.globalUrl}:${Config.globalPort}/api/v1/webapp/doctors/${BackendCurrent.context.profile.userId}/getGraphData/`,{},{
                headers:
                    {
                        'Authorization': BackendCurrent.context.authToken
                    }
            }
        ).then(async (response) => {
            {

                let keys = Object.keys(response.data)
                let _series = {
                    data: [
                        null,//Bacterial Vaginosis
                        null,//Cytolytic Vaginosis
                        null,//DIV
                        null,//Trichomonas Vaginalis
                        null,//Candida Albicans
                        null,//Candida Non-Albicans
                        null,//Vaginal Atrophy
                        null,//Normal
                        null,//Unknown
                    ]
                }
                keys.forEach(k=>{
                    switch (k){
                        case  "Bacterial Vaginosis" :
                            _series.data[0] = response.data[k];
                            break;
                        case  "Cytolytic Vaginosis" :
                            _series.data[1] = response.data[k];
                            break;
                        case  "DIV" :
                            _series.data[2] = response.data[k];
                            break;
                        case  "Trichomonas Vaginalis" :
                            _series.data[3] = response.data[k];
                            break;
                        case  "Candida Albicans" :
                            _series.data[4] = response.data[k];
                            break;
                        case  "Candida Non-Albicans" :
                            _series.data[5] = response.data[k];
                            break;
                        case  "Vaginal Atrophy" :
                            _series.data[6] = response.data[k];
                            break;
                        case  "Normal" :
                            _series.data[7] = response.data[k];
                            break;
                        case  "Unknown" :
                        case "Other":
                            _series.data[8] = response.data[k];
                            break;
                        default :
                            return;
                    }
                })
                SetMaxX(Math.max(..._series.data)+5)
                setSeries([_series]);
                console.log(response)
            }
        })
            .catch( async (error) => {
                console.log(error)
            });
    },[])

    return(
        <div className={"pt-20"}>
            <GBigLabel className={"mt-20 flex justify-content-center text-2rem"}>{t("routing.statistics")}</GBigLabel>
            <div className={"sm:pr-2.813rem sm:pl-2.813rem pt-3.125rem pb-3.125rem ml-10 sm:ml-6.25rem mr-10 sm:mr-6.25rem mt-3.75rem mb-3.75rem shadow-gyni rounded-2xl"}>
                <GBigLabel  className={"text-1.375rem"}>{t("statistics.results-distribution")}</GBigLabel>
                <div className={"md:flex"}>

                    <div className={"md:w-5/6 "}>
                        <div className={"hidden sm:block"} id="chart">
                            <ReactApexChart options={apexChartOptions} series={series} type="bar" height={261} />
                        </div>
                        <div className={" sm:hidden"} id="chart">
                            <ReactApexChart options={smApexChartOptions} series={series} type="bar" height={361} />
                        </div>
                    </div>
                    <div className={"md:w-2/6 m-auto space-y-1.25rem max-w-15.188rem"}>
                        <div className={""}>
                            <GBigLabel color={"text-#50C2BC"} className={"text-0.75rem"}>{t("statistics.normal")} *</GBigLabel>
                            <GSmallLabel className={"text-0.75rem"}>{t("statistics.normal-text")}</GSmallLabel>
                        </div>
                        <div className={""}>
                            <GBigLabel color={"text-#50C2BC"} className={"text-0.75rem"}>{t("statistics.unknown")} **</GBigLabel>
                            <GSmallLabel className={"text-0.75rem"}>{t("statistics.unknown-text")}</GSmallLabel>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}
export default Statistics;
