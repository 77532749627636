
import jsPDF from "jspdf";
import 'jspdf-autotable';
export default function PDF(){}

PDF.ExportToPDF = function exportToPDF(HeaderObject,tableID,fileName,additionalText){

    const doc = new jsPDF();
    // doc.setFontSize(1);

    const img = new Image();
    img.src = 'https://static.wixstatic.com/media/cf6f8c_2b6b8862ca9d4acfa3a935859002c4de~mv2.png/v1/fill/w_230,h_61,al_c,usm_0.66_1.00_0.01,enc_auto/GYNTOOLS_LOGO_300dpi_cropped.png';
    img.onload = function(){
        const date = new Date();
        const dateString = date.getDate() + '/' + (date.getMonth()+1) + '/' + date.getFullYear();

        let startHeight = 40;
        let tableStart = 0;

        additionalText && additionalText.forEach(t=>{
            startHeight+=10;
        })

        // var header = function(data) {
        //     doc.setFontSize(1);
        //     doc.setFontStyle('normal');
        // }

        doc.autoTable({html: tableID,startX: 1,startY: startHeight,

            styles: { fontSize:6}
            ,didDrawPage: function (data) {
                // Header

                //doc.addFont(font, 'GothamHTF-Book', 'normal');

                //doc.setFont('GothamHTF-Book');

                doc.setFontSize(14);
                //doc.setFontStyle('normal');
                doc.addImage(img, 'png', 10, 10);
                doc.text(dateString, 170,17);
                doc.text(HeaderObject, 14,35);
                tableStart = 45;
                doc.setFontSize(10);

                additionalText && additionalText.forEach(t=>{

                    doc.text((Object.keys(t)[0]+":"+t[Object.keys(t)[0]]), 14,tableStart);
                    tableStart+=10;
                })
                // Footer
                const str = "Page " + doc.internal.getNumberOfPages();
                // Total page number plugin only available in jspdf v1.0+
                // if (typeof doc.putTotalPages === 'function') {
                //     str = str + " of " + doc.internal.getNumberOfPages();
                // }


                // jsPDF 1.4+ uses getWidth, <1.4 uses .width
                const pageSize = doc.internal.pageSize;
                const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
                doc.text(str, data.settings.margin.left, pageHeight);
            },
            margin: {top: 30}
        });

        doc.save(`${fileName}.pdf`);
    };

};
