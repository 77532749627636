import React, {Component, useContext, useEffect, useState} from 'react'
import axios from "axios";
import Config from "../../services/Config";
import {BackendContext} from "../../machines/BackendMachine";
import {useActor} from "@xstate/react";
import GSmallLabel from "../../RemasterComponents/GSmallLabel";

const AdminFotaPage = () => {

    const BEContext = useContext(BackendContext)
    let BackendCurrent, BESend;
    [BackendCurrent, BESend] = useActor(BEContext);

    const [FotaFileName,SetFotaFileName] = useState("");
    const [File,SetFile] = useState(null)

    useEffect(()=>{
        GetFotaFile();
    })

    const GetFotaFile=()=>{


        axios.get(`${Config.globalUrl}:${Config.globalPort}/api/v1/webapp/general/${BackendCurrent.context.profile.userId}/getFotaFile`, {headers: {'Authorization': BackendCurrent.context.authToken}})
            .then(async (response) => {
                SetFotaFileName(response.data);
            })
            .catch(async (error) => {
                console.log(error);
            });
    }
    const PickFile=(file)=>{
        SetFile(null)
        SetFile(file.target.files[0])
    }
    const UploadFile=()=>{

        let formData = new FormData();
        formData.append("fotaFile", File);

        axios.post(`${Config.globalUrl}:${Config.globalPort}/api/v1/webapp/general/${BackendCurrent.context.profile.userId}/UploadFWFile`,formData, {
            headers: {
                'Authorization': BackendCurrent.context.authToken,
                'Content-Type': File.type
            }
        }).then(async (response) => {
            GetFotaFile()
        }).catch(async (error) => {
            console.log(error);
        });
    }
    return(
        <div>
            <div className={"clinic-details-height-responsive pt-2"}>

                <div className={"  ml-10 sm:ml-6.25rem mr-10 sm:mr-6.25rem mb-3.75rem shadow-gyni rounded-2xl"}>
                    <div className={"lg:flex"}>
                        <div className={"pl-20 pr-20 pt-5 pb-5 w-full space-y-4"}>
                            <table className={"table-auto text-sm w-full border"}>
                                <thead className={"border-bottom"}>
                                <tr>
                                    <th className={"bg-gray-200 border-white border-2 pl-1"}>Current FW file</th>
                                </tr>
                                </thead>
                                <tbody>

                                    <tr className={" bg-gray-100  hover:bg-gray-300 cursor-pointer"}>
                                        <td className={"border-white border-2 pl-1 hover:bg-gyniCyan"}>
                                            {
                                                FotaFileName
                                            }
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div className={"flex"}>
                                <div className={"w-1/2"}>
                                    <input accept={".zip"} className={"cursor-pointer flex "} onChange={(evt)=>PickFile(evt)} type="file"/>
                                </div>
                                <div className={"w-1/2 flex justify-content-end"}>
                                    <div className={"cursor-pointer border w-20 rounded-xl flex shadow-xl  justify-content-center"} onClick={()=>UploadFile()}>Add</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    )
}

export default AdminFotaPage;

