import React, {useContext, useState} from 'react';
import CloseButton from "../assets/close_svg.svg"
import DeviceIssueIcon from "../assets/device_issue.png";
import GSmallLabel from "./GSmallLabel";
import GBigLabel from "./GBigLabel";
import GButton from "./GButton";
import GTransparentButton from "./GTransparentButton";

const GDiagnosisModal = (props) => {

    const [isModalOpen,setModalOpen] = useState(true);
    return (
        <div >
            {
                isModalOpen
                    ?
                    <div  className={"z-10 h-full w-full fixed  bg-#00163A backdrop-filter backdrop-blur flex"}>
                        <div className={"m-auto border-0 lg:rounded-3xl  "+ props.className}>
                            {/*HEADER*/}
                            <div className={"relative lg:static"}>
                                <div className={"absolute lg:static right-0 pt-2 pr-2 lg:pt-0 lg:pr-0 flex justify-content-end"}>
                                    <div onClick={()=>{
                                        if(props.handleClose){
                                            props.handleClose()
                                        }else {
                                            setModalOpen(false)
                                        }
                                    }} className={"flex bg-white rounded-full h-1.875rem w-1.875rem cursor-pointer"}>
                                        <div className={"m-auto "}>
                                            <img src={CloseButton} alt="x"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/*BODY*/}
                            <div  className={"lg:pr-1.875rem lg:pl-1.875rem "}>
                                {
                                    props.children
                                }
                            </div>
                        </div>
                    </div>
                    :
                    null
            }
        </div>
    )
};
export default GDiagnosisModal;

