import React from 'react';
import GSmallLabel from "./GSmallLabel";

const GLeftNumberBox = (props) => {
    return (
        <div className={"flex space-x-0.438rem"}>
            <div className={""}>
                <div className={"bg-white min-h-1.375rem h-1.375rem min-w-1.375rem w-1.375rem rounded-full shadow"}>


                    <GSmallLabel bold color={"text-#50C2BC"} className={"text-0.75rem text-center"}>
                        {
                            props.number
                        }
                    </GSmallLabel>

                </div>
                {
                    props.nodash
                    ?
                        null
                    :
                        <div className={"h-full w-0 ml-auto mr-auto"} style={{border:"1px dashed rgba(19, 150, 180, 0.3)"}} />
                }


            </div>

            <GSmallLabel className={"text-1rem  mb-0.75rem"}>
                {
                    props.children
                }
            </GSmallLabel>
        </div>
    )
};
export default GLeftNumberBox;
