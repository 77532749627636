import React, {useContext, useEffect, useState} from 'react'
import GBigLabel from "./GBigLabel";
import myVideo from "../assets/gynd-movie.mp4";
import GSmallLabel from "./GSmallLabel";
import GButton from "./GButton";
import GTransparentButton from "./GTransparentButton";
import GModal from "./GModal";
import {GlobalContext} from "../machines/GlobalStateMachine";
import {useActor} from "@xstate/react";
import {useTranslation} from "react-i18next";
import GTextInput from "./GTextInput";
import i18n from "../i18n";
import axios from "axios";
import Config from "../services/Config";
import {BackendContext} from "../machines/BackendMachine";

const MustResetPassword=(props)=>{
    const { t, i18n } = useTranslation()

    const BEContext = useContext(BackendContext)
    let BackendCurrent, BESend;
    [BackendCurrent, BESend] = useActor(BEContext);
    const isLetter =(c)=> {
        return (c >= 'a' && c <= 'z') ||
            (c >= 'A' && c <= 'Z')
    }
    const validatePassword =(password,rePassword)=>{
        let _hasCapital = false;
        let _includeUserName = false;
        let _isValid = false;
        let _minimumLength = password.length >= 8;
        let _containLetters = /[a-z]/i.test(password);
        let _containDigits = /[0-9]/i.test(password);
        let _containSpecialSymbols = /[!#$%&?]/.test(password)

        for(let i = 0 ; i < password.length ; i++){
            if(isLetter(password[i])){
                if (password[i] == password[i].toUpperCase()) {
                    _hasCapital = true;
                }
            }
        }

        if(password.indexOf(BackendCurrent.context.userProfile.Email) > -1){
            _includeUserName = true
        }

        _isValid = _minimumLength && _containLetters && _containDigits && _containSpecialSymbols && _hasCapital && !_includeUserName;


        let _validationError = ""
        let _matchError = ""

        if(!_minimumLength){
            _validationError+= "Minimum 8 symbols"
        }
        else if(!_containLetters){
            _validationError+= "Must contain Letters"
        }
        else if(!_containDigits){
            _validationError+= "Must contain Digits"
        }
        else if(!_containSpecialSymbols){
            _validationError+= "Must Contain !#$%&?"
        }else if(!_hasCapital){
            _validationError+= "Must Contain Capital Letters"
        }else if(_includeUserName){
            _validationError+= "Must Not Contain Email"
        }

        let _isMatch = password === rePassword;

        if(!_isMatch){
            _matchError+= t('error.passwords-do-not-match')
        }
        if(!_isMatch || !_isValid){
            return {
                isError:true,
                notMatch:_isMatch,
                notValid:_isValid,
                validationError:_validationError,
                matchError:_matchError
            }
        }else{
            return {
                isError:false,
                notMatch:_isMatch,
                notValid:_isValid,
                validationError:"",
                matchError:""
            }
        }

    }
    const [isPasswordResetSuccess,  setPasswordResetSuccess     ] = useState(false)
    const [isPasswordResetError ,   setPasswordResetError       ] = useState(false)
    const [matchError           ,   setPasswordNotMatchError    ]=useState("")
    const [beError,setBeError] = useState("")
    const [currentPasswordError ,   setCurrentPasswordError     ]=useState("")
    const [validError           ,   setPasswordNotValid         ]=useState("")
    useEffect(()=>{
        setCurrentPassword("")
        setNewPassword("")
        setRePassword("")
    },[])
    const sendResetPassword=async ()=>{
        await setPasswordResetError(false);
        await setPasswordResetSuccess(false)
        await setBeError("")
        // if(currentPassword === ""){
        //     await setCurrentPasswordError(t("error.mandatory-field"))
        // }
        let validated = validatePassword(newPassword,rePassword);
        if(!validated.isError){ //&& currentPassword !== ""){
            //TODO : SEND TO BACKEND
            ///api/v1/webapp/doctors/Doctor45/

            setPasswordNotMatchError("")
            // setCurrentPasswordError("")
            setPasswordNotValid("")
            // let url = `${Config.globalUrl}:${Config.globalPort}/api/v1/webapp/doctors/${BackendCurrent.context.profile.userId}/setNewPassword/`;
            let url = `${Config.globalUrl}:${Config.globalPort}/api/v1/webapp/${BackendCurrent.context.profile.userId}/changePass`;
            axios.post(url, {
                "newPassword":newPassword,
                "UserID":BackendCurrent.context.profile.userId
            },{headers: {'Authorization': BackendCurrent.context.authToken}})
                .then(async (response) => {
                    console.log(response)
                    setPasswordResetSuccess(true)
                    setBeError("")
                    setCurrentPassword("")
                    setNewPassword("")
                    setRePassword("")
                    setTimeout(()=>{
                        UpdateGlobal({type:"CLOSE_RESET_PASSWORD"})
                    },2000)
                })
                .catch( async (error) => {
                    console.log(error);
                    setBeError(error.response.data.error.message)
                    setPasswordResetError(true);
                });
        }else{
            if(!validated.notMatch){
                setPasswordNotMatchError(validated.matchError)
            }else{
                setPasswordNotMatchError("")
            }
            if(!validated.notValid){
                setPasswordNotValid(validated.validationError)
            }else{
                setPasswordNotValid("")
            }
        }
    }


    const GContext = useContext(GlobalContext)
    const [GlobalCurrentState, UpdateGlobal] = useActor(GContext);

    const [currentPassword  ,setCurrentPassword ] = useState("");
    const [newPassword      ,setNewPassword     ] = useState("");
    const [rePassword       ,setRePassword      ] = useState("")

    return(
        <GModal hideClose hideCloseButton>
            <div className={" "}>
                {
                    !isPasswordResetSuccess
                    ?
                        <GBigLabel className={"sm:text-2rem mt-1.5rem text-center"}>
                        {t("routing.reset-password")}
                        </GBigLabel>
                    :
                        null
                }

                {
                    isPasswordResetSuccess
                    ?
                        <GBigLabel className={"sm:text-1.25rem text-center"}>
                            {t("login.sign-up.password-reseted-short")}
                        </GBigLabel>
                    :
                        null
                }
                {
                    isPasswordResetError
                        ?
                        <>
                            <GBigLabel className={"sm:text-1.25rem text-center"}>
                                {t("login.sign-up.password-reseted-error")}
                            </GBigLabel>
                            <GSmallLabel className={"text-xs text-center"}>
                                {beError}
                            </GSmallLabel>
                        </>

                        :
                        null
                }
                {
                    !isPasswordResetSuccess
                    ?
                        <div className={"lg:mt-3rem lg:h-22rem"}>
                            <div  className={"m-10 space-y-6"}>
                                <div className={"space-y-1.25rem"}>
                                    {/*<GTextInput password*/}
                                    {/*            iconPosition={"top-5"} validation error={currentPasswordError} title={t("login.password.current-password")} value={currentPassword}  onChange={(e)=>setCurrentPassword(e.target.value)} borderStyle={" rounded-2xl border border-#1396B4 border-1px "} className={"w-18.438rem h-3.125rem"}/>*/}
                                    <GTextInput max={50} password
                                                iconPosition={"top-5"} validation error={validError} title={t("login.password.new-password")} value={newPassword}  onChange={(e)=>setNewPassword(e.target.value)} borderStyle={" rounded-2xl border border-#1396B4 border-1px "} className={"w-18.438rem h-3.125rem"}/>
                                    <GTextInput max={50} password
                                                iconPosition={"top-5"} validation error={matchError} title={t("login.password.re-password")} value={rePassword}  onChange={(e)=>setRePassword(e.target.value)} borderStyle={" rounded-2xl border border-#1396B4 border-1px "} className={"w-18.438rem h-3.125rem"}/>
                                </div>
                                <div className={"pl-2.625rem pr-2.625rem mb-10"}>
                                    <GButton onClick={()=>sendResetPassword()} className={"cursor-pointer m-auto max-w-10.438rem h-3.125rem"}>
                                        {t("general.update")}
                                    </GButton>

                                </div>
                            </div>
                        </div>
                    :
                        null
                }

            </div>
        </GModal>
    )
}
export default MustResetPassword
