import React, {useState} from "react"
import Arrow from "../assets/arrow_svg.svg"
import GRadioButton from "./GRadioButton";
const GCarousel=(props)=>{

    const [selectedPage,SetSelectedPage] = useState(0)
    let i,j, temporary =[], chunk = props.size;

    let data =props.children.filter(x=>x !== null)
    for (i = 0,j = data.length; i < j; i += chunk) {
        temporary.push(data.slice(i, i + chunk));
    }
    let pagesArray = temporary.filter(x=>x !== null);


    return(
            props.siblingControl
            ?
                <div className={props.className}>
                    <div className={"flex relative "}>
                        {
                            pagesArray.length > 1
                                ?
                                    <div className={"absolute h-full opacity-0 hover:opacity-100"}>
                                        <div className={"flex  h-full"}>
                                            <div
                                                onClick={() => props.selectedPage > 0 ? props.SetSelectedPage(props.selectedPage - 1) : null}
                                                className={"flex mt-auto mb-auto"}>
                                                <img className={"h-10 w-10 rotate-90 transform"} src={Arrow} alt={"left"}/>
                                            </div>
                                        </div>
                                    </div>
                                :
                                null
                        }
                        {
                            pagesArray.map((child, index) =>
                                <div key={index}
                                     className={"ml-auto mr-auto " + props.pageStyle + " " + (index !== props.selectedPage ? " hidden " : " ")}>
                                    {
                                        child
                                    }
                                </div>
                            )
                        }
                        {
                            pagesArray.length > 1
                            ?
                                <div className={"absolute right-0 opacity-0 hover:opacity-100 h-full"}>
                                    <div className={"flex justify-content-end h-full "}>
                                        <div
                                            onClick={() => props.selectedPage < pagesArray.length - 1 ? props.SetSelectedPage(props.selectedPage + 1) : null}
                                            className={"flex mt-auto mb-auto"}>
                                            <img className={"h-10 w-10 rotate-minus-90 transform"} src={Arrow} alt={"right"}/>
                                        </div>
                                    </div>
                                </div>
                            :
                                null
                        }

                    </div>
                    <div className={"flex justify-content-center"}>
                        {
                            pagesArray.length > 1 && pagesArray.map((child, index) =>
                                <GRadioButton onClick={() => props.SetSelectedPage(index)} checked={index === props.selectedPage}/>
                            )
                        }
                    </div>
                </div>
            :
                <div className={props.className}>
                <div className={"flex relative"}>
                    {
                        pagesArray.length > 1
                        ?
                            <div className={"absolute h-full opacity-0 hover:opacity-100"}>
                                <div className={"flex  h-full"}>
                                    <div onClick={() => selectedPage > 0 ? SetSelectedPage(selectedPage - 1) : null}
                                    className={"flex mt-auto mb-auto"}>
                                        <img className={"h-10 w-10 rotate-90 transform"} src={Arrow} alt={"left"}/>
                                    </div>
                                </div>
                            </div>
                        :
                            null
                    }

                    {
                        pagesArray.map((child, index) =>
                            <div key={index}
                                 className={"ml-auto mr-auto " + props.pageStyle + " " + (index !== selectedPage ? " hidden " : " ")}>
                                {
                                    child
                                }
                            </div>
                        )
                    }
                    {
                        pagesArray.length > 1
                            ?
                            <div className={"absolute right-0 opacity-0 hover:opacity-100 h-full"}>
                                <div className={"flex justify-content-end h-full "}>
                                    <div
                                        onClick={() => selectedPage < pagesArray.length - 1 ? SetSelectedPage(selectedPage + 1) : null}
                                        className={"flex mt-auto mb-auto"}>
                                        <img className={"h-10 w-10 rotate-minus-90 transform"} src={Arrow}
                                             alt={"right"}/>
                                    </div>
                                </div>
                            </div>
                            :
                            null
                    }
                </div>
                <div className={"flex justify-content-center "}>
                    {
                        pagesArray.length > 1 && pagesArray.map((child, index) =>

                            <GRadioButton onClick={() => SetSelectedPage(index)} checked={index === selectedPage}/>
                        )
                    }
                </div>
            </div>
    )
}
export default GCarousel;
