import React, {useEffect, useState} from "react"
import CBIcon from "../assets/cb_icon.png";
import CCBIcon from "../assets/checked_cb_icon.png"
import GSmallLabel from "./GSmallLabel";
const GCheckBox =(props)=>{
    const [isChecked,setChecked] = useState(props.checked)
    useEffect(()=>{
        if(!props.disabled)
            setChecked(props.checked)
    },[props.checked])
    console.log(props.checked)
    return(
        <div  className={"  "+props.className} style={ props.borderOnCheck && isChecked ? {border:"solid 1px #1396B4",borderRadius:"15px"} : {border:"solid 1px transparent",borderRadius:"15px"}} >
            <div onClick={()=> {
                if(!props.disabled) {
                    setChecked(!isChecked)
                    props.onClick(!isChecked)
                }
            }} className={"cursor-pointer  flex space-x-0.813rem mb-1.5rem "}>
                <div  className={"h-5 w-5 mb-auto mt-auto"} >
                    {
                        isChecked
                            ?
                            <div>
                                <img src={CCBIcon} alt={"checked_icon"}/>
                            </div>
                            :
                            <div>
                                <img src={CBIcon} alt={"not_checked_icon"}/>
                            </div>
                    }
                </div>
                <div>
                    <GSmallLabel className={" text-left"}>
                        {
                            props.text
                        }
                    </GSmallLabel>
                </div>
            </div>
            {
                isChecked
                ?
                    <div>
                        {
                            props.children
                        }
                    </div>
                    :null
            }

        </div>
    )
}
export default GCheckBox;
