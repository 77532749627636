import React, {useEffect, useState} from 'react';
import bell from "../assets/bell.png";
import GSmallLabel from "./GSmallLabel";
const GMessagesIcon = (props) => {

    const [numberOfNewMessages, setNumberOfNewMessages ] = useState(0)
    const [showIcon,setShowIcon] = useState(true)

    const calculateUnreaded=(localStorageItemName,array,setHandler)=>{
        let count = 0;
        let t_messages   = JSON.parse(localStorage.getItem(localStorageItemName))
        if(t_messages){

            array.forEach(m=>{
                if(t_messages.filter(x=>x.Message === m.Message).length === 0){
                    count++;
                }
            })

            return count;

        }else{
            localStorage.setItem(localStorageItemName,JSON.stringify(array))
        }
    }

    useEffect( ()=>{
        let count = 0;

        count += calculateUnreaded("Messages",props.messageArray.filter(x=>x.MessageType === "Message"))
        count += calculateUnreaded("Updates",props.messageArray.filter(x=>x.MessageType === "Update"))
        count += calculateUnreaded("Promos",props.messageArray.filter(x=>x.MessageType === "Promo"))

        setNumberOfNewMessages(count)
    })

    return (
        <div onClick={props.onClick} className={"cursor-pointer relative "}>
            {
                showIcon
                ?
                    <div style={{zoom:0.6}} className={"relative w-12 h-12  m-auto "}>
                        <img style={{zIndex:-1}} className={"absolute top-2"} src={bell}/>
                        <div style={{zIndex:10}} className={"flex justify-content-end"}>
                            {
                                numberOfNewMessages > 0
                                    ?
                                    <div className={"absolute top-1.5 right-0.5 h-8 w-8 flex rounded-full border-2 border-white bg-red-500 "}>
                                        <div className={"text-md m-auto text-white "}>
                                            {
                                                numberOfNewMessages
                                            }
                                        </div>
                                    </div>
                                    :
                                    null
                            }
                        </div>
                    </div>
                :
                    <div className={"h-full flex place-items-center "}>
                        {
                            numberOfNewMessages > 0
                                ?
                                <GSmallLabel bold>Notifications ({numberOfNewMessages})</GSmallLabel>
                                :
                                <GSmallLabel>Notifications</GSmallLabel>
                        }
                    </div>

            }


        </div>

    )
};
export default GMessagesIcon;
