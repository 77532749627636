import React from 'react';

const GSmallLabel = (props) => {
    let commonStyle = " "
    let fontStyle = ` ${props.bold ? " font-ghotamBold  " : " "} ` + ( props.color ? props.color : " text-#666767 ")
    let borderStyle = " ";
    return (
        <div className={commonStyle+fontStyle+borderStyle+" "+props.className +"  g-small-label "} onClick={props.onClick}>
            {
                props.children
            }
        </div>
    )
};
export default GSmallLabel;

