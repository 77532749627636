import React from 'react';
import GSmallLabel from "./GSmallLabel";

const GButton = (props) => {
    let innerHTML = props.children;
    let commonStyle = " bg-gradient-to-l from-#50C2BC to-#1396B4  "
    let fontStyle = " text-base text-white text-center text-0.875rem"
    let borderStyle = " rounded-2.688rem ";
    const goClick =()=>{
        if(!props.disabled) {
            props.onClick()
        }
    }

    return (
        <div   onKeyPress={(e)=>props.onKeyPress(e)} style={props.disabled?{background:"lightgray"}:{}} onClick={()=>goClick()} className={(props.customStyle ? " " : (commonStyle+borderStyle+"  shadow-gyni ")) + fontStyle +"  flex select-none  cursor-pointer "+props.className}>
            <GSmallLabel bold color={ props.customStyle ? props.textColor : " text-white "} className={"m-auto"}>
            {
                innerHTML
            }
            </GSmallLabel>
        </div>
    )
};
export default GButton;
