
import {createMachine, MachineConfig, MachineOptions} from "xstate";
import {assign} from "xstate";
import React from "react";
import {
    Confirm2faAdmin,
    forgetPassword,
    login,
    logout,
    reconnectMqtt,
    registerUser,
    resetPassword
} from "../services/BackendService.js"
import axios from "axios";
import Config from "../services/Config";

//State machine schema
interface BackendSchema {
    initial:'notAuthenticated',
    states: {
        notAuthenticated:{},
        authenticated:{},
        requestLogin:{},
        Confirm2fa:{},
        Check2fa:{},
        requestMqtt:{},
        requestResetPassword:{},
        requestSignUp:{},
        requestForget:{},
        requestLogout:{},
        getCurrentRunningTest:{},
        getClinicalData:{},
        getProfile:{},
        getTestById:{}
    };
}
//Events
type BackendEvent =
    | { type : "REQUEST_LOGIN" ; login:string,password:string,reCaptchaToken:string}
    | { type : "CONFIRM_2FA" , code:string}
    | { type : "REQUEST_MQTT"}
    | { type : "SET_IS_PASSWORD_RESETED_FALSE"}
    | { type : "REQUEST_RESET_PASSWORD",password:string,rePassword:string}
    | { type : "REQUEST_SIGN_UP" , data:{}}
    | { type : "REQUEST_LOGOUT" }
    | { type : "FETCH_DATA" , data:{}}
    | { type : "UPDATE_DATA", data:{}}
    | { type : "UPDATE_USER_PROFILE",data:{}}
    | { type : "GET_CLINICAL_DATA"}
    | { type : "GET_CURRENT_RUNNING_TEST"}
    | { type : "GET_TEST_BY_ID" , testId:string}
    | { type : "RESET_USER_CREATED"}
    | { type : "RESET_PASSWORD_FORGET"}
    | { type : "REQUEST_FORGET_PASSWORD"}
    | { type : "RESET_REQUESTED_TEST" }
    | { type : "RESET_MACHINE"}
//context
export interface BackendContextType{
    isPasswordReseted:boolean,
    isAuthenticated:boolean,

    authToken:string,
    updatePassword:boolean,
    await2fa:boolean,
    TemporaryPassword:boolean,
    failedAttempts:number,
    role:[],
    accessKeyId:string,
    secretAccessKey:string,
    sessionToken:string,
    userProfile:{},
    tests:[],
    testSummary:{},
    currentRunningTest:{},
    profile:{
        email:string,
        userId:string,
        deviceName:string,
        clinicName:string,
        siteAdmin:string,
        clinicEmail:string,
        clinicPhone:string,
        clinicZip:string,
        clinicLocation:object,
        clinicId:string,
        createdAt:string,
    },
    userCreated:string,
    userCreationError:string,
    forgetPasswordStatus:string,
    forgetPasswordError :string,
    errorsList:[],
    MQTTClient:object,
}

//machine config
const BackendMachineConfig : MachineConfig<any,BackendSchema,BackendEvent> =
    {
        id: "BackendMachine",
        context: {
            isPasswordReseted:false,
            isAuthenticated:false,
            await2fa:false,
            TemporaryPassword:false,
            updatePassword:false,
            authToken:"",
            failedAttempts:0,
            role:[],
            accessKeyId:"",
            secretAccessKey:"",
            sessionToken:"",
            userProfile:{},
            tests:[],
            currentRunningTest:{},
            testSummary:null,
            profile:{
                email:"",
                userId:"",
                deviceName:"",
                clinicName:"",
                siteAdmin:"",
                clinicEmail:"",
                clinicPhone:"",
                clinicZip:"",
                clinicLocation:{},
                clinicId:"",
                createdAt:"",
            },
            userCreated:"",
            userCreationError:"",
            forgetPasswordStatus:"",
            forgetPasswordError:"",
            errorsList:[],
        },
        initial: "notAuthenticated",
        states: {
            notAuthenticated:{
                on:{
                    RESET_USER_CREATED:{
                      actions:assign((ctx,evt)=>{
                          ctx.userCreated=""
                          ctx.userCreationError=""
                      })
                    },
                    RESET_PASSWORD_FORGET:{
                        actions:assign((ctx,evt)=>{
                            ctx.forgetPasswordStatus    =""
                            ctx.forgetPasswordError     =""
                        })
                    },
                    REQUEST_FORGET_PASSWORD:{
                        actions:assign((ctx,evt)=>{
                            ctx.errorsList = [];
                        }),
                        target:"requestForget",
                    },
                    REQUEST_SIGN_UP:{
                        actions:assign((ctx,evt)=>{
                            ctx.errorsList = [];
                        }),
                        target:"requestSignUp",
                    },
                    REQUEST_LOGIN:{
                        actions:assign((ctx,evt)=>{
                           ctx.errorsList = [];
                        }),
                        target:"requestLogin",
                    },
                    SET_IS_PASSWORD_RESETED_FALSE:{
                        actions:assign((ctx,evt)=>{
                            ctx.isPasswordReseted = false;
                        })
                    },
                    REQUEST_RESET_PASSWORD:{
                        actions:assign((ctx,evt)=>{
                            ctx.isPasswordReseted = false;
                            ctx.errorsList = [];
                        }),
                        target:"requestResetPassword",
                    },
                    FETCH_DATA:{

                    },
                    UPDATE_DATA:{

                    },
                    RESET_MACHINE:{
                        actions:"resetMachine",
                    }
                }
            },
            authenticated:{
                on:{
                    REQUEST_RESET_PASSWORD:{
                        actions:assign((ctx,evt)=>{
                            ctx.isPasswordReseted = false;
                            ctx.errorsList = [];
                        }),
                        target:"requestResetPassword",
                    },
                    REQUEST_MQTT:{
                        target:"requestMqtt",
                    },
                    REQUEST_LOGOUT:{
                        target:"requestLogout",
                    },
                    UPDATE_USER_PROFILE:{
                       target:"getProfile"
                    },
                    GET_CLINICAL_DATA:{
                        target:"getClinicalData"
                    },
                    GET_CURRENT_RUNNING_TEST:{
                        target:"getCurrentRunningTest"
                    },
                    GET_TEST_BY_ID:{
                        target:"getTestById"
                    },
                    RESET_REQUESTED_TEST:{
                        actions:assign((ctx,evt)=>{
                            ctx.testSummary = null
                        })
                    },
                    RESET_MACHINE:{
                        actions:"resetMachine",
                    }
                }
            },
            getClinicalData:{
                invoke: {
                    src:(ctx,evt)=>{
                        return new Promise((resolve,reject)=>{
                            axios.get(`${Config.globalUrl}:${Config.globalPort}/api/v1/webapp/doctors/${ctx.profile.userId}/getAllLabTestResults`,{headers: {'Authorization': ctx.authToken}})
                                .then( (response) => {
                                    resolve(response.data)
                                })
                                .catch(  (error) => {
                                    reject(error)
                                });
                        })
                    },
                    onError: {
                        target:"authenticated"
                    },
                    onDone: {
                        actions:assign((ctx,evt)=>{
                            ctx.tests = evt.data;
                        }),
                        target:"authenticated"
                    }
                }
            },
            getProfile:{
                invoke: {
                    src:(ctx,evt)=>{
                        return new Promise((resolve,reject)=>{
                            axios.get(`${Config.globalUrl}:${Config.globalPort}/api/v1/webapp/doctors/${ctx.profile.userId}/profile`, {headers: {'Authorization': ctx.authToken}})
                                .then(async (userProfile) => {
                                    resolve(userProfile.data)
                                }).catch(error => {
                                    reject(error)
                            })
                        })
                    },
                    onError: {
                        target:"authenticated"
                    },
                    onDone: {
                        actions:assign((ctx,evt)=>{
                            ctx.userProfile = evt.data;
                        }),
                        target:"authenticated"
                    }
                }
            },
            getTestById:{
                invoke: {

                    src:(ctx,evt)=>{
                        return new Promise((resolve,reject)=>{
                            axios.get(`${Config.globalUrl}:${Config.globalPort}/api/v1/webapp/doctors/${ctx.profile.userId}/TestData?TestID=${ctx.currentRunningTest.TestID}`,{headers: {'Authorization': ctx.authToken}})
                            // axios.get(`${Config.globalUrl}:${Config.globalPort}/api/v1/webapp/doctors/${ctx.profile.userId}/TestData?TestID=${228}`,{headers: {'Authorization': ctx.authToken}})
                                .then(async (response) => {
                                    resolve(response.data)
                                })
                                .catch( (error) => {
                                    reject(error);
                                });
                        })
                    },
                    onError: {
                        target:"authenticated"
                    },
                    onDone: {
                        actions:assign((ctx,evt)=>{
                            ctx.testSummary = evt.data;
                        }),
                        target:"authenticated"
                    }
                }
            },
            getCurrentRunningTest:{
                invoke: {
                    src:(ctx,evt)=>{
                        return new Promise((resolve,reject)=>{
                            axios.get(`${Config.globalUrl}:${Config.globalPort}/api/v1/webapp/doctors/${ctx.profile.userId}/getCurrentRunningTest/`,{headers: {'Authorization': ctx.authToken}})
                                .then(async (response) => {
                                    resolve(response.data)
                                })
                                .catch( (error) => {
                                    reject(error);
                                });
                        })
                    },
                    onError: {
                        target:"authenticated"
                    },
                    onDone: {
                        actions:assign((ctx,evt)=>{
                            ctx.currentRunningTest = evt.data;
                        }),
                        target:"authenticated"
                    }
                }
            },
            requestForget:{
                invoke: {
                    src: "requestForget",
                    onDone: {
                        actions:"passwordRestored",
                        target:"notAuthenticated",
                    },
                    onError: {
                        actions:"passwordRestored",
                        target:"notAuthenticated"
                    }
                }
            },
            requestSignUp:{
                invoke: {
                    src: "requestSignUp",
                    onDone: {
                        actions:"userCreated",
                        target:"notAuthenticated",
                    },
                    onError: {
                        actions:"userCreated",
                        target:"notAuthenticated"
                    }
                }
            },
            requestResetPassword:{
                invoke: {
                    src: "resetPasswordAction",
                    onDone: {
                        actions:"setPasswordReseted",
                        target:"notAuthenticated",
                    },
                    onError: {
                        actions:"notifyError",
                        target:"notAuthenticated"
                    }
                }
            },
            requestMqtt:{
                invoke: {
                    src: "mqttAction",
                    onDone: {
                        actions:"setMqttClient",
                        target:"authenticated",
                    },
                    onError: {
                        actions:"notifyError",
                        target:"authenticated"
                    }
                }
            },
            requestLogin:{
                invoke: {
                    src: "loginAction",
                    onDone: [
                        {
                            cond:(ctx,evt)=>evt.data.await2fa === true,
                            actions:"set2FaToken",
                            target:"Confirm2fa"
                        },
                        {
                            actions:"setAuthToken",
                            target: "authenticated",
                        },
                    ],
                    onError: {
                        actions:"notifyError",
                        target:"notAuthenticated"
                    }
                }
            },
            Confirm2fa:{
                on:{
                    CONFIRM_2FA:{
                        target:"Check2fa"
                    }
                }
            },
            Check2fa:{
                invoke: {
                    src: "Confirm2faAdmin",
                    onDone: {
                        actions:"setAuthToken",
                        target:"authenticated",
                    },
                    onError: {
                        actions:"notifyError",
                        target:"notAuthenticated"
                    }
                }
            },
            requestLogout:{
                invoke: {
                    src: "logoutAction",
                    onDone: {
                        actions:"destroyAuthToken",
                        target:"notAuthenticated",
                    },
                    onError: {
                        actions:"notifyError",
                        target:"notAuthenticated"
                    }
                }
            }
        }
    };

const RoutingMachineOptions : MachineOptions<any,any,any> =
    {
        activities: {},
        delays: {},
        guards: {
            // // input guards
            // isNoUsername: (context, event) => context.username.length === 0,
            // isUsernameBadFormat: context =>  {
            //     // let re = /\S+@\S+\.\S+/;
            //     // return !re.test(context.username);
            //     return false
            // },
            // isNoPhoneNumber: (context, event) => context.phoneNumber.length === 0,
            // isNoLoginCode: (context, event) => context.loginCode.length === 0,
            // isPhoneNumberShort: (context, event) => context.phoneNumber.length < 5,
            // //be guards
            // isNoAccount: (context, evt) => evt.data.code === 1,
            // isWrongLoginCode: (context, evt) => evt.data.code === 1,
            // isIncorrectPassword: (context, evt) => evt.data.code === 2,
            // isNoResponse: (context, evt) => evt.data.code === 3,
            // isInternalServerErr: (context, evt) => evt.data.code === 4

        },
        services: {
            resetPasswordAction : (ctx,evt) =>resetPassword(evt.password,evt.rePassword,ctx.authToken),
            loginAction: (ctx,evt) => login(evt.login,evt.password,evt.reCaptchaToken),
            Confirm2faAdmin:(ctx,evt)=>Confirm2faAdmin(ctx.authToken,evt.code),
            mqttAction: (ctx,evt)=>reconnectMqtt(ctx.userProfile.UserID,ctx.deviceName),
            logoutAction: (ctx,evt) => logout(),
            requestSignUp: (ctx,evt)=> registerUser(evt.data),
            requestForget:(ctx,evt)=> forgetPassword(evt.email)
            // requestLogin: ctx => contactSecondStepAuthService(ctx.token),
            // requestLogout : ctx => Logout(),
        },
        actions: {
            // BeforeStatisticsEnter:assign((ctx,evt)=>{
            //     data:{
            //         currentScreen:evt.data
            //     }
            // }),
            //
            //
            resetMachine:assign((ctx, evt) => ({



                isAuthenticated :false,
                await2fa : false,
                TemporaryPassword:false,
                authToken       :null,
                failedAttempts:0,
                role            :null,
                accessKeyId     :null,
                updatePassword : false,
                secretAccessKey :null,
                sessionToken    :null,
                userProfile     :null,
                profile:{
                    email           :null,
                    userId          :null,
                    deviceName      :null,
                    clinicName      :null,
                    siteAdmin       :null,
                    clinicEmail     :null,
                    clinicPhone     :null,
                    clinicZip       :null,
                    clinicLocation  :null,
                    clinicId        :null,
                    createdAt       :null,
                },
                MQTTClient      :null,
                isPasswordReseted:false,
                tests:[],
                currentRunningTest:{},
                testSummary:null,
                userCreated:"",
                userCreationError:"",
                forgetPasswordStatus:"",
                forgetPasswordError:"",
                errorsList:[],
            })),
            destroyAuthToken:assign((ctx, evt) => ({
                isAuthenticated :false,
                authToken       :null,
                await2fa : false,
                TemporaryPassword:false,
                failedAttempts  : 0 ,
                role            :null,
                accessKeyId     :null,
                secretAccessKey :null,
                updatePassword : false,
                sessionToken    :null,
                userProfile     :null,
                profile:{
                    email           :null,
                    userId          :null,
                    deviceName      :null,
                    clinicName      :null,
                    siteAdmin       :null,
                    clinicEmail     :null,
                    clinicPhone     :null,
                    clinicZip       :null,
                    clinicLocation  :null,
                    clinicId        :null,
                    createdAt       :null,
                },
                MQTTClient      :null,
            })),
            setPasswordReseted:assign((ctx,evt)=>{
                ctx.isPasswordReseted = true
            }),
            setMqttClient:assign((ctx, evt) => ({
                MQTTClient      :evt.data.MQTTClient,
            })),
            set2FaToken:assign((ctx,evt)=>({
                await2fa:evt.data?.await2fa,
                authToken:evt.data?.temp2faToken,
            })),
            setAuthToken:assign((ctx, evt) => ({
                isAuthenticated :true,
                authToken       :evt.data?.authToken,
                failedAttempts  : 0,
                updatePassword: evt.data?.updatePassword,
                role            :evt.data?.role,
                accessKeyId     :evt.data?.accessKeyId,
                secretAccessKey :evt.data?.secretAccessKey,
                sessionToken    :evt.data?.sessionToken,
                userProfile     :evt.data?.userProfile?.data,
                TemporaryPassword:evt.data?.TemporaryPassword,
                profile:{
                    email           :evt.data.profile?.email,
                    userId          :evt.data.profile?.userId,
                    deviceName      :evt.data.profile?.deviceName,
                    clinicName      :evt.data.profile?.clinicName,
                    siteAdmin       :evt.data.profile?.siteAdmin,
                    clinicEmail     :evt.data.profile?.clinicEmail,
                    clinicPhone     :evt.data.profile?.clinicPhone,
                    clinicZip       :evt.data.profile?.clinicZip,
                    clinicLocation  :evt.data.profile?.clinicLocation,
                    clinicId        :evt.data.profile?.clinicId,
                    createdAt       :evt.data.profile?.createdAt,
                },
                MQTTClient      :evt.data.MQTTClient,
            })),
            userCreated:assign((ctx,evt)=>{
                ctx.userCreated = evt.data.userCreated
                ctx.userCreationError = evt.data.error ? evt.data.error.response.data.error : "unknown error"
            }),
            passwordRestored:assign((ctx,evt)=>{
                ctx.forgetPasswordStatus   = evt.data.forgetPasswordStatus
                ctx.forgetPasswordError    = evt.data.error ? evt.data.error.response.data.error : "unknown error"
            }),
            notifyError:(ctx,evt)=>{
                ctx.failedAttempts = ctx.failedAttempts + 1;
                ctx.errorsList.push(evt.data.error)
            }
            // focusUsernameInput: (ctx) => {
            //     console.log("focusUsernameInput ctx.username >",ctx.username)
            // },
            // focusPasswordInput: (ctx) => {
            //     console.log("focusPasswordInput ctx.username >",ctx.phoneNumber)
            // },
            // focusLoginCodeInput: (ctx) => {
            //     console.log("focusLoginCodeInput ctx.loginCode >",ctx.loginCode)
            // },
            // cacheUsername: assign((ctx, evt) => ({
            //     username: evt.username
            // })),
            // cachePassword: assign((ctx, evt) => ({
            //     phoneNumber: evt.phoneNumber
            // })),
            // redirectToSignIn: ()=>{
            //     console.log("AZURE REDIRECTION");
            // },
            // cacheLoginCode:assign((ctx, evt) => ({
            //     loginCode: evt.loginCode
            // })),
            // onLoginCode:assign((ctx, evt) => ({
            //     isLoginCodeSent: evt.data.codeSent,
            // })),
            // onLoggedIn:assign((ctx, evt) => ({
            //     isLoggedIn: true,
            //     token:evt.data.token,
            //     role:evt.data.role
            // })),
            // onLoggedOut:assign((ctx, evt) => ({
            //     isLoggedIn: false,
            //     isLoginCodeSent:false,
            //     token:"",
            //     role:"",
            // })),

        }
    }
//
export const BackendContext = React.createContext({});
export const BackendMachine = createMachine<BackendContextType,BackendEvent>(BackendMachineConfig,RoutingMachineOptions)
