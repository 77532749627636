import React, {useContext, useEffect, useState} from 'react';
import DeviceIssueIcon from "../../assets/device_issue.png";
import ScanCompleteIcon from "../../assets/scan_complete.png"
import GSmallLabel from "../GSmallLabel";
import GBigLabel from "../GBigLabel";
import GButton from "../GButton";
import GTransparentButton from "../GTransparentButton";
import GModal from "../GModal";
import Bullet from "../Bullet";
import GCheckBox from "../GCheckBox";
import GTextInput from "../GTextInput";
import GTestView from "../GTestView";
import GDiagnosisFullScreen from "../GDiagnosisFullScreen";
import GDiagnosisModal from "../GDiagnosisModal";
import {GlobalContext} from "../../machines/GlobalStateMachine";
import {useActor} from "@xstate/react";
import {TestContext} from "../../machines/TestMachine";
import GProgressModal from "../GProgressModal";
import {DeviceContext} from "../../machines/DeviceMachine";
import axios from "axios";
import Config from "../../services/Config";
import {BackendContext} from "../../machines/BackendMachine";
import {useTranslation} from "react-i18next";

const Test = () => {
    const {t} = useTranslation()
    useEffect(() => {
        BESend({type: "GET_CURRENT_RUNNING_TEST"})

    }, [])

    const BEContext = useContext(BackendContext)
    let BackendCurrent, BESend;
    [BackendCurrent, BESend] = useActor(BEContext);

    const [isTestFinished, setTestFinished] = useState()
    const Hidden = true;
    t("diagnosis.bacterial-vaginosis");
    t("diagnosis.cytolitic-vaginitis");
    t("diagnosis.div");
    t("diagnosis.trichomoniasis");

    const [OverlayView] = useState(false);
    const [step1Skip] = useState(JSON.parse(localStorage.getItem("gyni-step-1-skip")))
    const [ViewDiagnosisFullScreen, SetDiagnosisFullScreen] = useState(null);
    const openFullScreen = (image, diagnosis) => {
        SetDiagnosisFullScreen({
            image,
            diagnosis
        })
    }


    /////TEST IMPLEMENTATION/////

    const TContext = useContext(TestContext)
    const [TestCurrentState, UpdateTestState] = useActor(TContext);

    const DContext = useContext(DeviceContext)
    const [DeviceCurrentState, SimulateDeviceStatus] = useActor(DContext);

    const GContext = useContext(GlobalContext)
    const [GlobalCurrentState, UpdateGlobal] = useActor(GContext);

    const [TestProgress, SetTestProgress] = useState(0)
    const [Error, SetError] = useState(null)
    // const [currentSummaryTestId , setCurrentSummaryTestId] = useState("-1")
    const DebugTestsStatePicker = (step) => {
        if (step === "3") {
            UpdateTestState({type: "SET_STEP_3", data: "step3"})
        } else if (step === "5") {
            //BESend({type:"GET_TEST_BY_ID",testId:BackendCurrent.context.currentRunningTest.TestID})
        } else if (step === "6") {
            BESend({type: "RESET_REQUESTED_TEST"})
        } else if (step === "error") {
            UpdateTestState({type: "SET_ERROR"})
        } else {
            UpdateTestState({type: "SET_STEP_" + step, data: "step" + step})
        }
    }
    const finishAndRewindTest = () => {

        BESend({type: "RESET_REQUESTED_TEST"}) //GET THE CURRENT TEST
        UpdateTestState({type: "SET_STEP_1", data: "step1"}) // SET FIRST STATE
        UpdateTestState({type: "RESET_TEST_MACHINE"}) // RESET MACHINE CONTEXT
        UpdateGlobal({type: "SET_IDLE"}) //SET APP IN IDLE
        BESend({type:"GET_CLINICAL_DATA"});
    }
    const finishERRORAndRewindTest = () => {

        BESend({type: "RESET_REQUESTED_TEST"}) //GET THE CURRENT TEST
        UpdateTestState({type: "SET_ERROR"})
        UpdateTestState({type: "RESET_TEST_MACHINE"}) // RESET MACHINE CONTEXT
        UpdateGlobal({type: "SET_IDLE"}) //SET APP IN IDLE
        BESend({type:"GET_CLINICAL_DATA"});
    }
    const TestsStatePicker = (step) => {

        if (step === "3" && BackendCurrent.context.currentRunningTest && BackendCurrent.context.currentRunningTest.Resolution && BackendCurrent.context.currentRunningTest.Resolution.Status === "PENDING"
            && BackendCurrent.context.currentRunningTest.Finished_details.Status === true
        ) {
            UpdateTestState({type: "SET_STEP_4", data: "step4"})
        } else if (step === "5") {
            BESend({type: "GET_TEST_BY_ID", testId: BackendCurrent.context.currentRunningTest.TestID})
            UpdateTestState({type: "SET_STEP_5", data: "step5"})
        } else if (step === "6") {
            finishAndRewindTest();
        } else if (step === "error") {
            finishERRORAndRewindTest();
        } else {
            UpdateTestState({type: "SET_STEP_" + step, data: "step" + step})
        }
    }

    const SetTestState = (step) => {
        TestsStatePicker(step)
    }
    const SetTestMinimized = () => {
        UpdateGlobal({type: "SET_MINIMIZED"})
    }

    const SetTestDone = () => {
        BESend({type:"GET_CLINICAL_DATA"});
        SetTestState("1")
        UpdateGlobal({type: "SET_IDLE"})
    }

    const UploadTestData = () => {

        // [
        //     {"Name":"Burning","Val":false},
        //     {"Name":"Discharge","Val":true},
        //     {"Name":"Itch","Val":false},
        //     {"Name":"Fishy Odor","Val":false},
        //     {"Name":"Pain","Val":false},
        //     {"Name":"Other Malodor","Val":true}
        // ]


        let complaints = [];
        complaints.push({
            Name: TestCurrentState.context.complaints.burning.ObjName,
            Val: TestCurrentState.context.complaints.burning.Val,
        })
        complaints.push({
            Name: TestCurrentState.context.complaints.discharge.ObjName,
            Val: (TestCurrentState.context.complaints.discharge.Val),
            //Val:TestCurrentState.context.complaints.discharge.Val,
        })
        // complaints.push({
        //     Name: TestCurrentState.context.complaints.discharge.ObjName,
        //     Val: (TestCurrentState.context.complaints.dryness.Val || TestCurrentState.context.complaints.discharge.Val),
        //     //Val:TestCurrentState.context.complaints.discharge.Val,
        // })
        complaints.push({
            Name: TestCurrentState.context.complaints.itch.ObjName,
            Val: TestCurrentState.context.complaints.itch.Val,
        })
        complaints.push({
            Name: TestCurrentState.context.complaints.fishyOdor.ObjName,
            Val: TestCurrentState.context.complaints.fishyOdor.Val,
        })
        complaints.push({
            Name: TestCurrentState.context.complaints.pain.ObjName,
            Val: TestCurrentState.context.complaints.pain.Val,
        })
        complaints.push({
            Name: TestCurrentState.context.complaints.otherMalodor.ObjName,
            Val: TestCurrentState.context.complaints.otherMalodor.Val,
        })
        // complaints.push({
        //     Name: TestCurrentState.context.complaints.other.ObjName,
        //     Val: TestCurrentState.context.complaints.other.Val,
        // })
        complaints.push({
            Name: TestCurrentState.context.complaints.dryness.ObjName,
            Val: TestCurrentState.context.complaints.dryness.Val,
        })
        // complaints.push({
        //     Name:TestCurrentState.context.complaints.other.ObjName,
        //     Val:TestCurrentState.context.complaints.other.Val,
        // })
        // complaints.push({
        //     Name:TestCurrentState.context.complaints.otherText
        // })
        let body = {

            TestID: BackendCurrent.context.currentRunningTest.TestID,
            Diagnosis: [],
            Complaints: complaints,
            PhysicalExamination: "",
            Inclusions: [],
            PatientYearOfBirth: TestCurrentState.context.patientYearOfBirth,
            Ethnicity: "",
            PatientPhLevel: 1.0,
            OtherEthnicity: "",
            OtherDiagnosis: "",

        }


        if (Config.NoUploadTestData) {
            console.log(JSON.stringify(body));
        } else {
            axios.post(`${Config.globalUrl}:${Config.globalPort}/api/v1/webapp/doctors/${BackendCurrent.context.profile.userId}/TestData`, body, {headers: {'Authorization': BackendCurrent.context.authToken}})
                .then(async (response) => {
                    SetTestState("4")
                })
                .catch(async (error) => {
                    console.log(error);
                    SetError(error.response.data.error.message)
                });

        }
    }


    const CanUploadData=()=>{

        let complaintsAvailable = false;
        let otherIsSelected = false;
        for(let i = 0 ; i < Object.keys(TestCurrentState.context.complaints).length ; i++){
            let k = Object.keys(TestCurrentState.context.complaints);
            if(TestCurrentState.context.complaints[k[i]].Val === true || TestCurrentState.context.complaints[k[i]].Val === "true"){
                if(k[i] === "other" && TestCurrentState.context.complaints[k[i]].Val === true){
                    otherIsSelected = true;
                }
                complaintsAvailable = true;
            }
        }

        if(otherIsSelected){
            let otherTextLength = TestCurrentState.context.complaints.otherText.length >= 4;
            return otherTextLength && complaintsAvailable && TestCurrentState.context.patientYearOfBirthError === "" && TestCurrentState.context.patientYearOfBirth !== "";
        }else{
            return complaintsAvailable && TestCurrentState.context.patientYearOfBirthError === "" && TestCurrentState.context.patientYearOfBirth !== "";

        }
    }

    const ViewSummary = () => {
        return <div>
            <GModal handleClose={() => SetTestMinimized()} className={" lg:w-77.5rem "}>
                <div className={"mt-1.688rem lg:mt-0 lg:pr-3.125rem lg:pl-3.125rem"}>
                    {
                        BackendCurrent.context.testSummary
                            ?
                            <GTestView
                                Diagnosis={BackendCurrent.context.testSummary.AlgoResult ? BackendCurrent.context.testSummary.AlgoResult.Diagnosis ? BackendCurrent.context.testSummary.AlgoResult.Diagnosis : [] : []}
                                handleFullScreen={openFullScreen}
                                TestId={BackendCurrent.context.testSummary.TestID}
                                TestDate={BackendCurrent.context.testSummary.Resolution.DateUTC}

                                PatientYearOfBirth={BackendCurrent.context.testSummary.PatientYearOfBirth}
                                Complaints={BackendCurrent.context.testSummary.Complaints}
                                PHLevel={BackendCurrent.context.testSummary.AlgoResult ? BackendCurrent.context.testSummary.AlgoResult.PH : 0}
                            />
                            :
                            <div>Loading ... </div>
                    }


                    <div className={"space-x-2.063rem flex justify-content-end mr-0.75rem lg:mb-2.62rem"}>
                        <GButton onClick={() => SetTestState("6")}
                                 className={" lg:flex hidden w-full lg:w-7.688rem h-3.125rem"}>
                            {t("scan.step-2.buttons.done")}
                        </GButton>
                    </div>
                </div>

            </GModal>
        </div>
    }

    useEffect(()=>{
        // if(BackendCurrent.context.testSummary && BackendCurrent.context.testSummary.TestID && BackendCurrent.context.testSummary.TestID !== currentSummaryTestId) {
        //     setCurrentSummaryTestId(BackendCurrent.context.testSummary.TestID)
        //     setTimeout(()=> {
                setTestFinished(
                    ViewSummary()
                )
        //     },1000);
        // }
    },[BackendCurrent.context.testSummary])

    const ViewTestResults = (SIMULATION = null) => {
        // case 46 scan failed
        // case 47 algo failed
        // case 48 scan completed

        if (BackendCurrent.context.testSummary) {
            if (!isTestFinished) {
                // if(BackendCurrent.context.testSummary && BackendCurrent.context.testSummary.TestID && BackendCurrent.context.testSummary.TestID !== currentSummaryTestId) {
                //     setCurrentSummaryTestId(BackendCurrent.context.testSummary.TestID)
                //     setTimeout(()=>{
                        setTestFinished(
                            ViewSummary()
                        )
                    // },1000)

                // }
            }
        } else {
            switch (SIMULATION ? SIMULATION : DeviceCurrentState.context.statusCode) {
                // case 45: //SUMMARY
                //     if (!isTestFinished)
                //         setTestFinished(
                //             ViewSummary()
                //         )
                //     break;
                case 46: //UNKNOWN - NOT SENT FROM DEVICE
                    if (!isTestFinished)
                        setTestFinished(<GModal handleClose={() => SetTestMinimized()} className={"rounded-3xl"}
                                                closeButtonStyle={"mr-auto ml-auto mb-1.688rem w-6.625rem "}>
                            <div className={"mt-1.797rem mr-2.937rem ml-2.937rem"}>
                                {/*IMAGE*/}
                                <div className={"flex justify-content-center mb-1.625rem"}>
                                    <img className={"w-8.813rem h-9.125rem"} src={DeviceIssueIcon}
                                         alt={"scan complete"}/>
                                </div>
                                {/*TITLE*/}
                                <div
                                    className={"flex text-center whitespace-nowrap  justify-content-center mb-0.938rem"}>
                                    <GBigLabel className={"text-1.375rem"}>
                                        <GSmallLabel>{t("scanner.scan-failed")}</GSmallLabel>
                                    </GBigLabel>
                                </div>
                                {/*SUB MESSAGE*/}
                                <div className={"text-center leading-1.291rem mb-1.563rem"}>
                                    <GSmallLabel>
                                        {t("scan.complete.p1")}
                                    </GSmallLabel>
                                </div>
                                <div className={"h-3.125rem"}>
                                    <GButton onClick={() => SetTestDone()}
                                             className={"m-auto max-w-10.438rem h-3.125rem"}>
                                        OK
                                    </GButton>
                                </div>
                            </div>
                        </GModal>)
                    break;
                case 47: //ALGORITHM FAILED
                    if (!isTestFinished)
                        setTestFinished(<GModal handleClose={() => SetTestMinimized()} className={"rounded-3xl"}
                                                closeButtonStyle={"mr-auto ml-auto mb-1.688rem w-6.625rem "}>
                            <div className={"mt-1.797rem mr-2.937rem ml-2.937rem"}>
                                {/*IMAGE*/}
                                <div className={"flex justify-content-center mb-1.625rem"}>
                                    <img className={"w-8.813rem h-9.125rem"} src={DeviceIssueIcon}
                                         alt={"scan complete"}/>
                                </div>
                                {/*TITLE*/}
                                <div
                                    className={"flex text-center whitespace-nowrap  justify-content-center mb-0.938rem"}>
                                    <GBigLabel className={"text-1.375rem"}>
                                        {/*{*/}
                                        {/*    DeviceCurrentState.context.statusCode === 46*/}
                                        {/*        ?*/}
                                        {/*        <GSmallLabel>{t("scanner.scan-failed")}</GSmallLabel>*/}
                                        {/*        :*/}
                                        <GSmallLabel>{t("scanner.algorithm-failed")}</GSmallLabel>
                                        {/*}*/}
                                    </GBigLabel>
                                </div>
                                {/*SUB MESSAGE*/}
                                <div className={"text-center leading-1.291rem mb-1.563rem"}>
                                    <GSmallLabel>
                                        {t("scan.complete.p1")}
                                    </GSmallLabel>
                                </div>
                                <div className={"h-3.125rem"}>
                                    <GButton onClick={() => SetTestDone()}
                                             className={"m-auto max-w-10.438rem h-3.125rem"}>
                                        OK
                                    </GButton>
                                </div>
                            </div>
                        </GModal>)
                    break;
                case 48: //SCAN FAILED
                    if (!isTestFinished)
                        setTestFinished(
                            <GModal handleClose={() => SetTestMinimized()} className={"rounded-3xl"}
                                                closeButtonStyle={"mr-auto ml-auto mb-1.688rem w-6.625rem "}>
                            <div className={"mt-1.797rem mr-2.937rem ml-2.937rem"}>
                                {/*IMAGE*/}
                                <div className={"flex justify-content-center mb-1.625rem"}>
                                    <img className={"w-8.813rem h-9.125rem"} src={DeviceIssueIcon}
                                         alt={"scan complete"}/>
                                </div>
                                {/*TITLE*/}
                                <div
                                    className={"flex text-center whitespace-nowrap  justify-content-center mb-0.938rem"}>
                                    <GBigLabel className={"text-1.375rem"}>
                                        {/*{*/}
                                        {/*    DeviceCurrentState.context.statusCode === 46*/}
                                        {/*        ?*/}
                                        <GSmallLabel>{t("scanner.scan-failed")}</GSmallLabel>
                                        {/*//         :*/}
                                        {/*// <GSmallLabel>{t("scanner.algorithm-failed")}</GSmallLabel>*/}
                                        {/*}*/}
                                    </GBigLabel>
                                </div>
                                {/*SUB MESSAGE*/}
                                <div className={"text-center leading-1.291rem mb-1.563rem"}>
                                    <GSmallLabel>
                                        {t("scan.complete.p1")}
                                    </GSmallLabel>
                                </div>
                                <div className={"h-3.125rem"}>
                                    <GButton onClick={() => SetTestDone()}
                                             className={"m-auto max-w-10.438rem h-3.125rem"}>
                                        OK
                                    </GButton>
                                </div>
                            </div>
                        </GModal>)
                    break;
            }
        }
        return (isTestFinished)
    }
    const ViewCurrentTestState = (SIMULATION = null) => {


        switch (SIMULATION ? SIMULATION : TestCurrentState.context.currentStep) {
            case "step1":
                return <GModal handleClose={() => SetTestMinimized()} className={"w-48.125rem"}>
                    <div className={"sm:pr-3.125rem sm:pl-3.125rem"}>
                        {/*TITLE*/}
                        <div className={"mb-3.375rem text-center flex  justify-content-center "}>
                            <GBigLabel className={"text-1.375rem sm:text-2rem"}>
                                {t("scan.step-1-title")}
                            </GBigLabel>
                        </div>
                        {/*TEXT*/}
                        <div className={"mb-2.188rem text-left leading-1.291rem  space-y-0.625rem"}>
                            <div className={"flex"}>
                                <Bullet/>
                                <GSmallLabel>
                                    {t("scan.step-1-p1")}
                                </GSmallLabel>
                            </div>
                            <div className={"flex"}>
                                <Bullet/>
                                <GSmallLabel>
                                    {t("scan.step-1-p2")}
                                </GSmallLabel>
                            </div>
                            <div className={"flex"}>
                                <Bullet/>
                                <GSmallLabel>
                                    {t("scan.step-1-p3")}
                                </GSmallLabel>
                            </div>
                        </div>
                        {/*CHECKBOX*/}
                        <div className={"sm:mb-3.563rem"}>
                            <GCheckBox onClick={(e) => {
                                localStorage.setItem("gyni-step-1-skip", e)
                            }} checked={step1Skip} text={t("scan.step-1-question")}/>
                        </div>
                        {/*BUTTON*/}
                        <div className={"mb-2.937rem pl-2.625rem pr-2.625rem"}>
                            <GButton onClick={() => {

                                if (BackendCurrent.context.currentRunningTest.TestID) {
                                    SetTestState("2")
                                } else {
                                    SetTestState("3")
                                }

                            }} className={"m-auto max-w-16.438rem h-3.125rem"}>
                                {t("scan.step-1.buttons.i-understand")}
                            </GButton>
                        </div>
                    </div
                    >
                </GModal>
            case "step2":
                return <GModal handleClose={() => SetTestMinimized()}>
                    <div className={"pr-8 pl-8"}>
                        {/*IMAGE*/}
                        <div className={"flex justify-content-center mb-1.625rem"}>
                            <img className={"w-8.813rem h-9.125rem"} src={DeviceIssueIcon} alt={"device issue"}/>
                        </div>
                        {/*TITLE*/}
                        <div className={"flex text-center whitespace-nowrap  justify-content-center mb-0.938rem"}>
                            <GBigLabel className={"text-1.375rem"}>
                                {t("scan.found-test.title")}
                            </GBigLabel>
                        </div>
                        {/*SUB MESSAGE*/}
                        <div className={"text-center leading-1.291rem mb-1.563rem"}>
                            <GSmallLabel>
                                {t("scan.found-test.p1")}
                            </GSmallLabel>
                            {/*<GSmallLabel>*/}
                            {/*    scanner wich was inserted at*/}
                            {/*</GSmallLabel>*/}
                            <GSmallLabel className={"mb-2.5 flex justify-content-center space-x-2"}>
                                <GSmallLabel>
                                    {
                                        (new Date(BackendCurrent.context.currentRunningTest?.Start_scan?.DateLocal)).getHours() < 10
                                            ?
                                            '0' + (new Date(BackendCurrent.context.currentRunningTest?.Start_scan?.DateLocal)).getHours()
                                            :
                                            (new Date(BackendCurrent.context.currentRunningTest?.Start_scan?.DateLocal)).getHours()
                                    }
                                    :
                                    {
                                        (new Date(BackendCurrent.context.currentRunningTest?.Start_scan?.DateLocal)).getMinutes() < 10
                                            ?
                                            '0' + (new Date(BackendCurrent.context.currentRunningTest?.Start_scan?.DateLocal)).getMinutes()
                                            :
                                            (new Date(BackendCurrent.context.currentRunningTest?.Start_scan?.DateLocal)).getMinutes()
                                    }
                                </GSmallLabel>
                                <GSmallLabel>
                                    {t("scan.found-test.today")}
                                </GSmallLabel>

                            </GSmallLabel>
                            <GSmallLabel>
                                {t("scan.found-test.p2")}
                            </GSmallLabel>
                        </div>
                        <div className={"pl-2.625rem pr-2.625rem"}>
                            <GButton onClick={() => SetTestState("3")} className={"h-3.125rem"}>
                                {t("scan.found-test.buttons.yes")}
                            </GButton>
                            <GTransparentButton onClick={() => SetTestDone()} className={"h-3.125rem"}>
                                {t("scan.found-test.buttons.no")}
                            </GTransparentButton>
                        </div>
                    </div>
                </GModal>
            case "step3":
                return <GModal handleClose={() => SetTestMinimized()} className={"w-48.125rem"}>
                    <div className={"lg:pr-3.125rem lg:pl-3.125rem"}>
                        {/*TITLE*/}
                        <div className={"mb-3.375rem text-center flex  justify-content-center "}>
                            <GBigLabel className={"text-2rem font-1.125rem"}>
                                {t("scan.step-2.title")}
                            </GBigLabel>
                        </div>
                        <div>
                            <GSmallLabel bold className={"text-1.125rem mb-1.875rem"}>
                                {t("general.patient-complaints")}
                            </GSmallLabel>
                            <div className={"mb-3.75rem space-y-1.25rem sm:space-y-0 sm:flex sm:space-x-1.25rem "}>
                                <div className={"sm:w-1/2 space-y-1.25rem"}>
                                    <GCheckBox checked={TestCurrentState.context.complaints.discharge.Val}
                                               onClick={(isChecked) => UpdateTestState({
                                                   type: "INPUT_DISCHARGE",
                                                   value: isChecked
                                               })} className={"shadow-checkboxShadow h-3.5rem p-1.125rem pt-3"}
                                               borderOnCheck text={t("complaints.discharge")}/>
                                    <GCheckBox checked={TestCurrentState.context.complaints.itch.Val}
                                               onClick={(isChecked) => UpdateTestState({
                                                   type: "INPUT_ITCH",
                                                   value: isChecked
                                               })} className={"shadow-checkboxShadow h-3.5rem p-1.125rem pt-3"}
                                               borderOnCheck text={t("complaints.itch")}/>
                                    <GCheckBox checked={TestCurrentState.context.complaints.fishyOdor.Val}
                                               onClick={(isChecked) => UpdateTestState({
                                                   type: "INPUT_FISHY_ODOR",
                                                   value: isChecked
                                               })} className={"shadow-checkboxShadow h-3.5rem p-1.125rem pt-3"}
                                               borderOnCheck text={t("complaints.fishy-odor")}/>
                                    <GCheckBox checked={TestCurrentState.context.complaints.dryness.Val}
                                               onClick={(isChecked) => UpdateTestState({
                                                   type: "INPUT_DRYNESS",
                                                   value: isChecked
                                               })} className={"shadow-checkboxShadow h-3.5rem p-1.125rem pt-3"}
                                               borderOnCheck text={t("complaints.dryness")}/>





                                </div>
                                <div className={"sm:w-1/2 space-y-1.25rem"}>
                                    <GCheckBox checked={TestCurrentState.context.complaints.burning.Val}
                                               onClick={(isChecked) => UpdateTestState({
                                                   type: "INPUT_BURNING",
                                                   value: isChecked
                                               })} className={"shadow-checkboxShadow h-3.5rem p-1.125rem pt-3"}
                                               borderOnCheck text={t("complaints.burning")}/>
                                    <GCheckBox checked={TestCurrentState.context.complaints.pain.Val}
                                               onClick={(isChecked) => UpdateTestState({
                                                   type: "INPUT_PAIN",
                                                   value: isChecked
                                               })} className={"shadow-checkboxShadow h-3.5rem p-1.125rem pt-3"}
                                               borderOnCheck text={t("complaints.pain")}/>
                                    <GCheckBox checked={TestCurrentState.context.complaints.otherMalodor.Val}
                                               onClick={(isChecked) => UpdateTestState({
                                                   type: "INPUT_OTHER_MALODOR",
                                                   value: isChecked
                                               })} className={"shadow-checkboxShadow h-3.5rem p-1.125rem pt-3"}
                                               borderOnCheck text={t("complaints.other-malodor")}/>
                                    <GCheckBox checked={TestCurrentState.context.complaints.other.Val}
                                               onClick={(isChecked) => UpdateTestState({
                                                   type: "INPUT_OTHER",
                                                   value: isChecked
                                               })} className={"shadow-checkboxShadow h-8.25rem p-1.125rem pt-3"}
                                               borderOnCheck text={t("complaints.other")}>
                                        <GTextInput max={50} value={TestCurrentState.context.complaints.otherText}
                                                    onChange={(e) => UpdateTestState({
                                                        type: "INPUT_OTHER_TEXT",
                                                        value: e.target.value
                                                    })} borderStyle={" rounded-2xl border border-#1396B4 border-1px "}
                                                    className={"h-3.125rem"}></GTextInput>
                                    </GCheckBox>
                                </div>
                            </div>
                        </div>
                        <div className={"mb-3.125rem "}>
                            <GSmallLabel bold className={"text-1.125rem mb-1.75rem"}>
                                {t("test.patient-yob")}
                            </GSmallLabel>
                            <GTextInput
                                max={50}
                                validation
                                placeHolder={"YYYY"}
                                error={TestCurrentState.context.patientYearOfBirthError}
                                value={TestCurrentState.context.patientYearOfBirth}
                                onChange={(e) => UpdateTestState({type: "INPUT_YOB", value: e.target.value})}
                                className={" sm:max-w-8.375rem h-3.5rem shadow-checkboxShadow"}/>
                        </div>
                        <div className={"mb-2.937rem pl-2.625rem pr-2.625rem"}>
                            {
                                CanUploadData()
                                ?
                                    <GButton
                                        onClick={() => UploadTestData()}
                                        // onClick={()=>SetTestState("4")}
                                        className={"m-auto max-w-10.438rem h-3.125rem"}>
                                        {t("scan.step-2.buttons.done")}
                                    </GButton>
                                    :
                                    <GButton
                                        disabled={true}
                                        onClick={() => UploadTestData()}
                                        // onClick={()=>SetTestState("4")}
                                        className={"m-auto max-w-10.438rem h-3.125rem"}>
                                        {t("scan.step-2.buttons.done")}
                                    </GButton>
                            }

                        </div>
                    </div>
                </GModal>
            case "step4":

                switch (DeviceCurrentState.context.statusCode) {
                    case 45:
                    case 46:
                    case 47:
                    case 48:
                        SetTestState("5")
                        break;
                }

                return <GProgressModal text={t("scan.step-3.title")} value={DeviceCurrentState.context.processValue}
                                       className={"p-8 sm:p-0 w-41.625rem"}/>
            case "step5":
                return ViewTestResults()
            case "step6":
                return <GModal handleClose={() => SetTestMinimized()} className={"rounded-3xl"}
                               closeButtonStyle={"mr-auto ml-auto mb-1.688rem w-6.625rem "}>
                    <div className={"mt-1.797rem mr-2.937rem ml-2.937rem"}>
                        {/*IMAGE*/}
                        <div className={"flex justify-content-center mb-1.625rem"}>
                            <img className={"w-8.813rem h-9.125rem"} src={ScanCompleteIcon} alt={"scan complete"}/>
                        </div>
                        {/*TITLE*/}
                        <div className={"flex text-center whitespace-nowrap  justify-content-center mb-0.938rem"}>
                            <GBigLabel className={"text-1.375rem"}>
                                {t("scanner.scan-completed")}
                            </GBigLabel>
                        </div>
                        {/*SUB MESSAGE*/}
                        <div className={"text-center leading-1.291rem mb-1.563rem"}>
                            <GSmallLabel>
                                {t("scan.complete.p1")}
                            </GSmallLabel>
                        </div>
                        <div className={"h-3.125rem"}>
                            <GButton onClick={() => SetTestDone()} className={"m-auto max-w-10.438rem h-3.125rem"}>
                                OK
                            </GButton>
                        </div>
                    </div>
                </GModal>
            case "error":
                return <div>ERROR</div>
                break;
        }
    }

    return (
        <div>


            {
                !Hidden ?


                    <div>
                        {/*//CONNECT SCANNER*/}

                        {/*RESPONSIVE *MODAL WINDOW | REGISTER PART 1 | TUTORIAL VIDEO*/}
                        {
                            ViewSummary()
                        }

                    </div>
                    :

                    Error
                        ?
                        <GModal handleClose={() => SetError(null)} className={"rounded-3xl"}
                                closeButtonStyle={"mr-auto ml-auto mb-1.688rem w-6.625rem "}>
                            <div className={"mt-1.797rem mr-2.937rem ml-2.937rem"}>
                                {/*IMAGE*/}
                                <div className={"flex justify-content-center mb-1.625rem"}>
                                    <img className={"w-8.813rem h-9.125rem"} src={DeviceIssueIcon}
                                         alt={"scan complete"}/>
                                </div>
                                {/*TITLE*/}
                                <div
                                    className={"flex text-center whitespace-nowrap  justify-content-center mb-0.938rem"}>
                                    <GBigLabel className={"text-1.375rem"}>
                                        {t("error")}
                                    </GBigLabel>
                                </div>
                                {/*SUB MESSAGE*/}
                                <div className={"text-center leading-1.291rem mb-1.563rem"}>
                                    <GSmallLabel>
                                        {
                                            Error
                                        }
                                    </GSmallLabel>
                                </div>
                                <div className={"h-3.125rem"}>
                                    <GButton onClick={() => {
                                        SetError(null)
                                        SetTestDone()
                                    }} className={"m-auto max-w-10.438rem h-3.125rem"}>
                                        OK
                                    </GButton>
                                </div>
                            </div>
                        </GModal>
                        :
                        <>
                            {
                                ViewDiagnosisFullScreen
                                    ?
                                    <GDiagnosisModal handleClose={() => SetDiagnosisFullScreen(false)}>
                                        <GDiagnosisFullScreen
                                            className={"sm:w-31.25rem "}
                                            overlay={OverlayView}
                                            diagnosis={ViewDiagnosisFullScreen}
                                            diagnosisImg={ViewDiagnosisFullScreen.inputImageUrl}
                                            diagnosisOverlayImg={ViewDiagnosisFullScreen.outputImageUrl}
                                        />
                                    </GDiagnosisModal>
                                    :
                                    ViewCurrentTestState()
                            }
                        </>


            }
        </div>
    )
};
export default Test;

