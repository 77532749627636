import React, {useContext, useEffect, useRef, useState} from 'react'
import {BackendContext} from "../../machines/BackendMachine";
import {useActor, useMachine} from "@xstate/react";
import {LoginContext, LoginMachine} from "../../machines/LoginMachine";
import logo from "../../assets/logo_svg.svg"
import GBigLabel from "../GBigLabel";
import GSmallLabel from "../GSmallLabel";
import GTextInput from "../GTextInput";
import GButton from "../GButton";

// import Reaptcha from "reaptcha";



import {useTranslation, withTranslation} from "react-i18next";
import ReCaptcha from "../../services/ReCaptcha";
import {DataBrew} from "aws-sdk";
import Config from "../../services/Config";

const SignIn=()=>{
    const { t, i18n } = useTranslation()

    const [FailedLoginCount , SetFailedLoginCount ] = useState(3)
    const [code2fa,setCode2fa] = useState("")
    const BEContext = useContext(BackendContext)
    let BackendCurrent, BESend;
    [BackendCurrent, BESend] = useActor(BEContext);

    const LContext = useContext(LoginContext)
    let lc, LoginSend;
    [lc, LoginSend] = useActor(LContext);

    const recaptchaRef = useRef(null)

    const RequireRecaptcha = async ()=>{
        console.log("Will Check Captcha")
        const captchaToken = await recaptchaRef.current.executeAsync();
        recaptchaRef.current.reset();

        let reToken = Config.ReCaptchaKey;

        BESend({ type : "REQUEST_LOGIN" , login:lc.context.login,password:lc.context.password,reCaptchaToken:reToken})
    }
    const handleSubmit =  () => {

        if(BackendCurrent.context.await2fa){
            BESend({ type : "CONFIRM_2FA" , code:code2fa})
        }else{
            // if(BackendCurrent.context.failedAttempts >= Config.FailedLoginCount){
            //     RequireRecaptcha();
            // }else{
               BESend({ type : "REQUEST_LOGIN" , login:lc.context.login,password:lc.context.password,reCaptchaToken:null})
        //    }
        }
    }
    const handleKeyPress=(e)=>{
        if(e.key === "Enter"){
            handleSubmit();
        }
    }

    return (
        //

        <div className={"pt-6.994rem "}>

            <img className={"h-3.938rem m-auto"} src={logo} alt={"logo"}/>
            <GBigLabel className={"mt-3.813rem leading-1.914rem text-2rem w-full flex justify-center"}> {t('login.sign-in')}</GBigLabel>
            <div className={"mt-2 flex justify-center space-x-0.313rem"}>
                <GSmallLabel className={"leading-1.076rem"}>{t('login.forgot-password')}</GSmallLabel>
                <GSmallLabel color={"text-#50C2BC"} bold className={"leading-1.076rem cursor-pointer  "} onClick={()=>LoginSend({type:"NAV_TO_FORGOT_PASSWORD"})} >{t('login.reset')}</GSmallLabel>
            </div>
            <div className={"mt-2 flex justify-center space-x-0.313rem"}>
                <GSmallLabel className={"leading-1.076rem"}>{t('login.dont-have-an-account')}</GSmallLabel>
                <GSmallLabel color={"text-#50C2BC"} bold className={"leading-1.076rem cursor-pointer "} onClick={()=>LoginSend({type:"NAV_TO_CREATE_USER"})} >{t('login.sign-up')}</GSmallLabel>
            </div>

            <div className={"mt-3.438rem w-21.875rem ml-auto mr-auto space-y-1.875rem relative"}>
                {
                    BackendCurrent.context.await2fa
                        ?
                        <GTextInput
                            max={50}
                            onKeyPress={(e) => handleKeyPress(e)}
                            iconPosition={"top-5"}
                            // validation
                            // error={lc.context.invalid_password}
                            // value={lc.context.password}
                            onChange={(e) => {
                                setCode2fa(e.target.value)
                            }}
                            className={"bg-gyni-textBox h-3.5rem rounded-lg"} />
                        :
                        <>
                            <GTextInput
                                max={50}
                                onKeyPress={(e) => handleKeyPress(e)}
                                validation
                                error={lc.context.invalid_login}
                                value={lc.context.login}
                                onChange={(e) => {
                                    LoginSend({type: "INPUT_LOGIN", login: e.target.value})
                                }}
                                className={"bg-gyni-textBox h-3.5rem rounded-lg "}
                                placeHolder={t('login.placeholder.login')}/>
                            <GTextInput
                                max={50}
                                onKeyPress={(e) => handleKeyPress(e)}
                                iconPosition={"top-5"}
                                validation
                                error={lc.context.invalid_password}
                                value={lc.context.password}
                                onChange={(e) => {
                                    LoginSend({type: "INPUT_PASSWORD", password: e.target.value})
                                }}
                                className={"bg-gyni-textBox h-3.5rem rounded-lg"} password
                                placeHolder={t('login.placeholder.password')}/>
                        </>
                }


                {
                    BackendCurrent.context.errorsList.length > 0
                        ?
                        <div className={"absolute w-full text-center text-red-500"}>{t('login.login-failed')}</div>
                        :
                        <div></div>
                }
            </div>
                 <GButton
                     onKeyPress={(e)=>handleKeyPress(e)}
                     onClick={()=>handleSubmit()}
                     className={"mt-4.563rem h-10 w-21.875rem ml-auto mr-auto"}>

                         {
                             !BackendCurrent.context.await2fa
                             ?
                                    t('login.sign-in')
                            :
                                 t('login.send-code')
                         }


                 </GButton>


            <div className={'bg-re'}>
                <ReCaptcha
                    setRef={recaptchaRef}
                    action={(token)=>{
                        console.log(token)
                    }}
                />
            </div>

        </div>
    )
}
export default  withTranslation()(SignIn);
