import React, {useContext} from 'react';
import GSmallLabel from "./GSmallLabel";
import GLeftNumberBox from "./GLeftNumberBox";
import GBigLabel from "./GBigLabel";
import {useTranslation} from "react-i18next";
import {GlobalContext} from "../machines/GlobalStateMachine";
import {useActor} from "@xstate/react";

const GHowToConnectScannerStep1 = (props) => {
    const { t, i18n } = useTranslation()
    const GContext = useContext(GlobalContext)
    const [GlobalCurrentState, UpdateGlobal] = useActor(GContext);
    const viewTutorialVideo=()=>{
        UpdateGlobal({type:"SET_TUTORIAL_VIDEO"});
    }


    return (
      <div className={""}>
          <GBigLabel className={"mt-8 text-center lg:mt-1.5rem text-1.375rem sm:text-2rem "}>
              {t("connect.title")}
          </GBigLabel>
          <GBigLabel className={"text-center text-0.875rem sm:text-1.25rem "}>
              {t("connect.step-1-title")}
          </GBigLabel>
          <div className={"g-step-body  mb-8 mt-8 lg:mb-2.438rem lg:mt-4rem "}>
              <div className={"hidden sm:block mb-2 bg-#1396B405 border-#1396B4 border rounded-md w-full lg:p-0.938rem"}>

                  <GSmallLabel color={"text-#1396B4"} className={"text-0.75rem"}>{t("connect.step-1-note")}</GSmallLabel>
                  <GSmallLabel onClick={()=>viewTutorialVideo()} color={"text-#1396B4"} className={" cursor-pointer hover:underline text-0.813rem"}>{t("general.click-here")}</GSmallLabel>
                  {/*<GSmallLabel color={"text-#1396B4"} className={"text-0.813rem"}>are using your computer, make sure it is not connected to a network cable)</GSmallLabel>*/}
                  {/*<GSmallLabel color={"text-#1396B4"} className={"text-0.813rem"}>For connection video tutorial click here</GSmallLabel>*/}
              </div>
              <GLeftNumberBox number={1}>
                  {t( "connect.step-1-p2")}
              </GLeftNumberBox>
              <GLeftNumberBox number={2}>
                  {t("connect.step-1-p1")}
              </GLeftNumberBox>
              <GLeftNumberBox number={3}>
                  {t("connect.step-1-p3")}
              </GLeftNumberBox>
              <GLeftNumberBox nodash number={4}>
                  {t("connect.step-1-p4")}
                  <div className={"text-#1396B4 underline cursor-pointer"}>{t("connect.step-1-url")}</div>
              </GLeftNumberBox>
          </div>

      </div>
    )
};
export default GHowToConnectScannerStep1;
