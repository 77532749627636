import React, {useContext, useEffect, useState} from 'react';
import GSmallLabel from "./GSmallLabel";
import GLeftNumberBox from "./GLeftNumberBox";
import GBigLabel from "./GBigLabel";
import snlabel from "../assets/sn-label.svg"
import GPartlyTextInput from "./GPartlyTextInput";
import axios from "axios";
import Config from "../services/Config";
import {GlobalContext} from "../machines/GlobalStateMachine";
import {useActor} from "@xstate/react";
import {useTranslation} from "react-i18next";

const GHowToConnectScannerStep5 = (props) => {
    const { t, i18n } = useTranslation()
    const GContext = useContext(GlobalContext)
    const [GlobalCurrentState, UpdateGlobal] = useActor(GContext);




    return (
      <div>
          <GBigLabel className={"mt-8 text-center lg:mt-1.5rem text-1.375rem sm:text-2rem "}>
              {t("connect.title")}
          </GBigLabel>
          <GBigLabel className={"text-center text-0.875rem sm:text-1.25rem "}>
              {t("connect.step-5-title")}
          </GBigLabel>
          <div className={" g-step-body "}>
              <div className={"pb-1.75rem"}>
                  <img className={"m-auto "} src={snlabel} alt={"SN-LABEL"}/>
              </div>
              <div className={"  mb-8 lg:mb-2.438rem "}>
                  <GPartlyTextInput handleChange={(e)=>UpdateGlobal({type:"SET_NEW_DEVICE_SERIAL_NUMBER",NewDeviceSerialNumber:e})} textValue={GlobalCurrentState.context.NewDeviceSerialNumber}/>
              </div>
          </div>


      </div>
    )
};
export default GHowToConnectScannerStep5;
