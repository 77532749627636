import React, {useContext, useEffect, useState} from 'react';
import GSmallLabel from "./GSmallLabel";
import magnifier from "../assets/magnifier.svg"
import http from "http";
import url from "url";
import JSZip from "jszip";
import axios from "axios";
import Config from "../services/Config";
import {BackendContext} from "../machines/BackendMachine";
import {useActor} from "@xstate/react";
import GResponsiveTest from "./GResponsiveTest";
import {useTranslation} from "react-i18next";

const GDiagnosis = (props) => {
    const BEContext = useContext(BackendContext)
    let BackendCurrent, BESend;
    [BackendCurrent, BESend] = useActor(BEContext);
    const [showNoImages,setShowNoImages] = useState(false);
    const { t, i18n } = useTranslation()
    const [algoImages,setAlgoImages] = useState({})
    useEffect(()=>{
        if(props.diagnosis.Name !== "Other")
            axios.get(`${Config.globalUrl}:${Config.globalPort}/api/v1/webapp/doctors/${BackendCurrent.context.profile.userId}/TestImages/?TestID=${props.testId}`,{headers: {'Authorization': BackendCurrent.context.authToken}})
                .then(async (response) => {
                    console.log(response)
                    if(Config.getImagesFromZip){
                        openZipFiles(response.data.inputFiles,response.data.outputFiles)
                    }else{
                        getImagesFromOutput(response.data.outputFiles)
                    }

                })
                .catch( async (error) => {
                    console.log(error);
                });
    },[])

    const getImageUrlByDiagnosis=(diagnosisImage)=>{

        const content = new Uint8Array(diagnosisImage);
        let blob = URL.createObjectURL(
            new Blob([content.buffer], { type: 'image/png' } /* (1) */)
        );
        return blob
    }

    const getDiagnosisFullName=(name)=>{
        switch (name){
            case "BV":
                return "Bacterial Vaginosis"
            case "CAS" :
            case "CAH" :
                return "Candida Albicans"
            case "CNA" :
                return "Candida Non-Albicans"
            case "AV"  :
                return "Vaginal Atrophy"
            case "TV"  :
                return "Trichomonas"
            case "DIV"  :
                return "DIV"
            case "CV"  :
                return "Cytolytic Vaginosis"
            default :
                return "Unknown"
        }

    }
    const getImagesFromOutput=(outputs)=>{
        console.log("GET_IMAGES_FROM_OUTPUT")

        let RESPONSE = [];

        var _algoImages = [];
        var _inputImages = [];

        outputs.forEach(output => {
            if(Object.keys(output)[0].indexOf("orig")>-1){
                _inputImages.push({
                    fileName : Object.keys(output)[0],
                    url:output[Object.keys(output)[0]]
                })
            }else{
                _algoImages.push({
                    fileName : Object.keys(output)[0],
                    url:output[Object.keys(output)[0]]
                })
            }

        })

        _algoImages.forEach(image=>{
            let outputImageName = image.fileName; //BV_O38.jpg.jpg
            let outputImageUrl = image.url

            let inputFile = _inputImages.filter(x=>x.fileName.indexOf(outputImageName.split(".")[0]) > -1)[0];
            let inputFileName = inputFile && inputFile.fileName;
            let inputFileUrl = inputFile && inputFile.url;

            let diagnosisName = outputImageName.replace("KOH","").split("_")[0]; //BV

            if(props.diagnosis.Name === getDiagnosisFullName(diagnosisName)){
                setAlgoImages(
                    {
                        outputImageName:outputImageName,
                        diagnosisName:diagnosisName,
                        diagnosisFullName:getDiagnosisFullName(diagnosisName) === 'Trichomonas'?"Trichomonas Vaginalis":getDiagnosisFullName(diagnosisName),
                        inputImageName:inputFileName,
                        inputImageUrl:inputFileUrl,
                        outputImageUrl:outputImageUrl
                    }
                )
            }
        })
    }
    const openZipFiles=(zips,outputs)=> {

        let RESPONSE = [];

        var _algoImages = [];

        outputs.forEach(output => {
            _algoImages.push({
                output: output,
                input: {
                    name: null,
                    data: []
                }
            })
        })


        zips.forEach(zipUrl => {

            // let k = Object.keys(zipUrl)[0];
            // if(k === 'sal'){
            let key = Object.keys(zipUrl);
            let url = zipUrl[key];
            let req = http.get(url, (res) => {
                if (res.statusCode !== 200) {
                    console.log(res.statusCode);
                    return;
                }
                var data = [], dataLen = 0;

                // don't set the encoding, it will break everything !
                // or, if you must, set it to null. In that case the chunk will be a string.

                res.on("data", (chunk) => {
                    data.push(chunk);
                    dataLen += chunk.length;
                });

                res.on("end", () => {
                    var buf = Buffer.concat(data);
                    // here we go !



                    JSZip.loadAsync(buf).then((zip) => {

                        //BV_038.jpg.jpg
                        _algoImages.forEach(image=>{
                            let outputImageName = Object.keys(image.output)[0]; //BV_O38.jpg.jpg
                            let outputImageUrl = image.output[Object.keys(image.output)[0]];
                            let diagnosisName = Object.keys(image.output)[0].replace("KOH","").split("_")[0]; //BV
                            let inputImageName = Object.keys(image.output)[0].replace("KOH","").split("_")[1].slice(0,-4); //038.jpg
                            let inputImageData = null;
                            Object.keys(zip.files).forEach(file=>{
                                if(zip.files[file].name === inputImageName){
                                    zip.file(file).async("uint8array").then((u8) => {
                                        if(props.diagnosis.Name === getDiagnosisFullName(diagnosisName)){
                                            setAlgoImages(
                                                {
                                                    outputImageName:outputImageName,
                                                    diagnosisName:diagnosisName,
                                                    diagnosisFullName:getDiagnosisFullName(diagnosisName) === 'Trichomonas'?"Trichomonas Vaginalis":getDiagnosisFullName(diagnosisName),
                                                    inputImageName:inputImageName,
                                                    inputImageUrl:getImageUrlByDiagnosis(u8),
                                                    outputImageUrl:image.output[Object.keys(image.output)[0]]
                                                }
                                            )
                                        }
                                    })
                                }
                            })
                        })
                    }).then((text) => {
                        console.log(text);
                    });
                });
            });

            req.on("error", (err) => {
                // handle error
            });
        })
    }

    useEffect(()=>{
        //setShowNoImages(false)
        setTimeout(()=>{
            if(!algoImages.inputImageUrl){
                setShowNoImages(true)
            }
        },2000)
    },[])
    const getDiagnosisName=(name)=>{
        switch (name){
            case "Trichomoniasis":
                return i18n.t("diagnosis.trichomoniasis")
            case "Candida Albicans":
                return i18n.t("diagnosis.candida-albicans")
            case "Candida Non-Albicans":
                return i18n.t("diagnosis.candida-non-albicans")
            case "Vaginal Atrophy":
                return i18n.t("diagnosis.vaginal-atrophy")
            case "Normal Vaginal Secretions":
                return i18n.t("diagnosis.normal-vaginal-secretions")
            case "Other":
                return i18n.t("diagnosis.other")
            default:
                return name;
        }
    }
    return (
        <div className={props.className +"  "}>

            {/*<GSmallLabel>{t("general.gyni-diagnosis")} {props.index}</GSmallLabel>*/}
            <GSmallLabel>{t("general.gyni-diagnosis")}</GSmallLabel>
            <GSmallLabel className={"mb-3"} bold>{getDiagnosisName(props.diagnosis.Name)}</GSmallLabel>
            <div className={"group cursor-pointer relative pt-1.188rem pb-1.188rem lg:pb-0 lg:pt-0"}>
                <div onClick={()=>props.onClick(algoImages)}  className={"rounded-xl  absolute bg-#50869F66 h-19.938rem md:h-24.438rem sm:h-20.938rem lg:h-11rem  lg:w-12rem w-full lg:w-60 "+(algoImages.outputImageUrl ? " group-hover:opacity-100 opacity-0 " :" opacity-0 ")}>
                    <img className={" absolute right-0.625rem bottom-0.625rem "} src={magnifier} alt={"diagnosis image"}/>
                </div>
                {
                    props.overlay
                    ?
                        <div className={"cursor-pointer  lg:w-12rem "}>
                            {
                                algoImages && algoImages.outputImageUrl
                                    ?
                                        <img className={"rounded-xl sm:h-20.938rem lg:h-11rem"} src={algoImages.outputImageUrl} alt={"diagnosis image"}/>
                                    :
                                        showNoImages || props.diagnosis.Name === "Other"
                                            ?
                                                <div className={"rounded-xl sm:h-20.938rem lg:h-11rem"}>
                                                    {i18n.t("general.no-image")}
                                                </div>
                                            :
                                                <div className={" rounded-xl flex sm:h-20.938rem lg:h-11.25rem "}>
                                                    <div className={"shadow-md flex relative rounded-full animate-spin h-10 w-10 opacity-60 bg-#1396B4 m-auto "}>
                                                        <div className={"flex m-auto rounded-full h-8 w-8 bg-white m-auto "}></div>
                                                        <div className={"absolute h-4 w-4 bg-white m-auto "}></div>
                                                    </div>
                                                </div>
                            }
                        </div>
                    :
                        <div className={"cursor-pointer   lg:w-12rem "}>
                            {
                                algoImages && algoImages.inputImageUrl
                                    ?
                                        <img className={" rounded-xl  sm:h-20.938rem lg:h-11rem "} src={algoImages.inputImageUrl} alt={"diagnosis image"}/>
                                    :
                                        showNoImages || props.diagnosis.Name === "Other"
                                            ?
                                                <div className={" rounded-xl  sm:h-20.938rem lg:h-11rem "}>
                                                    {i18n.t("general.no-image")}
                                                </div>
                                            :
                                                <div className={" rounded-xl flex sm:h-20.938rem lg:h-11.25rem "}>
                                                    <div className={"shadow-md flex relative rounded-full animate-spin h-10 w-10 opacity-60 bg-#1396B4 m-auto "}>
                                                        <div className={"flex m-auto rounded-full h-8 w-8 bg-white m-auto "}></div>
                                                        <div className={"absolute h-4 w-4 bg-white m-auto "}></div>
                                                    </div>
                                                </div>
                            }
                        </div>
                }
            </div>
        </div>
    )
};
export default GDiagnosis;
