import React, {useContext, useState} from 'react'
import GBigLabel from "./GBigLabel";
import myVideo from "../assets/gynd-movie.mp4";
import GSmallLabel from "./GSmallLabel";
import GButton from "./GButton";
import GTransparentButton from "./GTransparentButton";
import GModal from "./GModal";
import {GlobalContext} from "../machines/GlobalStateMachine";
import {useActor} from "@xstate/react";
import {useTranslation} from "react-i18next";
import axios from "axios";
import Config from "../services/Config";
import {BackendContext} from "../machines/BackendMachine";
import DeviceIssueIcon from "../assets/device_issue.png";

const EmailPopup=()=>{
    const { t, i18n } = useTranslation()
    const GContext = useContext(GlobalContext)
    const [GlobalCurrentState, UpdateGlobal] = useActor(GContext);

    const BEContext = useContext(BackendContext)
    let BackendCurrent, BESend;
    [BackendCurrent, BESend] = useActor(BEContext);

    const [showFeedback,SetShowFeedback] = useState(false)
    const [errorFeedback,SetErrorFeedback] = useState(false)
    const SendEmailAndClosePopup=()=>{
        SetShowFeedback(false)
        SetErrorFeedback(false)
        let lang = localStorage.getItem("language");
        if(!lang){
            lang = "en";
        }

        axios.post(`${Config.globalUrl}:${Config.globalPort}/api/v1/webapp/doctors/${BackendCurrent.context.profile.userId}/emailTestResult/`,{
            "TestID":GlobalCurrentState.context.testSummaryNumber,
            "currentLanguage":lang
        },{headers: {'Authorization': BackendCurrent.context.authToken}})
            .then(async (response) => {
                {
                    SetShowFeedback(true)
                    setTimeout(()=>{
                        UpdateGlobal({type:"CLOSE_EMAIL_POPUP"})
                    },2000)
                }
            })
            .catch( async (error) => {
                SetErrorFeedback(true)
                //UpdateGlobal({type:"CLOSE_EMAIL_POPUP"})
            });

    }

    return(

        <GModal handleClose={() => UpdateGlobal({type:"CLOSE_EMAIL_POPUP"})} className={"rounded-3xl h-2/4"}
                closeButtonStyle={"mr-auto ml-auto mb-1.688rem w-6.625rem "}>
            <div className={"relative mt-1.797rem mr-2.937rem ml-2.937rem"}>

                <div
                    className={"flex text-center whitespace-nowrap  justify-content-center mb-0.938rem"}>
                    <GBigLabel className={"text-1.375rem"}>
                        <GSmallLabel>Email Summary</GSmallLabel>
                    </GBigLabel>
                </div>
                {
                    showFeedback
                    ?
                        <GSmallLabel className={"absolute flex justify-content-center  w-full"}>
                            <GSmallLabel bold className={"justify-content-center flex "}>Email Sent</GSmallLabel>
                        </GSmallLabel>
                    :
                        null
                }
                {
                    errorFeedback
                        ?
                        <GSmallLabel className={"absolute flex justify-content-center  w-full"}>
                            <GSmallLabel color={"text-red-500"} bold className={"justify-content-center flex "}>Error</GSmallLabel>
                        </GSmallLabel>
                        :
                        null
                }
                {/*SUB MESSAGE*/}
                <div className={"space-y-2 mt-16 text-center leading-1.291rem mb-1.563rem"}>
                    <GSmallLabel className={"flex justify-content-center space-x-2"}>
                        <GSmallLabel>Test Number</GSmallLabel>
                        <GSmallLabel bold >{GlobalCurrentState.context.testSummaryNumber}</GSmallLabel>
                    </GSmallLabel>
                    <GSmallLabel className={"flex justify-content-center space-x-2"}>
                        <GSmallLabel>Will be sent to:</GSmallLabel>
                        <GSmallLabel bold >{BackendCurrent.context.userProfile.Email}</GSmallLabel>
                    </GSmallLabel>
                </div>
                <div className={"h-3.125rem mt-16"}>
                    <div className={""}>
                        <GButton onClick={()=>{
                           SendEmailAndClosePopup()
                        }} className={"h-3.125rem"}>
                          Send
                        </GButton>
                        <GTransparentButton onClick={()=>UpdateGlobal({type:"CLOSE_EMAIL_POPUP"})} className={"h-3.125rem"}>
                            Cancel
                        </GTransparentButton>
                    </div>
                </div>
            </div>
        </GModal>
    )
}
export default EmailPopup
