import React, { Component } from "react";
import {  FormGroup, FormControl } from "react-bootstrap";
import "../style/login.css";
import LoaderButton from "../components/LoaderButton";
import axios                     from 'axios';
import Config                    from '../services/Config';

export default class Forgot extends Component {

  constructor(props) {
    super(props);
    this.state = {
      regpassword:'',
      confirmpass:'',
      Match:true,
      Valid:true,
      isPaswordResetSent:false,
    };
  }



  handleResetPasswordControlChange = event=>{

    this.setState({
      [event.target.id]: event.target.value
    })

  };

  handleResetPassword = async event => {
    event.preventDefault();
    var re = new RegExp("^(?=.*[A-Za-z])(?=.*\\d)[A-Za-z\\d]{8,}$");
    var _test = re.test(this.state.confirmpass);
    await this.setState({Valid:_test,Match:this.state.confirmpass === this.state.regpassword})

    if(!this.state.Match || !this.state.Valid){
      return;
    }
    var body = {
        Token:window.location.href.split('?token=')[1],
        Password:this.state.regpassword
    }
    event.preventDefault();
    // this.setState({ isLoading: true });
    var url = `${Config.globalUrl}:${Config.globalPort}/api/v1/webapp/forgot/ResetConfirm/`
    await axios.post(url,body)
    .then(async (response) => {
        await this.setState({isPaswordResetSent:true})
        setTimeout(
          function() {
            this.props.history.push("/Login")
          }
          .bind(this),
          3000
      );

    })
    .catch( async (error) => {
      console.log(error);
    });
  };


  render() {
    const showFeedback = ((val, text, icon = true) => {
      return <div>
        {
          val
            ?
            <div />
            :
            <div className={icon ? 'validation-error shifted' : 'validation-error'}>{text}</div>
        }
      </div>


    })
      return(

        <div>
          <img className="bgrb" alt="bgrb" src={require('../assets/bgrb.svg').default}></img>
          <img className="bgrt" alt="bgrt" src={require('../assets/bgrt.svg').default}></img>
          <img className="bglt" alt="bglt" src={require('../assets/bglt.svg').default}></img>
          <div  className="ForgotPass">

            <div className='main-container'>
            <div className="right-block">
                <div className='big-label'>
                <div className='gynilogo'>
                    <img src = {require('../assets/gyninewlogo.svg').default} alt="lock"/>
                </div>
                {
                    this.state.isPaswordResetSent ?
                      <div className='big-label message-sent'>
                          <label id="welcome-title">Success!</label>
                          <label id="welcome-title">You will be forwarded to</label>
                          <label id="welcome-title">Login page</label>
                      </div>
                    :
                      <label>
                        Password Reset
                      </label>
                  }
                </div>
            </div>
             <div className="left-block">
            <form   style ={{"height":"42vh"}} className='custom-form' onSubmit={this.handleResetPassword}>
                <div className='small-label'>
                    <label>
                        Password
                    </label>
                </div>
                <FormGroup controlId="regpassword" >
                          <div className='input-with-icon'>
                            <span className = 'my-icon'>
                              <img src = {require('../assets/lock.svg').default} alt="lock"/>
                            </span>
                            <FormControl
                            autoComplete="off"
                              placeholder="Password"
                              autoFocus
                              type="password"
                              value={this.state.regpassword}
                              onChange={this.handleResetPasswordControlChange}
                            />
                          </div>
                        </FormGroup>
                          <FormGroup controlId="confirmpass" >
                          <div className='input-with-icon'>
                            <span className = 'my-icon'>
                              <img src = {require('../assets/lock.svg').default} alt="lock"/>
                            </span>
                            <FormControl
                              placeholder="Re-enter Password"
                              autoFocus
                              type="password"
                              value={this.state.confirmpass}
                              onChange={this.handleResetPasswordControlChange}
                            />
                          </div>
                        </FormGroup>
                        {showFeedback(this.state.Match, 'Passwords not Match', false)}
                        {showFeedback(this.state.Valid, 'Password must contain at least 8 digits and one letter', false)}
                <LoaderButton
                    style={{"margin-top": "10%"}}
                    block
                    // disabled={!this.validateResetPasswordForm()}
                    type="submit"
                    isLoading={this.state.isLoading}
                    text="SUBMIT PASSWORD"
                    loadingText="Submitting..."
                />


            </form>
            </div>
        </div>

      </div>
        </div>

      )
  }
}
