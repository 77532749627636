import React, {useContext, useEffect} from 'react'
import {BackendContext} from "../../machines/BackendMachine";
import {useActor, useMachine} from "@xstate/react";
import {LoginContext, LoginMachine} from "../../machines/LoginMachine";
import logo from "../../assets/logo_svg.svg";
import GBigLabel from "../GBigLabel";
import GSmallLabel from "../GSmallLabel";
import GTextInput from "../GTextInput";
import GButton from "../GButton";
import {useTranslation} from "react-i18next";
import ParseError from "../../services/ErrorParser";

const ForgotPasswordRequestPage=()=>{
    const { t, i18n } = useTranslation()
    const BEContext = useContext(BackendContext)
    let BackendCurrent, BESend;
    [BackendCurrent, BESend] = useActor(BEContext);

    const LContext = useContext(LoginContext)
    let lc, LoginSend;
    [lc, LoginSend] = useActor(LContext);


    useEffect(()=>{
        if(BackendCurrent.context.forgetPasswordStatus === "true"){
            setTimeout(()=>{
                BESend({type:"RESET_PASSWORD_FORGET"})
                LoginSend({type:"RESET_MACHINE"})
                LoginSend({type:"NAV_TO_LOGIN"})
            },4000)
        }
    })
    const handleKeyPress=(e)=>{
        if(e.key === "Enter"){
            BESend({ type : "REQUEST_FORGET_PASSWORD" , email:lc.context.email})
        }
    }
    return (

        <div className={"pt-6.994rem "}>
            <img className={"h-3.938rem m-auto"} src={logo} alt={"logo"}/>
            <GBigLabel className={"mt-3.813rem leading-1.914rem text-2rem w-full flex justify-center"}>{t("login.password.reset")}</GBigLabel>
            <div className={"mt-2 flex justify-center space-x-0.313rem"}>
                <GSmallLabel className={"leading-1.076rem"}>{t("login.password.reset.question")}</GSmallLabel>
                <GSmallLabel color={"text-#50C2BC"} bold className={"leading-1.076rem cursor-pointer "} onClick={()=>{
                    LoginSend({type:"RESET_MACHINE"})
                    LoginSend({type:"NAV_TO_LOGIN"})
                }} >{t("login.sign-in")}</GSmallLabel>
            </div>
            <div className={"text-red-500 w-full text-center ml-auto mr-auto absolute"}>
                {
                    BackendCurrent.context.forgetPasswordStatus === "true"
                        ?
                        <GSmallLabel bold className={"text-black"}>{t("login.sign-up.forget-password-sent")}</GSmallLabel>
                        :
                        BackendCurrent.context.forgetPasswordStatus === "error"
                            ?
                            <div>{ParseError(BackendCurrent.context.forgetPasswordError)}</div>
                            :
                            null
                }
            </div>
            <div className={"mt-3.438rem w-21.875rem ml-auto mr-auto space-y-1.875rem"}>
                <GTextInput
                    max={50}
                    onKeyPress={(e)=>handleKeyPress(e)}
                    validation
                    error={lc.context.invalid_email}
                    value={lc.context.email}
                    onChange={(e)=>{LoginSend({type:"INPUT_EMAIL",email:e.target.value})}}
                    className={"bg-gyni-textBox h-3.5rem rounded-lg"} placeHolder={t("login.placeholder.email")}/>
            </div>
            <GButton
                onClick={()=>BESend({ type : "REQUEST_FORGET_PASSWORD" , email:lc.context.email})}
                className={"mt-4.563rem h-10 w-21.875rem ml-auto mr-auto"}>
                {t("login.password.buttons.send")}
            </GButton>
        </div>

    )
}
export default ForgotPasswordRequestPage;
