import React, {Suspense} from "react";
import './index.css';
import {   withRouter } from "react-router-dom";
import './i18n'
import "./App.css";
import "./RemasterComponents/remasteredStyles/text-responsive-heigth.css"
import "./RemasterComponents/remasteredStyles/g-modal.css"
import "./RemasterComponents/remasteredStyles/global-respnosive.css"

import Container from "./RemasterComponents/Container";
import {RoutingMachine} from "./machines/RoutingMachine";

import {RouteContext} from "./machines/RoutingMachine";
import {BackendContext, BackendMachine} from "./machines/BackendMachine";
import {useMachine} from "@xstate/react";
import {DeviceMachine, DeviceContext} from "./machines/DeviceMachine";
import {GlobalMachine ,GlobalContext} from "./machines/GlobalStateMachine";
import {TestMachine ,TestContext} from "./machines/TestMachine";

import {LoginMachine,LoginContext} from "./machines/LoginMachine";

const App =()=> {

    const [,,RoutingService]    = useMachine(RoutingMachine);
    const [,,BackendService]    = useMachine(BackendMachine);
    const [,,DeviceService]     = useMachine(DeviceMachine);
    const [,,GlobalService]     = useMachine(GlobalMachine);
    const [,,TestService]       = useMachine(TestMachine);
    const [,,LoginService]       = useMachine(LoginMachine);
    return (

            <BackendContext.Provider value={BackendService}>
                <RouteContext.Provider value={RoutingService}>
                    <GlobalContext.Provider value={GlobalService}>
                        <LoginContext.Provider value={LoginService}>
                            <TestContext.Provider value={TestService}>
                                <DeviceContext.Provider value={DeviceService}>
                                    <Suspense fallback={null}>
                                        <Container/>
                                    </Suspense>
                                </DeviceContext.Provider>
                            </TestContext.Provider>
                        </LoginContext.Provider>
                    </GlobalContext.Provider>
                </RouteContext.Provider>
            </BackendContext.Provider>
    );
}

export default withRouter(App);
