import React, { Component } from 'react'
import '../../style/Admin/ClinicAndUsersManagementPage.css'
// import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
// import paginationFactory from 'react-bootstrap-table2-paginator';
// import BootstrapTable from 'react-bootstrap-table-next';
import axios                     from 'axios';
import Config                    from '../../services/Config';

import MyVerticallyCenteredModal from "../../components/MyVerticallyCenteredModal";
import Form from 'react-bootstrap/Form'
// import ProgressBar from 'react-bootstrap/ProgressBar'
// import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';
// import PDF from '../../services/PDF'

// const { ExportCSVButton } = CSVExport;

class ClinicAndUsersManagementPage extends Component {

    constructor(props) {
        super(props);
        this.state={
            array1:[],
            selectedDoctor:null,
            data:[],
            headertext: '',
            bodytext: '',
            closeonly: "true",

            closetext: '',

            yestext: '',

            notext: '',
            globalTrialDataMaxValue:0,
            globalTrialData: [
                { Name: 'bacterialVaginosis', My: 0, TotalProgress: 0, Target: 0 },
                { Name: 'candidaAlbicans', my: 0, TotalProgress: 0, Target: 0 },
                { Name: 'candidaNonAlbicans', my: 0, TotalProgress: 0, Target: 0 },
                { Name: 'trichomoniasis', my: 0, TotalProgress: 0, Target: 0 },
                { Name: 'atrophicVaginitis', my: 0, TotalProgress: 0, Target: 0 },
                { Name: 'purulentVaginitis', my: 0, TotalProgress: 0, Target: 0 },
                { Name: 'normal', my: 0, TotalProgress: 0, Target: 0 }
            ],
        }
    }
    componentDidMount() {
        this.getClinicsData();
    }
    async updateLabResultsCountByDoctorsId(UserID){

        await axios.get(`${Config.globalUrl}:${Config.globalPort}/api/v1/webapp/general/${Config.UserID}/getClinicalTrailData`,Config.token)
        .then(async (response) => {

                // response.data.forEach(lab => {
                //     const myProgress = lab['DoctorsProgress'].filter(x => x.Email === Email)[0];
                //     if(myProgress){
                //         lab.My = myProgress['ProgressVal'];
                //     }else{
                //         lab.My = 0;
                //     }
                // });
                response.data.forEach(lab => {
                    var myProgress = lab.DoctorsProgress.filter(x => x.UserID === UserID)[0];
                    if (myProgress) {
                        lab.My = myProgress.ProgressVal;
                    } else {
                        lab.My = 0;
                    }
                    lab.TotalProgress = 0;
                    lab.DoctorsProgress.forEach(doctorProgress=>{
                        lab.TotalProgress += doctorProgress.ProgressVal;
                    })
                });

                await this.setState({globalTrialData:response.data});
                let maxTrialTarget = Math.max.apply(Math, this.state.globalTrialData.map(function (o) { return o.Target; })); //get maximum target

                await this.setState({ //set state and update view
                    globalTrialData:response.data,
                    globalTrialDataMaxValue: maxTrialTarget,
                    // chartXAxisValues:this.prepareChartXAxisValues(maxTrialTarget)
                });

        })
        .catch( async (error) => {
            console.log(error);
        });
    }

    async getClinicsData(){
        //TODO: change url to get clinics
        await axios.get(`${Config.globalUrl}:${Config.globalPort}/api/v1/webapp/admins/${Config.UserID}/getClinics`,Config.token)
        .then(async (response) => {
                console.log('response '+ (new Date()));
                response.data.forEach(x=>{
                    x.Devices = [];
                    x.DeviceID.forEach(d=>{
                        x.Devices.push(d.DeviceID)
                    })
                })
                await this.setState({data:response.data});
        })
        .catch( async (error) => {
            console.log(error);
        });
    }
    async getDoctorsByClinicID(value){
        await axios.get(`${Config.globalUrl}:${Config.globalPort}/api/v1/webapp/admins/${Config.UserID}/getDoctorsByClinic/${value}`,Config.token)
        .then(async (response) => {
                // console.log('response '+ (new Date()));
                response.data.forEach(x=>{
                    x.editHandler = <div onClick = {this.openEditModal.bind(this,x)}>EDIT</div>
                })
                this.state.data.filter(x=>x.ClinicID===value)[0].doctors = response.data;

                await this.setState({data:this.state.data});
        })
        .catch( async (error) => {
            console.log(error);
        });
    }
    async openModal(ClinicID){

        await this.setState({modalShow:true,selectedClinic:ClinicID});
        // const graph_ref = this.refs['graph'];
        // graph_ref.classList.add('hidden');
    }

    async openEditModal(row){
        await this.setState({modalShow:true,selectedDoctor:row});
        this.updateLabResultsCountByDoctorsId(row.UserID);
        const form_ref = this.refs['userFormRef'];
        // var _modal = document.getElementById('clinicsandusersmanagmentmodal');
        // _modal.children[0].classList.add('clinics-modal-custom')
        form_ref.elements['formEmail'].value     =row.Email;
        form_ref.elements['formFirstName'].value =row.FirstName;
        form_ref.elements['formLastName'].value  =row.LastName;
    }
    async handlemodalclose(){
        await this.setState({modalShow:false});
        await this.setState({selectedDoctor:null});
    }
    async handlemodalyes(){
        const form_ref = this.refs['userFormRef'];
        if(this.state.selectedDoctor){
            const edit_user = this.state.selectedDoctor;

            edit_user.Email     = form_ref.elements['formEmail'].value;
            edit_user.FirstName = form_ref.elements['formFirstName'].value;
            edit_user.LastName  = form_ref.elements['formLastName'].value;
            // edit_user.IsEnabled = form_ref.elements['formIsEnabled'].value;
            this.editUser(edit_user);
            await this.setState({selectedDoctor:null});

        }else{
            //  var _t = this.state.data.filter(x => x.ClinicID === this.state.selectedClinic)[0].DeviceID[0];
            const new_user = {
                // Password:   form_ref.elements['formPassword'].value,

                Email:      form_ref.elements['formEmail'].value,
                FirstName:  form_ref.elements['formFirstName'].value,
                LastName:   form_ref.elements['formLastName'].value,
                IsEnabled:  true,
                // DeviceID: _t,
                ClinicID: this.state.selectedClinic,
                Role: ["Doctor"],
            };
            this.addNewUser(new_user);
        }
    }
    async editUser(body){
        // FirstName,
        // LastName,
        // ClinicID,
        // DeviceID,
        // Active_Status,
        // IsEnabled,
        // Email,
        await axios.post(`${Config.globalUrl}:${Config.globalPort}/api/v1/webapp/general/${Config.UserID}/updateDoctor/${body.UserID}`,body,Config.token)
        .then(async (res) => {
            this.getDoctorsByClinicID(body.ClinicID);
            await this.setState({modalShow:false});
        })
        .catch( async (error) => {
            console.log(error);
            await this.setState({modalShow:false});
        });
    }
    async addNewUser(body){

        await axios.post(`${Config.globalUrl}:${Config.globalPort}/api/v1/webapp/doctors/${Config.UserID}/signup/`,body,Config.token)
        .then(async () => {
            this.getDoctorsByClinicID(this.state.selectedClinic);
            await this.setState({modalShow:false});
        })
        .catch( async (error) => {
            console.log(error);
        });
    }
    static  handleEditPressed(cell){
        return (<div>EDIT</div>)
    }
    static deviceformatter(cell) {
        return (
            cell ?
                <div className='diag-container'>
                {
                    cell.map(x=>
                        <div style={{"textAlign":"center"}}>{x}</div>
                    )
                }
                </div>
                :
                <div></div>

        );
    }
    handleSearchChange(row,val){
        const _data = this.state.data;
        const d = _data.indexOf(row);
        const filteredTests = this.state.data[d].tests

        _data[d].filteredTests = filteredTests.filter(x=>x.TestID.indexOf(val.target.value) !== -1);
        this.setState({
            data:_data
        });
    }
     static setBarOpacity(Val){
        switch (Val) {
            case 0:
            default:
                return "1.0";
            case 1:
                return "0.6";
            case 2:
                return "0.8";
        }
    }
     static getBarColor(iter){
        let officialChartColors = [
            '#F78B5D',
            '#EF9F65',
            '#F4C856',
            '#9870EC',
            '#3DD2DB',
            '#3D8FDB',
            '#34BD84',
        ];
        let result = officialChartColors[iter];
        if(iter >= officialChartColors.length){
            result = '#'+Math.floor(Math.random()*16777215).toString(16);
        }
        return result;
    }
    setEnabled(e){
        var s_doc = this.state.selectedDoctor;
        s_doc.IsEnabled =e.target.value.toLowerCase();
        this.setState({selectedDoctor:s_doc})
    }
    render() {
        // const customTotal = (from, to, size) => (
        //     <span className="react-bootstrap-table-pagination-total">
        //       Showing { from } to { to } of { size } Results
        //     </span>
        // );
        // const Pagination = paginationFactory({
        //     paginationSize: 4,
        //     pageStartIndex: 0,
        //     // alwaysShowAllBtns: true, // Always show next and previous button
        //     // withFirstAndLast: false, // Hide the going to First and Last page button
        //     // hideSizePerPage: true, // Hide the sizePerPage dropdown always
        //     // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
        //     firstPageText: 'First',
        //     prePageText: 'Back',
        //     nextPageText: 'Next',
        //     lastPageText: 'Last',
        //     nextPageTitle: 'First page',
        //     prePageTitle: 'Pre page',
        //     firstPageTitle: 'Next page',
        //     lastPageTitle: 'Last page',
        //     showTotal: true,
        //     paginationTotalRenderer: customTotal,
        //     sizePerPageList: [{
        //       text: '5', value: 5
        //     }, {
        //       text: '10', value: 10
        //     },
        //     {
        //       text: 'All', value: this.state.data.length
        //     }] // A numeric array is also available. the purpose of above example is custom the text
        // });
        // const drillDownColumns = [
        //
        //     // Test #
        //     {
        //         dataField: 'FirstName',
        //         text: 'First Name'
        //     }
        //     ,
        //     // Test state
        //     {
        //         dataField: 'LastName',
        //         text: 'Last Name'
        //     },
        //     // // Doctors diagnosis
        //     {
        //         dataField: 'Email',
        //         text: 'Email',
        //     },
        //     {
        //         dataField: 'IsEnabled',
        //         text: 'Active',
        //     },
        //     {
        //         dataField: 'editHandler',
        //         text: 'Edit',
        //         formatter:ClinicAndUsersManagementPage.openEditModal
        //     },
        //
        // ];
        // const columns = [
        //
        //     {
        //         dataField: 'ClinicID',
        //         text: 'Clinic Name',
        //         formatter:(cell)=>{return <div className='download-button'>{cell}</div>}
        //     }
        //     ,
        //     {
        //         dataField: 'Location.Country', //<< NEED TO ADD TO BE
        //         text: 'Country'
        //     }
        //     ,
        //     {
        //         dataField: 'Location.City', //<< NEED TO ADD TO BE
        //         text: 'City'
        //     }
        //     ,
        //     {
        //         dataField: 'Location.State', //<< NEED TO ADD TO BE
        //         text: 'State'
        //     }
        //     ,
        //     {
        //         dataField: 'Devices', //<< NEED TO ADD TO BE
        //         text: 'Connected Scanner',
        //         formatter:ClinicAndUsersManagementPage.deviceformatter,
        //     }
        // ];
        // const expandGraph = {
        //     renderer : (row) => (
        //         <div className={'expanded-row-container'}>
        //             <div className = 'graphic' >
        //                 {
        //                     this.state.globalTrialData.map((element,iter)=>
        //                             <div key={iter} className='bar-chart-entity3'>
        //                                 <div className='chart-name-label-container'>
        //                                     <label key ={element.Name}>{element.Name}</label>
        //                                 </div>
        //                                 <div className='pb'>
        //                                     <div className='chart-info-label-container-ph'/>
        //                                     <div>
        //                                         <ProgressBar key={5} className='chart-entity-back'>
        //                                             <ProgressBar style={{backgroundColor : ClinicAndUsersManagementPage.getBarColor(iter),opacity:ClinicAndUsersManagementPage.setBarOpacity(0)}} max={100-element.My} now={element.My} key={1} />
        //                                             <ProgressBar style={{backgroundColor : ClinicAndUsersManagementPage.getBarColor(iter),opacity:ClinicAndUsersManagementPage.setBarOpacity(1)}} max={100-element.TotalProgress} now={element.TotalProgress} key={2} />
        //                                             <ProgressBar style={{border:`1px solid ${ClinicAndUsersManagementPage.getBarColor(iter)}`,backgroundColor : 'white',opacity:ClinicAndUsersManagementPage.setBarOpacity(2)}} max={100} now={(element.Target)} key={3} />
        //                                         </ProgressBar>
        //                                     </div>
        //                                 </div>
        //                                 <div className='chart-info-label-container'>
        //                                     {iter===0?<label>This user</label>:<div className='chart-info-label-container-ph'/>}
        //                                     <label style={{color : ClinicAndUsersManagementPage.getBarColor(iter)}}>{element.My}</label>
        //                                 </div>
        //                                 <div className='chart-info-label-container'>
        //                                     {iter===0?<label>Progress</label>:<div className='chart-info-label-container-ph'/>}
        //                                     <label style={{color : ClinicAndUsersManagementPage.getBarColor(iter)}}>{element.TotalProgress}</label>
        //                                 </div>
        //                                 <div className='chart-info-label-container'>
        //                                     {iter===0?<label>Target</label>:<div className='chart-info-label-container-ph'/>}
        //                                     <label style={{color : ClinicAndUsersManagementPage.getBarColor(iter)}}>{element.Target}</label>
        //                                 </div>
        //                             </div>)
        //                 }
        //                 </div>
        //         </div>
        //     )
        // }
        // const expandRow = {
        //     renderer: (row) => (
        //       <div className={'expanded-row-container'}>
        //         <div>Doctors</div>
        //           {
        //               <div>
        //
        //                   {
        //                       this.state.data.filter(x => x.ClinicID === row.ClinicID)[0].doctors ?
        //                         <ToolkitProvider
        //
        //                         exportCSV
        //                          bootstrap4 id={'doctors-table'}
        //                         keyField='UserID'
        //                         data={this.state.data.filter(x => x.ClinicID === row.ClinicID)[0].doctors}
        //                         columns={drillDownColumns}
        //                         >
        //                         {
        //                             props => (
        //                             <div>
        //                                 <div>
        //                                     <div className='clinic-control-panel'>
        //                                         <button className='my-custom-button' onClick={this.openModal.bind(this,row.ClinicID)}>Add new user</button>
        //                                         <button className='my-custom-button' onClick={PDF.ExportToPDF.bind(this,row,'#users-table',row.ClinicID)}>PDF</button>
        //                                         <ExportCSVButton className='my-custom-button' { ...props.csvProps }>CSV</ExportCSVButton>
        //
        //                                         <div>
        //                                             {/*<input  type={'text'} onChange={this.handleSearchChange.bind(this, row)} placeholder={'Search by test number...'}/>*/}
        //                                         </div>
        //                                         <div/>
        //                                         <div/>
        //                                         <div/>
        //                                         <div/>
        //                                         <div/>
        //                                         <div/>
        //                                     </div>
        //                                 </div>
        //                                 <hr />
        //                                 {/*<BootstrapTable id='users-table' expandRow={ expandGraph }  rowEvents={doctorsTableRowEvents} { ...props.baseProps } />*/}
        //                             </div>
        //                             )
        //                         }
        //                         </ToolkitProvider>
        //
        //
        //                           :
        //                           <div>None...</div>
        //                   }
        //               </div>
        //           }
        //       </div>
        //     )
        // };
        // const doctorsTableRowEvents = {
        //     onClick: (e, row) => {
        //        // this.openEditModal(row);
        //        this.updateLabResultsCountByDoctorsId(row.Email);
        //     },
        // };
        // const clinicsTableRowEvents = {
        //     onClick: (e, row) => {
        //       this.getDoctorsByClinicID(row.ClinicID);
        //     },
        //   };

        return (
            <div>
                <MyVerticallyCenteredModal
                    id='clinicsandusersmanagmentmodal'
                    show={this.state.modalShow}
                    headertext=''
                    bodytext={
                        <div>
                        <Form  onSubmit = {(event)=>{ event.preventDefault();}}  className = 'my-custom-form' ref={'userFormRef'}>

                            <Form.Group controlId="formEmail">
                                <Form.Label>Email address</Form.Label>
                                <Form.Control className="clinics-and-users-form-control" type="email" placeholder="Enter email" />
                            </Form.Group>

                            <Form.Group controlId="formFirstName">
                                <Form.Label>First Name</Form.Label>
                                <Form.Control className="clinics-and-users-form-control" type="text" placeholder="Enter first name" />
                            </Form.Group>
                            <Form.Group controlId="formLastName">
                                <Form.Label>Last Name</Form.Label>
                                <Form.Control className="clinics-and-users-form-control"  type="text" placeholder="Enter last name" />
                            </Form.Group>

                            {/* <Form.Group controlId="formUserName">
                                <Form.Label>User Name</Form.Label>
                                <Form.Control className="clinics-and-users-form-control"  type="text" placeholder="Enter username name" />
                            </Form.Group> */}

                            {/* <Form.Group controlId="formPassword">
                                <Form.Label>Password</Form.Label>
                                <Form.Control className="clinics-and-users-form-control"  type="text" placeholder="Password" />
                            </Form.Group> */}
                            {
                                this.state.selectedDoctor ?
                                    <Form.Group controlId="formIsEnabled">
                                        <Form.Label>is user enabled</Form.Label>
                                    </Form.Group>
                                :
                                <div></div>
                            }
                            {
                                this.state.selectedDoctor ?
                                    this.state.selectedDoctor.IsEnabled ?
                                    <select className="form-control-dropdown" onChange={this.setEnabled.bind(this)}>
                                        <option selected val={true}>TRUE</option>
                                        <option val={false}>FALSE</option>
                                    </select>
                                    :
                                    <select className="form-control-dropdown" onChange={this.setEnabled.bind(this)}>
                                        <option val={true}>TRUE</option>
                                        <option selected val={false}>FALSE</option>
                                    </select>
                                :
                                <div></div>
                            }

                        </Form>


                        </div>
                    }
                    closeonly="false"
                    handlemodalclose={this.handlemodalclose.bind(this)}
                    closetext='Close'
                    handlemodalyes={this.handlemodalyes.bind(this)}
                    yestext='Save'
                    handlemodalno={this.handlemodalclose.bind(this)}
                    notext='Cancel'
                />
                <label className="title">
                    Clinics and User Management
                </label>
                <div>
                    {/*<div className='my-custom-button' onClick={PDF.ExportToPDF.bind(this,null,'#clinics-table','clinics')}>Export table as PDF</div>*/}
                    {/*<BootstrapTable id='clinics-table' bootstrap4 keyField='ClinicID' data={ this.state.data } columns={ columns } expandRow={ expandRow } pagination={Pagination} rowEvents={ clinicsTableRowEvents }/>*/}
                </div>
            </div>
        );
    }
}
export default ClinicAndUsersManagementPage ;
