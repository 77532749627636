import React from 'react'
import GBigLabel from "../GBigLabel";
import {useTranslation} from "react-i18next";

const PrivacyPolicy=()=>{
    const { t, i18n } = useTranslation()
    return(
        <div className={"clinic-details-height-responsive pt-20"}>

            <GBigLabel className={"mt-20 flex justify-content-center text-2rem"}>{t("routing.privacy-policy")}</GBigLabel>
            <div className={"  ml-10 sm:ml-6.25rem mr-10 sm:mr-6.25rem mt-3.75rem mb-3.75rem shadow-gyni rounded-2xl"}>
                <div className={"lg:flex"}>
                    <div className={"lg:w-18.375rem sm:pr-2.813rem pt-3.125rem pb-3.125rem  pl-2 pr-2  sm:pl-2.813rem shadow-gyni rounded-2xl"}>
                        <div className={"flex"}>
                            <GBigLabel  className={"md:ml-2.813rem md:mb-3.438rem text-1.375rem"}></GBigLabel>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default PrivacyPolicy
