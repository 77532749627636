import React, {useContext} from 'react'
import GSmallLabel from "./GSmallLabel";
import star from "../assets/star.svg"
import {BackendContext} from "../machines/BackendMachine";
import {useActor} from "@xstate/react";
const GInitialsIcon=(props)=>{

    const BEContext = useContext(BackendContext)
    let BackendCurrent, BESend;
    [BackendCurrent, BESend] = useActor(BEContext);

    const ProfileImage = props.image;
    const isAdmin = props.isAdmin;
    return(
        <div>
            <div className={"flex relative w-2.75rem h-2.75rem bg-#1396B412 rounded-full "+(BackendCurrent.context.profile.userId.indexOf("admin")>-1 ? " border-2 border-yellow-300" : " ")}>
                {
                    ProfileImage
                    ?
                        <img src={ProfileImage}/>
                    :
                        <GSmallLabel bold className={"mt-auto mb-auto w-full text-center"} color={"text-#1396B4"}>
                            {
                                props.user
                                ?
                                    props.isClinicalUser
                                    ?
                                        props.user.firstName && props.user.lastName
                                        ?
                                            props.user.firstName[0].toUpperCase() +""+props.user?.lastName[0].toUpperCase()
                                        :
                                            ""
                                    :
                                        "A"
                                :
                                    BackendCurrent.context.userProfile?.FirstName
                                        ?
                                        BackendCurrent.context.userProfile?.FirstName[0].toUpperCase() +""+BackendCurrent.context.userProfile?.LastName[0].toUpperCase()
                                        :
                                        BackendCurrent.context.profile.userId.split("@").length > 0
                                            ?
                                            BackendCurrent.context.profile.userId.split("@")[0][0].toUpperCase()
                                            :
                                            BackendCurrent.context.profile.userId[0].toUpperCase()
                            }
                        </GSmallLabel>
                }
                {
                    isAdmin || BackendCurrent.context.profile.userId.indexOf("admin")>-1?
                        <img className={"w-4 h-4 absolute top-0 right-0 "} src={star} alt={"star"}/>
                        :
                        null
                }

            </div>
        </div>
    )
}
export default GInitialsIcon;
