import React, {useContext, useEffect, useState} from 'react'
import GModal from "./GModal";
import GCarousel from "./GCarousel";
import GButton from "./GButton";
import GTransparentButton from "./GTransparentButton";
import GHowToConnectScannerStep1 from "./GHowToConnectScannerStep1";
import GHowToConnectScannerStep2 from "./GHowToConnectScannerStep2";
import GHowToConnectScannerStep3 from "./GHowToConnectScannerStep3";
import GHowToConnectScannerStep4 from "./GHowToConnectScannerStep4";
import GHowToConnectScannerStep5 from "./GHowToConnectScannerStep5";
import {GlobalContext} from "../machines/GlobalStateMachine";
import {useActor} from "@xstate/react";
import axios from "axios";
import Config from "../services/Config";
import {BackendContext} from "../machines/BackendMachine";
import DeviceConnectedIcon from "../assets/device_connected.png";
import DeviceErrorIcon from "../assets/device_issue.png"
import GBigLabel from "./GBigLabel";
import GSmallLabel from "./GSmallLabel";
import {useTranslation} from "react-i18next";
import {RouteContext} from "../machines/RoutingMachine";

const ConnectScanner=()=>{
    const { t, i18n } = useTranslation()
    useEffect(()=>{
        setHelpPageSelected(0)
        setScannerIsConnected(false)
    },[])
    const GContext = useContext(GlobalContext)
    const [GlobalCurrentState, UpdateGlobal] = useActor(GContext);
    const BEContext = useContext(BackendContext)
    let BackendCurrent, BESend;
    [BackendCurrent, BESend] = useActor(BEContext);

    const [helpPageSelected,setHelpPageSelected] = useState(0)
    const [ScannerIsConnected,setScannerIsConnected] = useState(false)
    const [ScannerIsConnectedError,setScannerIsConnectedError] = useState(false)
    const [helpPages] = useState(
        <div>
            <GHowToConnectScannerStep1/>
            <GHowToConnectScannerStep2/>
            <GHowToConnectScannerStep3/>
            <GHowToConnectScannerStep4/>
            <GHowToConnectScannerStep5/>
        </div>
    )
    const RContext = useContext(RouteContext)
    const [RoutingCurrentState, Navigate] = useActor(RContext);


    const RegisterScanner=()=>{

        if(GlobalCurrentState.context.NewDeviceSerialNumber.length < 16){
            return;
        }
        var body = {
            SerialNumber:GlobalCurrentState.context.NewDeviceSerialNumber
        }
        axios.post(`${Config.globalUrl}:${Config.globalPort}/api/v1/webapp/siteadmins/${BackendCurrent.context.profile.userId}/setMyClinicDevice/`,body,{headers: {'Authorization': BackendCurrent.context.authToken}})
            .then(async (response) => {
                setScannerIsConnected(true)
                Config.accessKeyId      = response.data.IOTcred.AccessKeyId;
                Config.secretAccessKey  = response.data.IOTcred.SecretAccessKey;
                Config.sessionToken     = response.data.IOTcred.SessionToken;
                Config.deviceName       = response.data._doc.DeviceID;
                BESend({type:"REQUEST_MQTT"})

            })
            .catch( async (error) => {
                console.log(error);
                setScannerIsConnectedError(error)
            });


    }


    return(
    <GModal  handleClose={()=>{ UpdateGlobal({type:"CLOSE_CONNECT_TO_SCANNER"})}}>
        {
            ScannerIsConnected
            ?
                <div className={"pr-8 pl-8"}>
                    {/*IMAGE*/}
                    <div className={"flex justify-content-center mb-1.625rem"}>
                        <img className={"w-8.813rem h-9.125rem"} src={DeviceConnectedIcon} alt={"device issue"}/>
                    </div>
                    {/*TITLE*/}
                    <div className={"flex text-center whitespace-nowrap  justify-content-center mb-0.938rem"}>
                        <GBigLabel className={"text-1.375rem"}>
                            {t("connect.step-6-title")}
                        </GBigLabel>
                    </div>
                    {/*SUB MESSAGE*/}
                    <div className={"text-center leading-1.291rem mb-1.563rem"}>
                        <GSmallLabel>
                            {t("connect.step-6-p1")}
                        </GSmallLabel>
                        <GSmallLabel>
                            {t("connect.step-6-p2")}
                        </GSmallLabel>
                    </div>
                    <div className={"pl-2.625rem pr-2.625rem"}>
                        <GButton onClick={()=>{
                            UpdateGlobal({type:"CLOSE_CONNECT_TO_SCANNER"})
                            Navigate({type:"NAV_TO_HOME"});
                        }} className={"h-3.125rem"}>
                            {t("connect.button.go-to-home-page")}
                        </GButton>
                    </div>
                </div>
            :

                    ScannerIsConnectedError
                    ?
                        <div className={"pr-8 pl-8 max-w-22.25rem "}>
                            {/*IMAGE*/}
                            <div className={"flex justify-content-center mb-1.625rem"}>
                                <img className={"w-8.813rem h-9.125rem"} src={DeviceErrorIcon} alt={"device issue"}/>
                            </div>
                            {/*TITLE*/}
                            <div className={"flex text-center whitespace-nowrap  justify-content-center mb-0.938rem"}>
                                <GBigLabel className={"text-1.375rem"}>
                                    {t("connect.step-7-title")}
                                </GBigLabel>
                            </div>
                            {/*SUB MESSAGE*/}
                            <div className={"text-center leading-1.291rem mb-1.563rem"}>
                                <GSmallLabel className={"mb-3"}>
                                    {t("connect.step-7-p1")}
                                </GSmallLabel>
                                <GSmallLabel>

                                </GSmallLabel>
                                <GSmallLabel>
                                    {t("connect.step-7-p2")}
                                </GSmallLabel>
                            </div>
                            <div className={"pl-2.625rem pr-2.625rem"}>
                                <GButton onClick={()=>{setScannerIsConnectedError(false)}} className={"h-3.125rem"}>
                                    {t("connect.button.close")}
                                </GButton>
                            </div>
                        </div>
                    :
                        <div className={" sm:w-35rem md:w-41.625rem lg:w-59rem xl:w-62.5rem how-to-dialog"}>

                            <GCarousel className={"lg:p-13rem pt-0 pb-0"} SetSelectedPage={(p)=>setHelpPageSelected(p)} siblingControl={true} selectedPage={helpPageSelected} size={1} >
                                {
                                    helpPages.props.children
                                }
                            </GCarousel>
                            <div className={"mt-4 sm:mt-4.375rem"}>
                                {
                                    helpPageSelected === helpPages.props.children.length - 1
                                        ?
                                        <GButton className={"ml-auto mr-auto w-9.375rem h-3.125rem"} onClick={()=>RegisterScanner()} >
                                            {t("connect.button.connect")}
                                        </GButton>
                                        :
                                        <GButton className={"ml-auto mr-auto w-7.5rem h-3.125rem"} onClick={()=>setHelpPageSelected(helpPageSelected < helpPages.props.children.length-1 ? helpPageSelected+1 : helpPageSelected)} >
                                            {t("connect.button.next")}
                                        </GButton>
                                }
                                {
                                    helpPageSelected === 0
                                        ?
                                        <GTransparentButton className={"ml-auto mr-auto w-7.5rem h-3.125rem"} onClick={()=>UpdateGlobal({type:"CLOSE_CONNECT_TO_SCANNER"})} >
                                            {t("connect.button.close")}
                                        </GTransparentButton>
                                        :
                                        <GTransparentButton className={"ml-auto mr-auto w-7.5rem h-3.125rem"} onClick={()=>setHelpPageSelected(helpPageSelected > 0 ? helpPageSelected-1 : helpPageSelected)} >
                                            {t("connect.button.back")}
                                        </GTransparentButton>
                                }

                            </div>

                        </div>
        }

    </GModal>
    )
}
export default ConnectScanner;
