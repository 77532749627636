import React, {useState} from "react"
import OverallScannerViewPage from "../../pages/Admin/OverallScannerViewPage";
import TrialProgressPerUserPage from "../../pages/Admin/TrialProgressPerUserPage";
import TrialOverallProgressPage from "../../pages/Admin/TrialOverallProgressPage";
import ClinicAndUsersManagementPage from "../../pages/Admin/ClinicAndUsersManagementPage";
import ScanningStatisticsPage from "../../pages/Admin/ScanningStatisticsPage";
import ScannerReportPage from "../../pages/Admin/ScannerReportPage";
import TestReportPage from "../../pages/Admin/TestReportPage";
import ErrorLogPage from "../../pages/Admin/ErrorLogPage";
import SystemSetupPage from "../../pages/Admin/SystemSetupPage";
import AuditTrailPage from "../../pages/Admin/AuditTrailPage";
import NotFound from "../../pages/notfound";
import AdminMessagesPage from "../../pages/Admin/AdminMessagesPage";
import AdminFotaPage from "../../pages/Admin/AdminFotaPage";
import UsersManagement from "../../pages/Admin/UsersManagement";
import AdminSystemSettingsPage from "../../pages/Admin/AdminSystemSettingsPage";
const Admin=()=>{
    const [currentAdminPage , setAdminPage] = useState("UsersManagementPage")
    const ViewAdminPage =()=>{
        switch(currentAdminPage){
            case 'UsersManagementPage':
                return  <UsersManagement/>
            case 'OverallScannerViewPage':
                return <OverallScannerViewPage/>
            case 'AdminMessagesPage':
                return <AdminMessagesPage/>
            case 'AdminFotaPage':
                return <AdminFotaPage/>
            case 'AdminSystemSettingsPage':
                return <AdminSystemSettingsPage/>
            case 'TrialProgressPerUserPage':
                return <TrialProgressPerUserPage/>
            case 'TrialOverallProgressPage':
                return <TrialOverallProgressPage/>
            case 'ClinicAndUsersManagementPage':
                return <ClinicAndUsersManagementPage/>
            case 'ScanningStatisticsPage':
                return <ScanningStatisticsPage/>
            case 'ScannerReportPage':
                return <ScannerReportPage/>
            case 'TestReportPage':
                return <TestReportPage/>
            case 'ErrorLogPage':
                return <ErrorLogPage/>
            case 'SystemSetupPage':
                return <SystemSetupPage/>
            case 'AuditTrailPage':
                return <AuditTrailPage/>
            default:
                return <NotFound/>
        }
    }
    return(
        <div className={"pt-20"}>
            {/*ADMIN NAV BAR*/}
            <div className={"flex space-x-4 p-2 mr-10 ml-10"}>
                <button style={{outline:"none"}} className={"  " + (currentAdminPage === "UsersManagementPage"       ? "  text-#50C2BC font-ghotamBold " : "  ")} onClick={()=>setAdminPage("UsersManagementPage"        )}>Clinics And Doctors Management</button>
                <button style={{outline:"none"}} className={"  " + (currentAdminPage === "OverallScannerViewPage"       ? "  text-#50C2BC font-ghotamBold " : "  ")} onClick={()=>setAdminPage("OverallScannerViewPage"        )}>Overall Scanner View Page</button>
                <button style={{outline:"none"}} className={"  " + (currentAdminPage === "AdminMessagesPage"       ? "  text-#50C2BC font-ghotamBold " : "  ")} onClick={()=>setAdminPage("AdminMessagesPage"        )}>Messages</button>
                <button style={{outline:"none"}} className={"  " + (currentAdminPage === "AdminFotaPage"       ? "  text-#50C2BC font-ghotamBold " : "  ")} onClick={()=>setAdminPage("AdminFotaPage"        )}>Fota</button>
                <button style={{outline:"none"}} className={"  " + (currentAdminPage === "AdminSystemSettingsPage"       ? "  text-#50C2BC font-ghotamBold " : "  ")} onClick={()=>setAdminPage("AdminSystemSettingsPage")}>Settings</button>
                {/*<button className={"border " + (currentAdminPage === "TrialProgressPerUserPage"     ? "  text-#50C2BC font-ghotamBold " : "  ")} onClick={()=>setAdminPage("TrialProgressPerUserPage"      )}>Trial Progress Per User Page</button>*/}
                {/*<button className={"border " + (currentAdminPage === "TrialOverallProgressPage"     ? " bg-red-500 " : "  ")} onClick={()=>setAdminPage("TrialOverallProgressPage"      )}>Trial Overall Progress Page</button>*/}
                {/*<button className={"border " + (currentAdminPage === "ClinicAndUsersManagementPage" ? " bg-red-500 " : "  ")} onClick={()=>setAdminPage("ClinicAndUsersManagementPage"  )}>Clinic And Users Management Page</button>*/}
                {/*<button className={"border " + (currentAdminPage === "ScanningStatisticsPage"       ? " bg-red-500 " : "  ")} onClick={()=>setAdminPage("ScanningStatisticsPage"        )}>Scanning Statistics Page</button>*/}
                {/*<button className={"border " + (currentAdminPage === "ScannerReportPage"            ? " bg-red-500 " : "  ")} onClick={()=>setAdminPage("ScannerReportPage"             )}>Scanner Report Page</button>*/}
                {/*<button className={"border " + (currentAdminPage === "TestReportPage"               ? " bg-red-500 " : "  ")} onClick={()=>setAdminPage("TestReportPage"                )}>Test Report Page</button>*/}
                {/*<button className={"border " + (currentAdminPage === "ErrorLogPage"                 ? " bg-red-500 " : "  ")} onClick={()=>setAdminPage("ErrorLogPage"                  )}>Error Log Page</button>*/}
                {/*<button className={"border " + (currentAdminPage === "SystemSetupPage"              ? " bg-red-500 " : "  ")} onClick={()=>setAdminPage("SystemSetupPage"               )}>System Setup Page</button>*/}
                {/*<button className={"border " + (currentAdminPage === "AuditTrailPage"               ? " bg-red-500 " : "  ")} onClick={()=>setAdminPage("AuditTrailPage"                )}>Audit Trail Page</button>*/}
            </div>
            <div>
                {
                    ViewAdminPage()
                }
            </div>
        </div>
    )
}
export default Admin
