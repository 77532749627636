import React from 'react'
import GBigLabel from "../GBigLabel";
import {useTranslation} from "react-i18next";
import metaLogo from "../../assets/meta.png"
const ContactUs=()=>{
    const { t, i18n } = useTranslation()
    return(
        <div className={"clinic-details-height-responsive pt-20"}>

            <GBigLabel className={"mt-20 flex justify-content-center text-2rem"}>{t("routing.contact-us")}</GBigLabel>
            <div className={"  ml-10 sm:ml-6.25rem mr-10 sm:mr-6.25rem mt-3.75rem mb-3.75rem shadow-gyni rounded-2xl"}>
                <div className={"lg:flex "}>
                    <div className={"lg:w-18.375rem sm:pr-2.813rem pt-3.125rem pb-3.125rem  pl-2 pr-2  sm:pl-2.813rem shadow-gyni rounded-2xl"}>
                        <div className={"flex  w-full h-full"}>

                                <div className={"flex m-auto space-x-5"}>
                                    <a href="https://www.linkedin.com/company/gyntools/"><img id='navigation-icon'  alt="small logo" src={require('../../assets/linkedin.png').default}></img></a>
                                    <a href="https://twitter.com/gyntools"><img id='navigation-icon'  alt="small logo" src={require('../../assets/twitter-icons-png-transparent-8.png').default}></img></a>
                                    <a href="https://www.facebook.com/GynTools"><img id='navigation-icon'  alt="small logo" src={metaLogo}></img></a>
                                </div>


                        </div>

                    </div>
                    <div className={"m-20 pb-20"}>
                        <div>














                            <div className='contact-us-label'>Office:</div>
                            <div className='contact-us-label'>GynTools</div>
                            <div className='contact-us-label'>Hartum 19, Har Hotzvim</div>
                            <div className='contact-us-label'>Jerusalem 9777518</div>
                            <div className='contact-us-label'>Israel</div>
                            <div className='contact-us-label'>email: <a className={"text-black"} href="mailto:support@gyntools.com">support@gyntools.com</a></div>
                            <a className={"text-black"} href={"http://www.gyntools.com"} >http://www.gyntools.com</a>
                        </div>



                    </div>
                </div>
            </div>
        </div>
    )
}
export default ContactUs
