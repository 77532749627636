import React, { Component } from 'react'
import '../../style/Admin/TrialProgressPerUserPage.css'
// import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
// import BootstrapTable from 'react-bootstrap-table-next';
class TrialProgressPerUserPage extends Component {

    constructor(props) {
        super(props);
        this.state={
            data:[]
        }
    }
    componentDidMount() {
    }
    render() {
        // const columns = [
        //     {
        //         dataField: 'id',
        //         text: 'id'
        //     }
        // ];
        return (
            <div>

                <label className="title">
                      Trial Progress Per User
                </label>
                <div>
                    {/*<BootstrapTable  keyField='id' data={ this.state.data } columns={ columns } />*/}
                </div>
            </div>
        );
    }
}

export default TrialProgressPerUserPage ;

