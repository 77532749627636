import React, {Component, useContext, useEffect, useState} from 'react'
import axios from "axios";
import Config from "../../services/Config";
import {BackendContext} from "../../machines/BackendMachine";
import {useActor} from "@xstate/react";
import GSmallLabel from "../../RemasterComponents/GSmallLabel";
import SF from "../../services/SEARCH_FILTER";
import PDF from "../../services/PDF";
import CSV from "../../services/CSV";

const AdminMessagesPage = () => {

    const BEContext = useContext(BackendContext)
    let BackendCurrent, BESend;
    [BackendCurrent, BESend] = useActor(BEContext);

    const [data, setData] = useState([])


    useEffect(()=>{
        GetAllMessages();
    },[])
    const GetAllMessages=()=>{
        axios.get(`${Config.globalUrl}:${Config.globalPort}/api/v1/webapp/general/${BackendCurrent.context.profile.userId}/getMassages`, {headers: {'Authorization': BackendCurrent.context.authToken}})
            .then(async (response) => {
                setData(response.data);
            })
            .catch(async (error) => {
                console.log(error);
            });
    }
    const RemoveMessage=(message)=>{
        axios.post(`${Config.globalUrl}:${Config.globalPort}/api/v1/webapp/general/${BackendCurrent.context.profile.userId}/deleteMassage`, {id:message._id},{headers: {'Authorization': BackendCurrent.context.authToken}})
            .then(async (response) => {
                GetAllMessages();
            })
            .catch(async (error) => {
                console.log(error);
            });
    }

    ///NEW MESSAGE
    const [messageType,setMessageType] = useState("Message")
    const [fromDate,setFromDate] = useState((new Date()))
    const [toDate,setToDate] = useState((new Date()))
    const [message,setMessage] = useState("")
    const SetMessageType=(evt)=>{
        setMessageType(evt.target.value)
    }
    const SetFromDate=(evt)=>{
        setFromDate(evt.target.value)
    }
    const SetToDate=(evt)=>{
        setToDate(evt.target.value)
    }
    const SetMessage=(evt)=>{
        setMessage(evt.target.value)
    }
    const AddMessage=(evt)=>{
        axios.post(`${Config.globalUrl}:${Config.globalPort}/api/v1/webapp/general/${BackendCurrent.context.profile.userId}/setMassages`, {
            "Message": message,
            "MessageType":messageType,
            "IsCritical":false,
            "StartDate":(new Date(fromDate).toUTCString()),
            "EndDate":(new Date(toDate).toUTCString())
        },{headers: {'Authorization': BackendCurrent.context.authToken}})
            .then(async (response) => {
                GetAllMessages();
            })
            .catch(async (error) => {
                console.log(error);
            });
    }
    return(
        <div className={"clinic-details-height-responsive pt-2"}>

            <div className={"  ml-10 sm:ml-6.25rem mr-10 sm:mr-6.25rem mb-3.75rem shadow-gyni rounded-2xl"}>
                <div className={"lg:flex"}>
                    <div className={"pl-20 pr-20 pt-5 pb-5 w-full"}>
                        <div className={"border"}>
                            <div className={"flex"}>
                                <table className={"w-full "}>
                                    <tr className={"bg-gray-200"}>
                                        <th className={"w-4/6"}>Message Type</th>
                                        <th className={"w-1/6"}>From Date</th>
                                        <th className={"w-1/6"}>To Date</th>
                                    </tr>
                                    <tr className={""}>
                                        <td>
                                            <select className={"w-full"} defaultValue={messageType} onChange={(evt)=>{SetMessageType(evt)}}>
                                                <option  value={"Message"}>Message</option>
                                                <option  value={"Promo"}>Promo</option>
                                                <option  value={"Update"}>Update</option>
                                            </select>
                                        </td>
                                        <td className={""}>
                                            <input max={toDate} className={""} onChange={(evt)=>SetFromDate(evt)} type={"datetime-local"}/>
                                        </td>
                                        <td className={""}>
                                            <input min={fromDate}  onChange={(evt)=>SetToDate(evt)} type={"datetime-local"}/>
                                        </td>
                                    </tr>
                                </table>

                            </div>
                            <div className={""}>
                                <table className={"w-full "}>
                                    <tr className={"bg-gray-200"}>
                                        <th className={""}>Message</th>
                                    </tr>
                                    <tr className={""}>
                                        <td className={"border"}>
                                            <input  contentEditable={true} placeholder={"..."} className={"w-full"} value={message} onChange={evt=>SetMessage(evt)}/>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                            <div className={"flex justify-content-end"}>
                                <div className={"flex  space-x-2 w-full justify-content-end"}>
                                    <div className={"flex space-x-2"}>
                                        <GSmallLabel>Search By ID</GSmallLabel>
                                        <input className={" border flex justify-content-center rounded-xl shadow-xl transform mb-2 pl-2"} onKeyUp={(e)=>SF.ApplySearch("messages-table",e.target.value)}/>
                                    </div>
                                    <div
                                        className={"cursor-pointer border w-20 flex justify-content-center rounded-xl shadow-xl transform hover:scale-125 mb-2"}
                                        onClick={() => PDF.ExportToPDF("Messages", '#messages-table', 'messages')}
                                    >PDF
                                    </div>
                                    <div
                                        className={"cursor-pointer border w-20 flex justify-content-center rounded-xl shadow-xl transform hover:scale-125 mb-2"}
                                        onClick={() => CSV.ExportToCSV("Messages", 'messages-table', 'messages' )}
                                    >CSV
                                    </div>
                                    <div className={"cursor-pointer border w-20 flex justify-content-center rounded-xl shadow-xl transform hover:scale-125 mb-2"} onClick={()=>AddMessage()}>Add</div>

                                </div>
                            </div>
                        </div>

                        <table id={"messages-table"} className={"table-auto text-sm w-full border"}>
                            <thead className={"border-bottom"}>
                            <tr>

                                <th className={"bg-gray-200 border-white border-2 pl-1"}>ID</th>
                                <th className={"bg-gray-200 border-white border-2 pl-1"}>Message</th>
                                <th className={"bg-gray-200 border-white border-2 pl-1"}>Type</th>
                                <th className={"bg-gray-200 border-white border-2 pl-1"}>Start Date</th>
                                <th className={"bg-gray-200 border-white border-2 pl-1"}>End Date</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                data.map(( message,ri) =>
                                    <>
                                        <tr key={"osk-"+ri} className={(ri % 2 !== 0 ? " bg-gray-100  ": " ") + " hover:bg-gray-300 cursor-pointer"} >
                                            <td className={"border-white border-2 pl-1"}>
                                                {
                                                    message._id
                                                }
                                            </td>
                                            <td className={"border-white border-2 pl-1"}>
                                                {
                                                    message.Message
                                                }
                                            </td>
                                            <td className={"border-white border-2 pl-1"}>
                                                {
                                                    message.MessageType
                                                }
                                            </td>
                                            <td className={"border-white border-2 pl-1"}>
                                                {
                                                    message.StartDate
                                                }
                                            </td>
                                            <td className={"border-white border-2 pl-1"}>
                                                {
                                                    message.EndDate
                                                }
                                            </td>
                                            <td className={"border-white border-2 pl-1"}>
                                                <button onClick={()=>RemoveMessage(message)}>Remove</button>
                                            </td>
                                        </tr>
                                    </>

                                )

                            }

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdminMessagesPage;

