import React from 'react';
import GSmallLabel from "./GSmallLabel";

const GTransparentButton = (props) => {
    let innerHTML = props.children;
    let commonStyle = "flex cursor-pointer"
    let fontStyle = " select-none  text-base text-#50C2BC text-center "
    let borderStyle = " rounded-2.688rem ";
    return (
        <div onClick={props.onClick} className={commonStyle+fontStyle+borderStyle+" "+props.className}>
            <GSmallLabel bold color={"text-#50C2BC text-sm"} className={"m-auto"}>
            {
                innerHTML
            }
            </GSmallLabel>
        </div>
    )
};
export default GTransparentButton;
