

import React, { Component } from 'react'
import '../style/clinicaldetails.css'
// import BootstrapTable from 'react-bootstrap-table-next';
import axios                     from 'axios';
import Config                    from '../services/Config';

import {FormGroup,FormControl} from 'react-bootstrap'
import MyVerticallyCenteredModal from "../components/MyVerticallyCenteredModal";
import Form from 'react-bootstrap/Form';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';

class ClinicalDetails extends Component {
    constructor(props){
        super(props);
        this.state = {

            hideClicnicalDetails:Config.hideSiteAdmin,
            UserRole:'Doctor',
            clinicLocation:{
                clinicAddress:'',
                clinicCountry:'',
                clinicCity:'',
                clinicStateProvince:'',
                clinicZip:'',
            },
            clinicID:'',
            clinicName:'',
            clinicPhone:'',
            contactEmail:'',
            selectedDoctor:null,
            users:[

            ],
            modalShow:false,
            clinicFormRef:false,
            removeModalShow:false,
            enableModalShow:false,
            selectedToRemove:null,
            isEdit:false,

        }
    }

    static roleFormatter(row,cell) {
        return (
            Object.keys(cell.Role).map((role,i)=>

                <div key={i} className='role-container'>
                    {cell.Role[i]}
                </div>
            )
        )
    }
    static editFormatter(cell) {
        return (
            <div className='outer-container'>
                <div className='edit-button' onClick={cell}>
                    EDIT
                </div>
            </div>
        );
    }
    static removeFormatter(row,cell) {
        return (
                cell.UserID !== Config.UserID ?
                    <div className='outer-container'>
                        {
                            cell.IsEnabled ?
                                <div className='edit-button' onClick={cell.Remove}>
                                    DISABLE
                                </div>
                            :
                                <div className='edit-button' onClick={cell.Enable}>
                                    ENABLE
                                </div>
                        }

                    </div>
                    :
                    <div disabled className='outer-container'>

                    </div>

        );
    }
    async openModal(val,email){

        if(val === 'edit-doctor'){
            var row = this.state.users.filter(x=>x.Email === email)[0];
            await this.setState({modalShow:true,selectedDoctor:row,isEdit:true,UserRole:row.Role});
            const form_ref = this.refs['userFormRef'];
            form_ref.elements['formEmail'].value     =row.Email;
            form_ref.elements['formFirstName'].value =row.FirstName;
            form_ref.elements['formLastName'].value  =row.LastName;
            // if(row.Role && row.Role.indexOf('SiteAdmin') > -1){
            //     form_ref.elements['my-form-check-inline'].checked = true;
            // }else{
            //     form_ref.elements['my-form-check-inline'].checked = false;
            // }
            // form_ref.elements['formIsEnabled'].value =row.IsEnabled;
        }else if(val === 'edit-clinic'){
            await this.setState({clinicModalShow:true});
            const form_ref = this.refs['clinicFormRef'];


            form_ref.elements['formclinicName'].value           =this.state.clinicName;
            form_ref.elements['formclinicAddress'].value        =this.state.clinicLocation.clinicAddress;
            // form_ref.elements['formclinicCountry'].value        =this.state.clinicLocation.clinicCountry;
            form_ref.elements['formclinicCity'].value           =this.state.clinicLocation.clinicCity;
            // form_ref.elements['formclinicStateProvince'].value  =this.state.clinicLocation.clinicStateProvince;
            form_ref.elements['formclinicZip'].value            =this.state.clinicLocation.clinicZip;
            form_ref.elements['formclinicPhone'].value          =this.state.clinicPhone;
            // form_ref.elements['formcontactEmail'].value         =this.state.contactEmail;

        }else if (val === 'remove-user'){
            await this.setState({removeModalShow:true,selectedToRemove:email});
        }
        else if (val === 'enable-user'){
            await this.setState({enableModalShow:true,selectedToRemove:email});
        }
        else if (val === 'add-user'){
            await this.setState({modalShow:true,isEdit:false});
            const form_ref = this.refs['userFormRef'];
            form_ref.elements['formEmail'].value     ="";
            form_ref.elements['formFirstName'].value ="";
            form_ref.elements['formLastName'].value  ="";
            // form_ref.elements['formRole'].value      ="";
        }
    }
    async handlemodalclose(){
        await this.setState({errorMessage:'',modalShow:false,clinicModalShow:false,removeModalShow:false,enableModalShow:false,selectedDoctor:null,selectedToRemove:null,UserRole:'Doctor'});
    }
    async handleClinicModalYes(){

        const form_ref = this.refs['clinicFormRef'];
        if (form_ref.checkValidity() === false) {

            Object.keys(form_ref.elements).forEach(ele=>{
                if(form_ref[ele].validity.valid === false){
                    form_ref[ele].parentNode.parentNode.classList.add('not-valid')
                }else{
                    if(form_ref[ele].parentNode.parentNode.classList.contains('not-valid') > -1){
                        form_ref[ele].parentNode.parentNode.classList.remove('not-valid')
                    };
                }
            })
            return;
        }
        const edit_clinic = {
            ClinicID:this.state.clinicID,
            Location:{
                Address:form_ref.elements['formclinicAddress'].value,
                Country:this.state.clinicLocation.clinicCountry,
                State:this.state.clinicLocation.clinicStateProvince,
                City:form_ref.elements['formclinicCity'].value
            },
            ClinicName:form_ref.elements['formclinicName'].value,
            ClinicZip:form_ref.elements['formclinicZip'].value,
            ClinicPhone:form_ref.elements['formclinicPhone'].value,
        }
        this.editClinic(edit_clinic);

    }
    async handleEnableUser(){
      this.enableUser(this.state.selectedToRemove)
    }
    async handleRemoveUser(){
      this.removeUser(this.state.selectedToRemove);
    }
    async handlemodalyes(){
        const form_ref = this.refs['userFormRef'];

        if (form_ref.checkValidity() === false) {

            Object.keys(form_ref.elements).forEach(ele=>{
                if(form_ref[ele].validity.valid === false){
                    form_ref[ele].parentNode.parentNode.classList.add('not-valid')
                }else{
                    if(form_ref[ele].parentNode.parentNode.classList.contains('not-valid') > -1){
                        form_ref[ele].parentNode.parentNode.classList.remove('not-valid')
                    };
                }
            })
            return;
        }

        if(this.state.selectedDoctor){
            const edit_user = this.state.selectedDoctor;
            edit_user.ClinicID  = this.state.clinicID;
            edit_user.Email     = form_ref.elements['formEmail'].value.trim();
            edit_user.FirstName = form_ref.elements['formFirstName'].value;
            edit_user.LastName  = form_ref.elements['formLastName'].value;
            // if(form_ref.elements['my-form-check-inline'].checked){
            //     edit_user.Role = ['Doctor','SiteAdmin']
            // }else{
                edit_user.Role = ['Doctor']
            // }
            this.editUser(edit_user);

        }else{
            const new_user = {
                ClinicID:   this.state.clinicID,

                Email:      form_ref.elements['formEmail'].value.trim(),
                FirstName:  form_ref.elements['formFirstName'].value+' ',
                LastName:   form_ref.elements['formLastName'].value,
                // Role:       form_ref.elements['my-form-check-inline'].checked ?   ['Doctor','SiteAdmin'] :   ['Doctor'],
                Role:       ['Doctor'],
                IsEnabled:  true,
            };

            this.addNewUser(new_user);
        }
    }

    async getClinicInfo(){
        await axios.get(`${Config.globalUrl}:${Config.globalPort}/api/v1/webapp/doctors/${Config.UserID}/getMyClinicInfo/`,Config.token)
        .then(async (response) => {
            await this.setState({
                // contactEmail:response.data.Email,
                clinicID:response.data.ClinicID,
                clinicName:response.data.ClinicName,
                clinicPhone:response.data.ClinicPhone,
                clinicLocation:{
                    clinicAddress:response.data.Location.Address ? response.data.Location.Address : '',
                    clinicCountry:response.data.Location.Country ? response.data.Location.Country: '',
                    clinicCity:response.data.Location.City ? response.data.Location.City : '',
                    clinicStateProvince:response.data.Location.State ? response.data.Location.State : '',
                    clinicZip:response.data.ClinicZip ? response.data.ClinicZip : '',
                }
            })
            // console.log(response);
        })
        .catch( async (error) => {
            console.log(error);
        });
    }
    // async updateClinicInfo(){

    // }
    async getMyClinicDoctors(){
        await axios.get(`${Config.globalUrl}:${Config.globalPort}/api/v1/webapp/siteadmins/${Config.UserID}/getMyClinicDoctors/`,Config.token)
        .then(async (response) => {

            response.data.forEach(_user => {
                _user.Edit = this.openModal.bind(this,'edit-doctor',_user.Email);
                _user.Remove = this.openModal.bind(this,'remove-user',_user.Email);
                _user.Enable = this.openModal.bind(this,'enable-user',_user.Email)
                _user.Role = _user.Role ? _user.Role : ['Doctor'];
            });
            await this.setState({
                users:response.data
            })

        })
        .catch( async (error) => {
            console.log(error);
        });
    }
    async setClinicDoctorProfile(){

    }

    async enableUser(user){

        user = this.state.users.find(x=>x.Email === user)
        user.IsEnabled = !user.IsEnabled;
        this.editUser(user);
        await this.setState({selectedToRemove:null,enableModalShow:false})
    }
    async removeUser(user){
        user = this.state.users.find(x=>x.Email === user)
        user.IsEnabled = !user.IsEnabled;
        this.editUser(user);
        await this.setState({selectedToRemove:null,removeModalShow:false})
    }
    async editClinic(body){

        await axios.post(`${Config.globalUrl}:${Config.globalPort}/api/v1/webapp/siteadmins/${Config.UserID}/updateMyClinicInfo/`,body,Config.token)
        .then(async (response) => {
            await this.setState({clinicModalShow:false});
            this.getClinicInfo();
        })
        .catch( async (error) => {
            console.log(error);
        });


    }
    async editUser(body){
        await this.setState({wrongInput:false,errorMessage:''});
        await axios.post(`${Config.globalUrl}:${Config.globalPort}/api/v1/webapp/siteadmins/${Config.UserID}/updateMyClinicDoctorsProfile/`,body,Config.token)
        .then(async (response) => {
            await this.setState({selectedDoctor:null,modalShow:false,UserRole:'Doctor'});

        })
        .catch( async (error) => {
            console.log(error);
            var _dispErr = '';
            if(error && error.response && error.response.data && error.response.data.error){
                if(typeof(error.response.data.error) === 'string'){
                    _dispErr = error.response.data.error;
                }else{
                    if(error.response.data.error.message){
                        if(typeof(error.response.data.error.message)==='string'){
                            _dispErr = error.response.data.error.message;
                        }
                    }
                }
            }
            this.setState({wrongInput:true,errorMessage:_dispErr});
        });
        this.getMyClinicDoctors();
    }
    async addNewUser(body){


        await this.setState({wrongInput:false,errorMessage:''});
        await axios.post(`${Config.globalUrl}:${Config.globalPort}/api/v1/webapp/siteadmins/${Config.UserID}/addDoctorToMyClinic/`,body,Config.token)
        .then(async (response) => {
            await this.setState({clinicModalShow:false});

            this.setState({modalShow:false,UserRole:'Doctor',wrongInput:false,errorMessage:''});
        })
        .catch( async (error) => {
            console.log(error);
            this.setState({wrongInput:true,errorMessage:'Email is already registered in the system'});
        });
        this.getMyClinicDoctors();
    }
    selectCountry (val) {
        var loc = this.state.clinicLocation;
        loc.clinicCountry = val;
        this.setState({ clinicLocation: loc });
      }
    selectRegion (val) {
        var loc = this.state.clinicLocation;
        loc.clinicStateProvince = val;
        this.setState({ clinicLocation: loc });
    }
    componentDidMount() {
        this.getClinicInfo();
        this.getMyClinicDoctors();
    }
    render() {
        // const columns = [
        //
        //         {
        //             dataField: 'FirstName',
        //             text: 'First Name',
        //             headerStyle:{
        //                 color:'#B4B4B4',
        //                 fontWeight:100,
        //                 fontSize:'14px',
        //                 border: '2px solid transparent',
        //                 width:'15%'
        //             }
        //         },
        //         {
        //             dataField: 'LastName',
        //             text: 'Last Name',
        //             headerStyle:{
        //                 color:'#B4B4B4',
        //                 fontWeight:100,
        //                 fontSize:'14px',
        //                 border: '2px solid transparent',
        //                 width:'15%'
        //             }
        //         },
        //         {
        //             dataField: 'Email',
        //             text: 'Email',
        //             headerStyle:{
        //                 color:'#B4B4B4',
        //                 fontWeight:100,
        //                 fontSize:'12px',
        //                 border: '2px solid transparent',
        //                 width:'40%'
        //             }
        //         }
        //         ,
        //         {
        //             dataField: 'Role',
        //             text: '',
        //             headerStyle:{
        //                 border: '2px solid transparent',
        //                 width:'10%'
        //             },
        //             formatter:ClinicalDetails.roleFormatter
        //         },
        //         {
        //             dataField: 'Edit',
        //             text: '',
        //             headerStyle:{
        //                 border: '2px solid transparent',
        //                 width:'5%'
        //             },
        //             formatter:ClinicalDetails.editFormatter
        //         },
        //         {
        //             dataField: 'Remove',
        //             text: '',
        //             headerStyle:{
        //                 border: '2px solid transparent',
        //                 width:'10%'
        //             },
        //             formatter:ClinicalDetails.removeFormatter
        //         },
        // ];
        // const rowStyle = { border: '2px solid transparent'};

        return (
            <div>
                <MyVerticallyCenteredModal id='clinical-modal'
                    show={this.state.clinicModalShow}
                    headertext=''
                    bodytext={


                        <Form  onSubmit = {(event)=>{ event.preventDefault();}}   className = 'clinical-form' ref={'clinicFormRef'}>
                            <div className="modal-title">
                                Edit Clinic
                            </div>
                            {/* <Form.Group controlId="formcontactEmail">
                            <div className='input-with-icon-cd'>
                                    <span className = 'my-icon'>
                                        <img src = {require('../assets/letter.svg').default} alt="lock"/>
                                    </span>
                                <Form.Control required type="email" placeholder="Enter email" />
                                </div>
                            </Form.Group> */}

                            <Form.Group controlId="formclinicPhone">
                            <div className='input-with-icon-cd'>
                                    <span className = 'my-icon'>
                                        <img src = {require('../assets/phn.svg').default} alt="lock"/>
                                    </span>
                                <Form.Control type="text" placeholder="Enter clinic phone" />
                                </div>
                            </Form.Group>
                            <Form.Group controlId="formclinicName">
                            <div className='input-with-icon-cd'>
                                    <span className = 'my-icon'>
                                        <img src = {require('../assets/home.svg').default} alt="lock"/>
                                    </span>
                                <Form.Control required type="text" placeholder="Enter clinic name" />
                                </div>
                            </Form.Group>


                            <Form.Group controlId="formclinicCountry">
                                <div className='input-with-icon-cd'>
                                    <span className = 'my-icon'>
                                        <img src = {require('../assets/loc.svg').default} alt="lock"/>
                                    </span>
                                    <CountryDropdown
                                    ref = 'formclinicCountry'
                                    className = 'region-dropdown'
                                    value={this.state.clinicLocation.clinicCountry}
                                    onChange={(val) => this.selectCountry(val)} />
                                </div>
                            </Form.Group>
                            <Form.Group controlId="formclinicStateProvince">
                                <div className='input-with-icon-cd'>
                                    <span className = 'my-icon'>
                                        <img src = {require('../assets/loc.svg').default} alt="lock"/>
                                    </span>
                                    {
                                        this.state.clinicLocation.clinicCountry ?
                                        this.state.clinicLocation.clinicCountry === 'United States' ?
                                            <RegionDropdown
                                            ref = 'region-ref'
                                            defaultOptionLabel = 'State'
                                            className = 'region-dropdown'
                                            country={this.state.clinicLocation.clinicCountry}
                                            value={this.state.clinicLocation.clinicStateProvince}
                                            onChange={(val) => this.selectRegion(val)}
                                        />
                                        :
                                        <RegionDropdown
                                            ref = 'region-ref'
                                            defaultOptionLabel = 'Region/Province'
                                            className = 'region-dropdown'
                                            country={this.state.clinicLocation.clinicCountry}
                                            value={this.state.clinicLocation.clinicStateProvince}
                                            onChange={(val) => this.selectRegion(val)}
                                        />
                                        :
                                        <div></div>
                                    }
                                </div>
                            </Form.Group>
                            <Form.Group controlId="formclinicAddress">
                            <div className='input-with-icon-cd'>
                                    <span className = 'my-icon'>
                                        <img src = {require('../assets/loc.svg').default} alt="lock"/>
                                    </span>
                                <Form.Control type="text" placeholder="Enter address" />
                                </div>
                            </Form.Group>
                            <Form.Group controlId="formclinicCity">
                            <div className='input-with-icon-cd'>
                                    <span className = 'my-icon'>
                                        <img src = {require('../assets/loc.svg').default} alt="lock"/>
                                    </span>
                                <Form.Control type="text" placeholder="Enter city" />
                                </div>
                            </Form.Group>
                            <Form.Group controlId="formclinicZip">
                            <div className='input-with-icon-cd'>
                                    <span className = 'my-icon'>
                                        <img src = {require('../assets/loc.svg').default} alt="lock"/>
                                    </span>
                                <Form.Control type="text" placeholder="Enter zip" />
                                </div>
                            </Form.Group>
                        </Form>
                    }

                    handlemodalclose={this.handlemodalclose.bind(this)}
                    closetext='Close'
                    handlemodalyes={this.handleClinicModalYes.bind(this)}
                    yestext='Save'
                    handlemodalno={this.handlemodalclose.bind(this)}
                    notext='Cancel'
                />
                <MyVerticallyCenteredModal id='clinical-modal'
                    show={this.state.removeModalShow}
                    headertext={'Remove user'}
                    bodytext={
                        `Are you sure you want to disable ${this.state.selectedToRemove? (this.state.users.filter(x=>x.Email === this.state.selectedToRemove)[0].FirstName):'this user'}?`
                    }

                    handlemodalclose={this.handlemodalclose.bind(this)}
                    closetext='Close'
                    handlemodalyes={this.handleRemoveUser.bind(this)}
                    yestext='Yes , i`m sure'
                    handlemodalno={this.handlemodalclose.bind(this)}
                    notext='Cancel'
                />
                <MyVerticallyCenteredModal id='clinical-modal'
                    show={this.state.enableModalShow}
                    headertext={'Enable user'}
                    bodytext={
                        `Are you sure you want to enable ${this.state.selectedToRemove? (this.state.users.filter(x=>x.Email === this.state.selectedToRemove)[0].FirstName):'this user'}?`
                    }

                    handlemodalclose={this.handlemodalclose.bind(this)}
                    closetext='Close'
                    handlemodalyes={this.handleEnableUser.bind(this)}
                    yestext='Yes , i`m sure'
                    handlemodalno={this.handlemodalclose.bind(this)}
                    notext='Cancel'
                />

                <MyVerticallyCenteredModal id='clinical-modal'
                    show={this.state.modalShow}
                    headertext=''
                    bodytext={
                        <Form onSubmit = {(event)=>{ event.preventDefault();}}  className = 'clinical-form' ref={'userFormRef'}>
                            {
                                this.state.isEdit
                                ?
                                <div className="modal-title">
                                    Edit User
                                </div>
                                :
                                <div className="modal-title">
                                    Add User
                                </div>
                            }


                            <FormGroup controlId="formEmail">
                                <div className={`input-with-icon-cd ${this.state.wrongInput ? 'wrong':''}`}>
                                    <span className = 'my-icon'>
                                        <img src = {require('../assets/letter.svg').default} alt="lock"/>
                                    </span>
                                    <FormControl required type="email" placeholder="Enter email" />
                                </div>
                            </FormGroup>

                            <Form.Group controlId="formFirstName">
                                <div className='input-with-icon-cd'>
                                    <span className = 'my-icon'>
                                        <img src = {require('../assets/actor.svg').default} alt="lock"/>
                                    </span>
                                    <Form.Control required type="text" placeholder="Enter first name" />
                                </div>

                            </Form.Group>
                            <Form.Group controlId="formLastName">
                                {/* <Form.Label>Last Name</Form.Label> */}
                                <div className='input-with-icon-cd'>
                                    <span className = 'my-icon'>
                                        <img src = {require('../assets/actor.svg').default} alt="lock"/>
                                    </span>
                                    <Form.Control required type="text" placeholder="Enter last name" />
                                </div>

                            </Form.Group>
                            <Form.Group controlId="formRole">
                                {/* <Form.Label>Role</Form.Label> */}
                                <div className='input-with-icon-cd special'>
                                    {/* <span className = 'my-icon'>
                                        <img src = {require('../assets/actor.svg').default} alt="lock"/>
                                    </span> */}
                                    {/* <Form.Label>Site Admin</Form.Label>
                                    <Form.Check id='my-form-check-inline' inline type="checkbox" value={this.state.isAdminSelected} label="" /> */}
                                    {/* <select
                                        value = {this.state.UserRole}
                                        className = 'region-dropdown'
                                        onChange = {(val)=>{
                                            this.setState({UserRole:val.target.value
                                            })}}
                                    >
                                        <option value='Doctor'>Doctor</option>
                                        <option value='Admin'>Admin</option>
                                    </select> */}
                                    {/* <Form.Control type="text" placeholder="Role" /> */}
                                </div>

                            </Form.Group>
                            {
                                this.state.errorMessage !== '' ?
                                <div>
                                    {this.state.errorMessage}
                                </div>
                                :
                                <div></div>
                            }

                        </Form>
                    }

                    handlemodalclose={this.handlemodalclose.bind(this)}
                    closetext='Close'
                    handlemodalyes={this.handlemodalyes.bind(this)}
                    yestext='Save'
                    handlemodalno={this.handlemodalclose.bind(this)}
                    notext='Cancel'
                />
                <div>
                    <label className="title">
                        Clinic Details
                    </label>
                </div>
                <div>
                    <label className="title-small">
                        Clinic info
                    </label>
                </div>
                <div className='clinic-info-grid'>
                    <label className='clinic-info-grid-item'>{this.state.clinicName}</label>
                    {
                    this.state.clinicLocation ?
                        <div className={'clinic-location-container'}>
                        {
                            Object.keys(this.state.clinicLocation).map((loc,i)=>
                                this.state.clinicLocation[loc].length > 0 ?

                                    i !== 4 ?
                                    this.state.clinicLocation[loc]+', '
                                    :
                                    this.state.clinicLocation[loc]+' '
                                    :
                                    ''
                                )
                        }
                        </div>
                        :
                        <div></div>
                    }
                    <label className='clinic-info-grid-item'>{this.state.clinicPhone}</label>
                    <label className='clinic-info-grid-item'>{this.state.contactEmail}</label>
                    {
                        this.state.hideClicnicalDetails || Config.role.indexOf('SiteAdmin') === -1 ?
                        <div>Active</div>
                        :
                        <div className='edit-button' onClick={this.openModal.bind(this,'edit-clinic',null)}>EDIT</div>
                    }

                </div>

                {
                    this.state.hideClicnicalDetails || Config.role.indexOf('SiteAdmin') === -1 ?
                    <div></div>
                    :
                    <div>
                        <div className='users-table-header'>
                        <div></div>
                        <div>USERS</div>
                        <div className='edit-button' onClick={this.openModal.bind(this,'add-user',null)}>ADD NEW USER</div>
                        </div>
                        <div>
                            {/*<BootstrapTable rowStyle={ rowStyle } classes="users-table" keyField='FirstName' data={ this.state.users } columns={ columns } />*/}
                        </div>
                    </div>
                }

            </div>
        );
    }
}

export default ClinicalDetails ;

