import React, {useContext, useState} from 'react';
import GSmallLabel from "./GSmallLabel";
import {useTranslation} from "react-i18next";

const GToggleButton = (props) => {
    const { t, i18n } = useTranslation()
    const [isOn,setOn] = useState(props.initial ? props.initial : false)
    return (
        <div className={"flex  mt-auto mb-auto space-x-1rem " + props.className}>
            <div className={"mb-auto mt-auto select-none w-3.063rem h-1.25rem cursor-pointer rounded-full"} onClick={()=>{
                props.onClick();
                setOn(!isOn)
            }}>
                <div className={"position-absolute  pt-0.063rem pb-0.063rem"}>
                    <div className={"pl-0.063rem position-absolute w-2.563rem h-1.063rem  rounded-full "+ (isOn ? " bg-#1396B4 ":" border")}>
                        {
                            isOn
                                ?
                                <GSmallLabel bold className={"uppercase  p-0.063rem pl-1 text-white text-0.625rem"}>
                                    {t("general.on")}
                                </GSmallLabel>
                                : null
                            // <GSmallLabel className={"text-white text-0.625rem flex justify-content-end"}>
                            //     OFF
                            // </GSmallLabel>
                        }
                    </div>

                </div>
                <div className={"position-relative h-1.25rem w-1.25rem bg-white rounded-full shadow-toggleShadow" +(isOn?" left-6  ":" ")}/>
            </div>
            <div >
                <GSmallLabel className={props.textColor}>
                    {
                        props.text
                    }
                </GSmallLabel>
            </div>
        </div>
    )
};
export default GToggleButton;
