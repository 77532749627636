import React from "react"

const GResponsiveTest =()=>{
    return(
        <div className={"absolute w-full"}>
            <div className={"flex m-auto"}>
                <div className={"bg-transparent w-full border-2 p-1 bg-white text-red-500"}>MIN</div>
                <div className={"bg-transparent w-full border-2 p-1 bg-white  sm:text-red-500 "}> >SM </div>
                <div className={"bg-transparent w-full border-2 p-1 bg-white  md:text-red-500 "}> >MD </div>
                <div className={"bg-transparent w-full border-2 p-1 bg-white  lg:text-red-500 "}> >LG </div>
                <div className={"bg-transparent w-full border-2 p-1 bg-white  xl:text-red-500 "}> >XL </div>
                <div className={"bg-transparent w-full border-2 p-1 bg-white 2xl:text-red-500 "}> >2XL </div>
            </div>

            {/**/}
        </div>
    )
}
export default GResponsiveTest;
