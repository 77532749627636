import React, {useEffect, useRef, useState} from "react"

const GPartlyTextInput =(props)=>{

    const textInput0 = useRef(null);
    const textInput1 = useRef(null);
    const textInput2 = useRef(null);
    const textInput3 = useRef(null);

    const [focusedInput,setFocusedInput] = useState(0);
    const [seg1,setSeg1] = useState("")
    const [seg2,setSeg2] = useState("")
    const [seg3,setSeg3] = useState("")
    const [seg4,setSeg4] = useState("")

    const setTextValue =(focused,cb,val)=>{
        cb(val);
        setFocusedInput(focused);
    }
    useEffect(()=>{
        props.handleChange(seg1+seg2+seg3+seg4)
        switch (focusedInput){
            case 0:
                if(seg1.length === 4)
                    textInput1.current.focus();
                break;
            case 1:
                if(seg2.length === 0)
                    textInput0.current.focus();
                if(seg2.length === 4)
                    textInput2.current.focus();
                break;
            case 2:
                if(seg3.length === 0)
                    textInput1.current.focus();
                if(seg3.length === 4)
                    textInput3.current.focus();
                break;
            case 3:
                if(seg4.length === 0)
                    textInput2.current.focus();
                break;
        }
    },[seg1,seg2,seg3,seg4])
    return(
            <div className={" flex sm:space-x-0.625rem sm:ml-10 sm:mr-10"}>
                <input ref={textInput0} onChange={(e)=>setTextValue(0,setSeg1,e.target.value)} placeholder={"XXXX"} maxLength={4} className={"text-center border-#1396B4 border rounded-md m-auto bg-#1396B408  sm:w-5.438rem sm:h-3.438rem w-1/4"}  type={"text"}/>
                <input ref={textInput1} onChange={(e)=>setTextValue(1,setSeg2,e.target.value)} placeholder={"XXXX"} maxLength={4} className={"text-center border-#1396B4 border rounded-md m-auto bg-#1396B408  sm:w-5.438rem sm:h-3.438rem w-1/4"}  type={"text"}/>
                <input ref={textInput2} onChange={(e)=>setTextValue(2,setSeg3,e.target.value)} placeholder={"XXXX"} maxLength={4} className={"text-center border-#1396B4 border rounded-md m-auto bg-#1396B408  sm:w-5.438rem sm:h-3.438rem w-1/4"}  type={"text"}/>
                <input ref={textInput3} onChange={(e)=>setTextValue(3,setSeg4,e.target.value)} placeholder={"XXXX"} maxLength={4} className={"text-center border-#1396B4 border rounded-md m-auto bg-#1396B408  sm:w-5.438rem sm:h-3.438rem w-1/4"}  type={"text"}/>
            </div>
    )
}
export default GPartlyTextInput;
