import React, {useContext, useState} from 'react';
import CloseButton from "../assets/close_svg.svg"
import DeviceIssueIcon from "../assets/device_issue.png";
import GSmallLabel from "./GSmallLabel";
import GBigLabel from "./GBigLabel";
import GButton from "./GButton";
import GTransparentButton from "./GTransparentButton";
import "./remasteredStyles/g-modal.css"
const GModal = (props) => {

    const [isModalOpen,setModalOpen] = useState(true);
    return (
        <div className={""}>
            {
                isModalOpen
                    ?
                    <div style={{zIndex:3}} className={" z-10 h-full w-full fixed top-0 g-modal-overlay w-full   bg-#00163A backdrop-filter backdrop-blur flex "}>
                        <div  className={" g-modal-body  m-auto border-0 sm:rounded-3xl pr-1.875rem pl-1.875rem pt-1.953rem sm:pb-2.063rem " +(!props.noheadercolor ? " bg-white ":"")+ props.className}>
                            {/*HEADER*/}
                            {
                                props.hideClose
                                    ?
                                    null
                                    :
                                    <div className={""}>
                                        <div className={"flex justify-content-end"}>
                                            <div onClick={() => {
                                                if (props.handleClose) {
                                                    props.handleClose()
                                                } else {
                                                    setModalOpen(false)
                                                }
                                            }}
                                                 className={" flex bg-#1396B40F rounded-full h-1.875rem w-1.875rem cursor-pointer"}>
                                                <div className={"m-auto "}>
                                                    <img src={CloseButton} alt="x"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                            }
                            {/*BODY*/}
                            <div className={""}>
                                {
                                    props.children
                                }
                                {
                                    props.hideClose
                                    ?
                                        props.hideCloseButton
                                        ?
                                            null
                                        :
                                        <div className={props.closeButtonStyle}>
                                            <GButton onClick={() => {
                                                if (props.handleClose) {
                                                    props.handleClose()
                                                } else {
                                                    setModalOpen(false)
                                                }
                                            }} className={"h-3.125rem"}>
                                                {
                                                    props.closeButtonText
                                                }
                                            </GButton>
                                        </div>
                                    :
                                        null
                                }
                            </div>
                        </div>
                    </div>
                    :
                    null
            }
        </div>
    )
};
export default GModal;

