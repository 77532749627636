import React, { Component } from 'react'
 
class NotFound extends Component {
    componentDidMount() {
    }
    render() {
        return (
            <div>404</div>
        );
    }
}
 
export default NotFound ;