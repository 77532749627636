import React, {Component, useContext, useEffect, useState} from 'react'
import '../../style/Admin/OverallScannerViewPage.css'
import JSZip from 'jszip'
import axios from 'axios';
import Config from '../../services/Config';
import "react-datepicker/dist/react-datepicker.css";
import {BackendContext} from "../../machines/BackendMachine";
import {useActor} from "@xstate/react";
import moment from "moment";
import http from "http";
import PDF from '../../services/PDF'
import CSV from '../../services/CSV'
import GSmallLabel from "../../RemasterComponents/GSmallLabel";
import SF from "../../services/SEARCH_FILTER";
import i18n from "../../i18n";
import Validators from "../../services/Validators";


const UsersManagement = () => {

    const BEContext = useContext(BackendContext)
    let BackendCurrent;
    [BackendCurrent,] = useActor(BEContext);

    const pageCount = 8;
    const [usersView, SetUsersView] = useState(null)
    const [pagesCount, SetPagesCount] = useState([1])
    const [currentPage, SetCurrentPage] = useState(1);
    const [data, setData] = useState([])
    const [users, setUsers] = useState([])
    const [editUserWindow, SetEditUserWindow] = useState(false)
    const [selectedUser, SetSelectedUser] = useState(null);
    const [selectedClinic, SetSelectedClinic] = useState(null);
    const [searchFilter,SetSearchFilter] = useState(null)
    useEffect(() => {
        getClinicsData();
    }, [])

    const getClinicsData = () => {

        //TODO: change url to get clinics
        axios.get(`${Config.globalUrl}:${Config.globalPort}/api/v1/webapp/admins/${BackendCurrent.context.profile.userId}/getClinics`, {headers: {'Authorization': BackendCurrent.context.authToken}})
            .then(async (response) => {
                console.log('response ' + (new Date()));
                setData(response.data);
            })
            .catch(async (error) => {
                console.log(error);
            });
    }

    const getClinicUsers = (ClinicID) => {

        axios.get(`${Config.globalUrl}:${Config.globalPort}/api/v1/webapp/admins/${BackendCurrent.context.profile.userId}/getDoctorsByClinic/${ClinicID}`, {headers: {'Authorization': BackendCurrent.context.authToken}})
            .then(async (response) => {
                SetUsersView(true)
                setUsers(response.data)
            })
            .catch(async (error) => {
                console.log(error);
            });
    }
    const updateUser = (user, Role, isNewUser, password) => {

        let isEmailValid = Validators.validateEmail(user.Email)
        let isValidPassword = Validators.validatePasswordNoMatch(password)

        let roles = [];
        if (Role[0] === true) {
            roles.push("SiteAdmin")
        }
        if (Role[1] === true) {
            roles.push("Doctor")
        }
        let url = isNewUser
            ?
            `${Config.globalUrl}:${Config.globalPort}/api/v1/webapp/doctors/${BackendCurrent.context.profile.userId}/signup/`
            :
            `${Config.globalUrl}:${Config.globalPort}/api/v1/webapp/general/${BackendCurrent.context.profile.userId}/updateDoctor/${user.UserID}`


        let body = {
            Email: user.Email,
            FirstName: user.FirstName,
            LastName: user.LastName,
            IsEnabled: user.IsEnabled,
        }
        if (!isNewUser) {
            body.UserID = user.UserID
            body.Role = roles
        } else {
            body.Role = ["Doctor"]
            body.Password = password
            body.ClinicID = selectedClinic.ClinicID
        }
        if(isEmailValid){
            if(isValidPassword.isError && isNewUser){
                alert(isValidPassword.validationError)
            }else{
                axios.post(url, body, {headers: {'Authorization': BackendCurrent.context.authToken}})
                    .then(async (res) => {
                        backToUsers();
                        setTimeout(() => {
                            getClinicUsers(isNewUser ? selectedClinic.ClinicID : user.ClinicID)
                        }, 500)
                    })
                    .catch(async (error) => {
                        alert(error);
                        console.log(error);
                    });
            }
        }else{
            alert("Not valid Email")
        }

    }
    const editUser = (user) => {
        SetSelectedUser(user)
        SetEditUserWindow(true)
    }
    const backToUsers = () => {
        SetSelectedUser(null)
        SetEditUserWindow(false)
    }
    const backToClinics = () => {
        SetSelectedClinic(null)
        SetCurrentPage(1)
        SetUsersView(false)
    }
    const inputEmail=(_user,e)=>{
        _user.Email = e.target.value
    }
    const UserEditContainer = (user) => {
        let isNewUser = !user;
        let _user = user ? user : {
            Email: "",
            FirstName: "",
            LastName: "",
            IsEnabled: false,
            Role: [],
        }
        let isAdmin = _user.Role.filter(x => x === "SiteAdmin").length > 0;
        let isDoctor = _user.Role.filter(x => x === "Doctor").length > 0;
        let password = ""
        return <div className={"p-20 border space-x-2 "}>
            <div className={"space-y-2"}>
                <GSmallLabel>Email</GSmallLabel>
                <input max={50} disabled={!isNewUser} className={"border pl-2 w-full"} defaultValue={_user.Email}
                       placeholder={"Email"} onChange={(e)=>inputEmail(_user,e)}/>
                <GSmallLabel>First Name</GSmallLabel>
                <input max={50} className={"border pl-2 w-full"} defaultValue={_user.FirstName} placeholder={"First Name"}
                       onChange={(e) => _user.FirstName = e.target.value}/>
                <GSmallLabel>Last Name</GSmallLabel>
                <input max={50} className={"border pl-2 w-full"} defaultValue={_user.LastName} placeholder={"Last Name"}
                       onChange={(e) => _user.LastName = e.target.value}/>
                <GSmallLabel>Is Enabled</GSmallLabel>
                <select className={"border pl-2 w-full"} defaultValue={_user.IsEnabled}
                        onChange={(e) => _user.IsEnabled = e.target.value}>
                    <option value={false}>No</option>
                    <option value={true}>Yes</option>
                </select>
                {
                    isNewUser
                        ?
                        <div>
                            <GSmallLabel>Password</GSmallLabel>
                            <input max={50} type={"password"} className={"border pl-2 w-full"} placeholder={""}
                                   onChange={(e) => password = e.target.value}/>
                        </div>

                        :
                        null
                }

                <div className={"w-full flex justify-content-end"}>
                    <div
                        className={"cursor-pointer border w-20 flex justify-content-center rounded-xl shadow-xl transform hover:scale-125 mb-2"}
                        onClick={() => updateUser(_user, [isAdmin, isDoctor], isNewUser, password)}>Save
                    </div>
                </div>

            </div>
        </div>
    }



    return (

        <div className={"clinic-details-height-responsive pt-2 mt-4"}>


            <div className={"  ml-10 sm:ml-6.25rem mr-10 sm:mr-6.25rem mb-3.75rem shadow-gyni rounded-2xl"}>
                <div className={"lg:flex"}>

                    <div className={"pl-20 pr-20 pt-5 pb-5 w-full"}>
                        {
                            usersView
                                ?
                                editUserWindow
                                    ?
                                    <div>
                                        <div>

                                            <div
                                                className={"cursor-pointer border w-20 flex justify-content-center rounded-xl shadow-xl transform hover:scale-125 mb-2"}
                                                onClick={() => backToUsers()}>Back
                                            </div>
                                        </div>

                                        {
                                            UserEditContainer(selectedUser)
                                        }
                                    </div>
                                    :
                                    <div>
                                        <div>
                                            <div className={"flex w-full"}>
                                                <div className={"flex space-x-2 w-1/2 "}>
                                                    <div
                                                        className={"cursor-pointer border w-20 flex justify-content-center rounded-xl shadow-xl transform hover:scale-125 mb-2"}
                                                        onClick={() => backToClinics()}>back
                                                    </div>
                                                    <div
                                                        className={"cursor-pointer border w-20 flex justify-content-center rounded-xl shadow-xl transform hover:scale-125 mb-2"}
                                                        onClick={() => editUser(null)}>Add
                                                    </div>
                                                </div>
                                                <div className={"flex  space-x-2 w-full justify-content-end"}>
                                                    <div className={"flex space-x-2"}>
                                                        <GSmallLabel>Search By ID</GSmallLabel>
                                                        <input max={50} className={" border flex justify-content-center rounded-xl shadow-xl transform mb-2 pl-2"} onKeyUp={(e)=>SF.ApplySearch("users-table",e.target.value)}/>
                                                    </div>
                                                    <div
                                                        className={"cursor-pointer border w-20 flex justify-content-center rounded-xl shadow-xl transform hover:scale-125 mb-2"}
                                                        onClick={() => PDF.ExportToPDF("Users", '#users-table', 'users')}
                                                    >PDF
                                                    </div>
                                                    <div
                                                        className={"cursor-pointer border w-20 flex justify-content-center rounded-xl shadow-xl transform hover:scale-125 mb-2"}
                                                        onClick={() => CSV.ExportToCSV("Users", 'users-table', 'users' )}
                                                    >CSV
                                                    </div>
                                                </div>
                                            </div>

                                            <table id={"users-table"} className={"table-auto text-sm w-full border"}>
                                                <thead className={"border-bottom"}>
                                                <tr>


                                                    <th className={""}>User ID</th>
                                                    <th className={""}>Email</th>
                                                    {/*<th className={""}>Role</th>*/}
                                                    <th className={""}>First Name</th>
                                                    <th className={""}>Last Name</th>
                                                    <td className={""}>Enabled</td>
                                                    <td></td>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {

                                                    users.map((user, ti) =>
                                                        <>
                                                            <tr key={"osk-" + ti}
                                                                className={(ti % 2 !== 0 ? " bg-gray-100  " : " ") + " hover:bg-gray-300 cursor-pointer"}>
                                                                <td className={"border-white border-2 pl-1"}>
                                                                    {
                                                                        user.UserID
                                                                    }
                                                                </td>
                                                                <td className={"border-white border-2 pl-1"}>
                                                                    {user.Email}
                                                                </td>
                                                                {/*<td className={"border-white border-2 pl-1"}>*/}
                                                                {/*    {*/}
                                                                {/*        user.Role.map(r=><div>{r}</div>)*/}
                                                                {/*    }*/}
                                                                {/*</td>*/}
                                                                <td className={"border-white border-2 pl-1"}>
                                                                    {user.FirstName}
                                                                </td>
                                                                <td className={"border-white border-2 pl-1"}>
                                                                    {user.LastName}
                                                                </td>

                                                                <td className={"border-white border-2 pl-1"}>
                                                                    {user.IsEnabled.toString()}
                                                                </td>
                                                                <td className={"border-white border-2 pl-1"}
                                                                    onClick={() => editUser(user)}>
                                                                    Edit
                                                                </td>
                                                            </tr>
                                                        </>)

                                                }

                                                </tbody>
                                            </table>
                                        </div>
                                        <div className={" flex space-x-2 text-xs justify-content-center mt-2"}>
                                            {/*{*/}
                                            {/*    pagesCount.length > 0*/}
                                            {/*        ?*/}
                                            {/*        <div*/}
                                            {/*            className={" cursor-pointer " + (currentPage === 1 ? " text-blue-700 " : " text-gray-300 ") + " hover:text-blue-700 "}*/}
                                            {/*            onClick={() => SetCurrentPage(1)}>{1}</div>*/}
                                            {/*        :*/}
                                            {/*        null*/}
                                            {/*}*/}
                                            {/*{*/}
                                            {/*    pagesCount.length > pageCount*/}
                                            {/*        ?*/}
                                            {/*        <div>...</div>*/}
                                            {/*        :*/}
                                            {/*        null*/}
                                            {/*}*/}
                                            {/*{*/}
                                            {/*    pagesCount.slice(0, pagesCount.length - 1).slice(currentPage - 5 > 0 ? currentPage - 4 : 1, currentPage - 5 > 0 ? currentPage + 5 : currentPage + 10).map(p =>*/}
                                            {/*        <div*/}
                                            {/*            className={" cursor-pointer " + (currentPage === p ? " text-blue-700 " : " text-gray-300 ") + " hover:text-blue-700 "}*/}
                                            {/*            onClick={() => SetCurrentPage(p)}>{p}</div>)*/}
                                            {/*}*/}
                                            {/*{*/}
                                            {/*    pagesCount.length > pageCount*/}
                                            {/*        ?*/}
                                            {/*        <div>...</div>*/}
                                            {/*        :*/}
                                            {/*        null*/}
                                            {/*}*/}
                                            {/*{*/}
                                            {/*    pagesCount.length > 0*/}
                                            {/*        ?*/}
                                            {/*        <div*/}
                                            {/*            className={" cursor-pointer " + (currentPage === pagesCount.length ? " text-blue-700 " : " text-gray-300 ") + " hover:text-blue-700 "}*/}
                                            {/*            onClick={() => SetCurrentPage(pagesCount[pagesCount.length - 1])}>{pagesCount[pagesCount.length - 1]}</div>*/}
                                            {/*        :*/}
                                            {/*        null*/}
                                            {/*}*/}

                                        </div>
                                    </div>
                                :
                                <div>
                                    <div className={"flex  space-x-2 w-full justify-content-end"}>
                                        <div className={"flex space-x-2"}>
                                            <GSmallLabel>Search By ID</GSmallLabel>
                                            <input max={50} className={"border flex justify-content-center rounded-xl shadow-xl transform mb-2 pl-2"} onKeyUp={(e)=>SF.ApplySearch("clinics-table",e.target.value)}/>
                                        </div>
                                        <div
                                            className={"cursor-pointer border w-20 flex justify-content-center rounded-xl shadow-xl transform hover:scale-125 mb-2"}
                                            onClick={() => PDF.ExportToPDF("Clinics", '#clinics-table', 'clinics')}
                                        >PDF
                                        </div>
                                        <div
                                            className={"cursor-pointer border w-20 flex justify-content-center rounded-xl shadow-xl transform hover:scale-125 mb-2"}
                                            onClick={() => CSV.ExportToCSV("Clinics", 'clinics-table', 'clinics' )}
                                        >CSV
                                        </div>
                                    </div>

                                    <table id={"clinics-table"} className={"table-auto text-sm w-full border"}>


                                        <thead className={"border-bottom"}>
                                        <tr>
                                            <th className={"bg-gray-200 border-white border-2 pl-1"}>Id</th>
                                            <th className={"bg-gray-200 border-white border-2 pl-1"}>Clinic Name</th>
                                            <th className={"bg-gray-200 border-white border-2 pl-1"}>Site Admin</th>
                                            <th className={"bg-gray-200 border-white border-2 pl-1"}>Clinic Email</th>
                                            <th className={"bg-gray-200 border-white border-2 pl-1"}>Clinic Phone</th>
                                            <th className={"bg-gray-200 border-white border-2 pl-1"}>Clinic Zip</th>
                                            <th className={"bg-gray-200 border-white border-2 pl-1"}>Location</th>
                                            <th className={"bg-gray-200 border-white border-2 pl-1"}>Is Enabled</th>
                                            <th className={"bg-gray-200 border-white border-2 pl-1"}>Creation Time</th>
                                            <th className={"bg-gray-200 border-white border-2 pl-1"}>Connected Device
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            data.map((clinic, ri) =>
                                                <>
                                                    <tr key={"osk-" + ri}
                                                        className={(ri % 2 !== 0 ? " bg-gray-100  " : " ") + " hover:bg-gray-300 cursor-pointer"}>
                                                        <td className={"border-white border-2 pl-1"}>
                                                            {
                                                                clinic.ClinicID
                                                            }
                                                        </td>
                                                        <td className={"border-white border-2 pl-1"}>
                                                            {
                                                                clinic.ClinicName
                                                            }
                                                        </td>
                                                        <td className={"border-white border-2 pl-1"}>
                                                            {
                                                                clinic.SiteAdmin
                                                            }
                                                        </td>
                                                        <td className={"border-white border-2 pl-1"}>
                                                            {
                                                                clinic.ClinicEmail
                                                            }
                                                        </td>
                                                        <td className={"border-white border-2 pl-1"}>
                                                            {
                                                                clinic.ClinicPhone
                                                            }
                                                        </td>
                                                        <td className={"border-white border-2 pl-1"}>
                                                            {
                                                                clinic.ClinicZip
                                                            }
                                                        </td>
                                                        <td className={"border-white border-2 pl-1"}>
                                                            <div>
                                                                {
                                                                    clinic.Location.Country
                                                                }
                                                            </div>
                                                            <div>
                                                                {
                                                                    clinic.Location.State
                                                                }
                                                            </div>
                                                            <div>
                                                                {
                                                                    clinic.Location.City
                                                                }
                                                            </div>
                                                        </td>
                                                        <td className={"border-white border-2 pl-1"}>
                                                            {
                                                                clinic.IsEnabled
                                                            }
                                                        </td>
                                                        <td className={"border-white border-2 pl-1"}>
                                                            {
                                                                clinic.CreatedUTC
                                                            }
                                                        </td>
                                                        <td className={"border-white border-2 pl-1"}>
                                                            {
                                                                clinic.DeviceID.length > 0 ? clinic.DeviceID[0].DeviceID : "none"
                                                            }
                                                        </td>
                                                        <td className={"border-white border-2 pl-1"} onClick={() => {
                                                            SetSelectedClinic(clinic)
                                                            getClinicUsers(clinic.ClinicID)
                                                        }}>
                                                            View Users
                                                        </td>
                                                    </tr>
                                                </>
                                            )

                                        }

                                        </tbody>
                                    </table>
                                </div>

                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UsersManagement;

