import React, {useContext, useEffect, useState} from 'react';
import LogoSvg from "../../assets/logo_svg.svg"
import StatusIcon from "./StatusIcon";
import GButton from "../GButton";
import GSelectLabel from "../GSelectLabel";
import GSmallLabel from "../GSmallLabel";

import {RouteContext, RoutingMachine} from "../../machines/RoutingMachine.ts";
import {useActor, useMachine, useService,} from "@xstate/react";
import {BackendContext} from "../../machines/BackendMachine";
import {DeviceContext} from "../../machines/DeviceMachine";
import {GlobalContext} from "../../machines/GlobalStateMachine";
import GInitialsIcon from "../GInitialsIcon";
import axios from "axios";
import Config from "../../services/Config";
import {useTranslation} from "react-i18next";
import {TestContext} from "../../machines/TestMachine";
import GMessagesIcon from "../GMessagesIcon";
import GMessagesWindow from "../GMessagesWindow";
import GResponsiveTest from "../GResponsiveTest";

const AppHeader = (props) => {
    const { t, i18n } = useTranslation()


    const StartButtonHideOffset = 162;
    const RContext = useContext(RouteContext)
    const [RoutingCurrentState, Navigate] = useActor(RContext);

    const BEContext = useContext(BackendContext)
    const [BackendCurrentState, Send] = useActor(BEContext);

    const DContext = useContext(DeviceContext)
    const [DeviceCurrentStatus,SendDevice] = useActor(DContext);

    const GContext = useContext(GlobalContext)
    const [GlobalCurrentState, UpdateGlobal] = useActor(GContext);


    const [viewMobileMenu,setMobileMenuView] = useState(false);

    const [offset, setOffset] = useState(0);

    const TContext = useContext(TestContext)
    const [TestCurrentState, UpdateTestState] = useActor(TContext);

    const [isMessageWindowOpen,openMessagesWindow] = useState(false)


    const [messageArray,setMessageArray] = useState([])
    const [refreshInterval,SetRefreshInterval]=useState(null)
    useEffect(()=>{

        if(refreshInterval){
            clearInterval(refreshInterval)
        }
        SetRefreshInterval(setInterval(()=>{
            axios.post(`${Config.globalUrl}:${Config.globalPort}/api/v1/webapp/doctors/${BackendCurrentState.context.profile.userId}/getMassages`,{date:(new Date())},{headers: {'Authorization': BackendCurrentState.context.authToken}})
                .then( (response) => {
                    setMessageArray(response.data)
                })
                .catch(  (error) => {
                    console.log(error)
                });
        },3000))

    },[])

    const NavigateTo =(route)=>{
        switch (route){
            case "home":
                Navigate({type:"NAV_TO_HOME"});
                break;
            case "admin":
                Navigate({type:"NAV_TO_ADMIN"});
                break;
            case "statistics":
                Navigate({type:"NAV_TO_STATISTICS"});
                break;
            case "clinic":
                Navigate({type:"NAV_TO_CLINIC_DETAILS"});
                break;
            case "privacyAndPolicy":
                Navigate({type:"NAV_TO_PRIVACY_AND_POLICY"});
                break;
            case "termsAndConditions":
                Navigate({type:"NAV_TO_TERMS_AND_CONDITIONS"});
                break;
            case "contactUs":
                Navigate({type:"NAV_TO_CONTACT_US"});
                break;
            // case "emailPopup":
            //     UpdateGlobal({type:"SET_EMAIL_POPUP"});
                break;
            case "tutorialVideo":
                UpdateGlobal({type:"SET_TUTORIAL_VIDEO"});
                break;
            case "languageSelector":
                UpdateGlobal({type:"SET_LANGUAGE_SELECTOR"});
                break;
            case "resetPassword":
                UpdateGlobal({type:"SET_RESET_PASSWORD"});
                break;
            case "mustResetPassword":
                UpdateGlobal({type:"SET_MUST_RESET_PASSWORD"});
                break;
            case "connectToScanner":
                UpdateGlobal({type:"SET_CONNECT_TO_SCANNER"});
                break;
        }
    }


    useEffect(() => {
        // GetDoctorProfile();
        window.onscroll = () => {
            setOffset(window.pageYOffset)
        }
    }, []);


    const NavigationSeries =()=>{
        let series = [];

        if(BackendCurrentState.context.role.indexOf("SiteAdmin") > -1) {
            series.push({label:t("routing.connect-to-scanner")  ,handler:()=>NavigateTo("connectToScanner")});
        }
        series.push({label: t("routing.clinic-details"), handler: () => NavigateTo("clinic")})

        series.push({label:t("language"),handler:()=>{NavigateTo("languageSelector")}})
        series.push({label:t("routing.privacy-policy")      ,handler:()=>NavigateTo("privacyAndPolicy")})
        series.push({label:t("routing.terms-and-conditions"),handler:()=>NavigateTo("termsAndConditions")})
        series.push({label:t("routing.reset-password"),handler:()=>{NavigateTo("resetPassword")}})
        if(BackendCurrentState.context.role.indexOf("Admin")>-1){
            series.push({label:t("general.admin"),handler:()=>{NavigateTo("admin")}})
        }
        return series;
    }

    return (
        <nav style={{zIndex:2}} className={"position-fixed w-full bg-white "}>
            <div style={{boxShadow:"0px 3px 7px 1px #80808045"}} className="h-20 pt-1.375rem pb-1.375rem pl-3 pr-3 lg:pl-2.125rem lg:pr-2.125rem">
                <div className={"flex h-10  "}>
                    {/*<GResponsiveTest/>*/}
                    <div className=" lg:w-1/3 lg:space-x-8 space-x-3 mt-auto mb-auto  flex ">
                        <div className="lg:hidden  flex items-center">
                            <button onClick={()=>setMobileMenuView(!viewMobileMenu)} className="outline-none mobile-menu-button">
                                <svg className=" w-6 h-6 text-gray-500 hover:text-green-500 "
                                     x-show="!showMenu"
                                     fill="none"
                                     stroke-linecap="round"
                                     stroke-linejoin="round"
                                     stroke-width="2"
                                     viewBox="0 0 24 24"
                                     stroke="currentColor"
                                >
                                    <path d="M4 6h16M4 12h8M4 18h16"></path>
                                </svg>
                            </button>
                        </div>


                        <img  src={LogoSvg} alt="logo"/>

                        <div className={"hidden lg:block"}>
                            <StatusIcon className={"flex"}/>
                        </div>
                    </div>
                    <div className="    w-1/2 center space-x-8 mt-auto mb-auto   justify-content-center hidden lg:flex">

                            <div>
                                <GSmallLabel className={"cursor-pointer"}  color={RoutingCurrentState.context.currentRoute === "home" ? " text-#50C2BC font-ghotamBold" : null} onClick={()=>NavigateTo("home")}>{t("routing.home")}</GSmallLabel>
                            </div>
                            <div>
                                <GSmallLabel  className={"cursor-pointer"}   color={RoutingCurrentState.context.currentRoute === "statistics" ? "text-#50C2BC font-ghotamBold" : null} onClick={()=>NavigateTo("statistics")}>{t("routing.statistics")}</GSmallLabel>
                            </div>
                            <div>
                                <GSelectLabel placeholder={t("routing.settings")}
                                              series={NavigationSeries()}
                                />
                            </div>
                            <div>
                                <GSelectLabel placeholder={t("routing.help")} series={
                                    [
                                        {label:t("routing.tutorial-video")      ,handler:()=>NavigateTo("tutorialVideo")},

                                        {label:t("routing.contact-us"),handler:()=>NavigateTo("contactUs")},
                                    ]
                                }/>
                            </div>

                    </div>
                    <div className="   w-1/3 space-x-2 mt-auto mb-auto justify-content-end hidden  lg:flex ">
                        {
                            offset > StartButtonHideOffset || RoutingCurrentState.context.currentRoute !== "home"
                            ?

                                    GlobalCurrentState.context.currentGlobalState === "minimized"
                                        ?
                                        <GButton onClick={()=>UpdateGlobal({type:"SET_ON_TOP"})} className={"h-10 w-52"}>
                                            {t("routing.buttons.resume")}
                                        </GButton>
                                        :

                                        <GButton disabled={Config.NoDeviceTestStart ? false  : !DeviceCurrentStatus.context.isReady} onClick={()=>UpdateGlobal({type:"SET_IN_TEST"})} className={"h-10 w-52"}>
                                            + {t("routing.buttons.start-a-new-test")}
                                        </GButton>
                            :
                                null
                        }


                        <div className={"flex mt-auto  mb-auto space-x-0.688rem"}>

                            <GSelectLabel

                                placeholder={t("routing.help")}
                                series={
                                    [
                                        {label:t("general.logout"),handler:()=>{
                                            clearInterval(refreshInterval)

                                            Send({type:"RESET_MACHINE"})
                                            SendDevice({type:"RESET_MACHINE"})
                                            UpdateGlobal({type:"RESET_MACHINE"})
                                            Navigate({type:"RESET_MACHINE"});
                                                Send({ type : "REQUEST_LOGOUT" })
                                            // UpdateTestState({type: "RESET_TEST_MACHINE"}) // RESET MACHINE CONTEXT
                                        }},
                                    ]
                                }
                                icon={
                                    <GInitialsIcon
                                        isAdmin={BackendCurrentState.context.role.filter(x=>x === "SiteAdmin").length > 0}
                                    />
                                }
                            />

                            <GSelectLabel
                                className={"mt-auto mb-auto  xl:flex 2xl:hidden"}
                                placeholder={


                                    BackendCurrentState.context.userProfile?.FirstName
                                        ?
                                        BackendCurrentState.context.userProfile?.FirstName
                                        :
                                        BackendCurrentState.context.profile.userId.split("@").length > 0
                                            ?
                                            BackendCurrentState.context.profile.userId.split("@")[0]
                                            :
                                            BackendCurrentState.context.profile.userId

                                }
                                series={
                                    [
                                        {label:t("general.logout"),handler:()=>{
                                                clearInterval(refreshInterval)

                                                Send({type:"RESET_MACHINE"})
                                                SendDevice({type:"RESET_MACHINE"})
                                                UpdateGlobal({type:"RESET_MACHINE"})
                                                Navigate({type:"RESET_MACHINE"});
                                                Send({ type : "REQUEST_LOGOUT" })
                                                // UpdateTestState({type: "RESET_TEST_MACHINE"}) // RESET MACHINE CONTEXT
                                            }},
                                    ]
                                }
                            />
                            <GSelectLabel
                                className={"mt-auto mb-auto hidden  2xl:flex whitespace-nowrap"}
                                placeholder={


                                    BackendCurrentState.context.userProfile?.FirstName
                                            ?
                                        BackendCurrentState.context.userProfile?.FirstName +" "+BackendCurrentState.context.userProfile?.LastName
                                            :
                                        BackendCurrentState.context.profile.userId.split("@").length > 0
                                                ?
                                            BackendCurrentState.context.profile.userId.split("@")[0]
                                                :
                                            BackendCurrentState.context.profile.userId

                                }
                                series={
                                    [
                                        {label:t("general.logout"),handler:()=>{
                                                clearInterval(refreshInterval)

                                                Send({type:"RESET_MACHINE"})
                                            SendDevice({type:"RESET_MACHINE"})
                                            UpdateGlobal({type:"RESET_MACHINE"})
                                            Navigate({type:"RESET_MACHINE"});
                                                Send({ type : "REQUEST_LOGOUT" })
                                            // UpdateTestState({type: "RESET_TEST_MACHINE"}) // RESET MACHINE CONTEXT
                                        }},
                                    ]
                                }
                            />
                            <GMessagesIcon messageArray={messageArray} onClick={()=>openMessagesWindow(!isMessageWindowOpen)}/>
                            {
                                isMessageWindowOpen
                                ?
                                    <GMessagesWindow messageArray={messageArray} close={()=>openMessagesWindow(false)}/>
                                :
                                    null
                            }
                        </div>
                    </div>

                    <div className={"flex lg:hidden space-x-3 w-full justify-content-end"}>

                        <StatusIcon className={"flex"}/>
                        {
                            offset > StartButtonHideOffset
                                ?
                                <div className={"flex lg:hidden"}>
                                    {
                                        GlobalCurrentState.context.currentGlobalState === "minimized"
                                            ?
                                            <GButton onClick={() => UpdateGlobal({type: "SET_ON_TOP"})}
                                                     className={"h-1.875rem w-1.875rem"}>
                                                r
                                            </GButton>
                                            :
                                            <GButton onClick={() => UpdateGlobal({type: "SET_IN_TEST"})}
                                                     className={"h-1.875rem w-1.875rem"}>
                                                +
                                            </GButton>
                                    }
                                </div>
                                :
                                null
                        }
                    </div>
                </div>
            </div>
            <div className={"lg:hidden"}>
                {
                    viewMobileMenu
                        ?
                        <div className={"pt-1.375rem pb-1.375rem pl-2.125rem pr-2.125rem mobile-menu space-y-4 w-full shadow absolute cursor-pointer pb-2.5 pt-2.5 rounded-lg bg-white"}>
                            <div onClick={()=>setMobileMenuView(false)}>
                                <GSmallLabel className={"cursor-pointer"}  color={RoutingCurrentState.context.currentRoute === "home" ? "text-#50C2BC font-ghotamBold" : null} onClick={()=>NavigateTo("home")}>{t("routing.home")}</GSmallLabel>
                            </div>
                            <div onClick={()=>setMobileMenuView(false)}>
                                <GSmallLabel  className={"cursor-pointer"}   color={RoutingCurrentState.context.currentRoute === "statistics" ? "text-#50C2BC font-ghotamBold" : null} onClick={()=>NavigateTo("statistics")}>{t("routing.statistics")}</GSmallLabel>
                            </div>
                            <div>
                                <GSelectLabel placeholder={t("routing.settings")}
                                              series={NavigationSeries()}
                                />
                            </div>
                            <div>
                                <GSelectLabel placeholder={t("routing.help")} series={
                                    [
                                        {label:t("routing.tutorial-video")      ,handler:()=>NavigateTo("tutorialVideo")},

                                        {label:t("routing.contact-us")          ,handler:()=>NavigateTo("contactUs")},
                                    ]
                                }/>

                            </div>
                            <div onClick={()=>setMobileMenuView(false)}>
                                <GSmallLabel className={"cursor-pointer"}  onClick={()=>{
                                    clearInterval(refreshInterval)

                                    Send({type:"RESET_MACHINE"})
                                    SendDevice({type:"RESET_MACHINE"})
                                    UpdateGlobal({type:"RESET_MACHINE"})
                                    Navigate({type:"RESET_MACHINE"});
                                    Send({ type : "REQUEST_LOGOUT" })
                                    // UpdateTestState({type: "RESET_TEST_MACHINE"}) // RESET MACHINE CONTEXT
                                }}>{t("general.logout")}</GSmallLabel>
                            </div>
                        </div>
                        :null
                }
            </div>


        </nav>
    )
};
export default AppHeader;

