//VALIDATIONS
import i18n from "../i18n";

export default function Validators(){}

const validatePhoneNumber = (input)=>{
    return !input.match(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/);
}
const validateNumber = (input)=>{
    return !input.match(/^[0-9]+$/);
}
const validateEmail = (email) => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email))
    {
        return (true)
    }
    return (false)
};
const emptyCheck =(inpt)=>{
    return inpt.length === 0
}
const isLetter =(c)=> {
    return (c >= 'a' && c <= 'z') ||
        (c >= 'A' && c <= 'Z')
}
const validatePassword =(password,rePassword,email)=>{
    let _isValid = false;
    let _minimumLength = password.length >= 8;
    let _containLetters = /[a-z]/i.test(password);
    let _containDigits = /[0-9]/i.test(password);
    let _containSpecialSymbols = /[!#$%&?]/.test(password)
    let _hasCapital = false;
    let _includeUserName = false;

    for(let i = 0 ; i < password.length ; i++){
        if(isLetter(password[i])){
            if (password[i] == password[i].toUpperCase()) {
                _hasCapital = true;
            }
        }
    }

    if(password.indexOf(email) > -1){
        _includeUserName = true
    }

    _isValid = _minimumLength && _containLetters && _containDigits && _containSpecialSymbols && _hasCapital && !_includeUserName;

    let _validationError = ""
    let _matchError = ""
    if(!_minimumLength){
        _validationError+= "Minimum 8 symbols"
    }
    else if(!_containLetters){
        _validationError+= "Must contain Letters"
    }
    else if(!_containDigits){
        _validationError+= "Must contain Digits"
    }
    else if(!_containSpecialSymbols){
        _validationError+= "Must Contain !#$%&?"
    }else if(!_hasCapital){
        _validationError+= "Must Contain Capital Letters"
    }else if(_includeUserName){
        _validationError+= "Must Not Contain Email"
    }

    let _isMatch = password === rePassword;

    if(!_isMatch){
        _matchError+= i18n.t('error.passwords-do-not-match')
    }
    if(!_isMatch || !_isValid){
        return {
            isError:true,
            notMatch:_isMatch,
            notValid:_isValid,
            validationError:_validationError,
            matchError:_matchError
        }
    }else{
        return {
            isError:false,
            notMatch:_isMatch,
            notValid:_isValid,
            validationError:"",
            matchError:""
        }
    }

}

const validatePasswordNoMatch =(password)=>{

    let _isValid = false;
    let _minimumLength = password.length >= 8;
    let _containLetters = /[a-z]/i.test(password);
    let _containDigits = /[0-9]/i.test(password);
    let _containSpecialSymbols = /[!#$%&?]/.test(password)
    let _hasCapital = false;
    let _includeUserName = false;

    for(let i = 0 ; i < password.length ; i++){
        if(isLetter(password[i])){
            if (password[i] === password[i].toUpperCase()) {
                _hasCapital = true;
            }
        }
    }

    _isValid = _minimumLength && _containLetters && _containDigits && _containSpecialSymbols && _hasCapital && !_includeUserName;

    let _validationError = ""

    if(!_minimumLength){
        _validationError+= "Minimum 8 symbols"
    }
    else if(!_containLetters){
        _validationError+= "Must contain Letters"
    }
    else if(!_containDigits){
        _validationError+= "Must contain Digits"
    }
    else if(!_containSpecialSymbols){
        _validationError+= "Must Contain !#$%&?"
    }else if(!_hasCapital){
        _validationError+= "Must Contain Capital Letters"
    }else if(_includeUserName){
        _validationError+= "Must Not Contain Email"
    }


    if(!_isValid){
        return {
            isError:true,
            notValid:_isValid,
            validationError:_validationError,
        }
    }else{
        return {
            isError:false,
            notValid:_isValid,
            validationError:"",
        }
    }

}
//

Validators.validatePhoneNumber = validatePhoneNumber;
Validators.validateNumber = validateNumber;
Validators.validateEmail = validateEmail;
Validators.emptyCheck = emptyCheck;
Validators.validatePassword = validatePassword;
Validators.validatePasswordNoMatch = validatePasswordNoMatch;