import i18n from "i18next";

const ParseError=(errorCode)=>{
    switch (errorCode){
        case 901:
            return i18n.t("error.bad-user-info")
        default:
            return i18n.t("error.bad-user-info")
    }
}
export default ParseError
