import React, {useState} from 'react';
import GSmallLabel from "./GSmallLabel";
import GCheckBox from "./GCheckBox";

const GStyledSelect = (props) => {

    let commonStyle = " pl-0.938rem pr-0.938rem "
    let fontStyle = " text-base text-left  "
    let borderStyle = "border rounded-lg border-#1396B433 ";
    let [selectedIndex,setSelectedIndex] = useState(0)

    let [isDropDownOpen,OpenDropDown] = useState(false)
    const handleSelected =(e)=>{
        setSelectedIndex(props.series.indexOf(e))
        OpenDropDown(false)
        props.onChange(e)
    }
    return (
        <div tabindex="0" onBlur={()=>OpenDropDown(false)} className={" relative "}>
            {
                props.title
                    ?
                    <div >
                        <GSmallLabel color={"text-gray-400 bg"} className={"text-0.875rem "}>
                            {
                                props.title
                            }
                        </GSmallLabel>

                    </div>

                    :
                    null
            }
            <div
                onClick={()=>OpenDropDown(!isDropDownOpen)}
                className={commonStyle+fontStyle+(props.borderStyle ? props.borderStyle : borderStyle)+" cursor-pointer outline-none flex "+props.className}
            >
                <div className={"w-full mt-auto mb-auto"}>
                    {
                        props.selected
                    }
                </div>

                <div className={"mt-auto mb-auto "}>
                    <svg className={isDropDownOpen ? "  " :" transform rotate-180 " } width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.30273 5.77149L6.0371 1.03711L10.7715 5.77148" stroke="#666767" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </div>
            </div>
            <div style={{zIndex:1}} className={"  rounded-md text-base text-left   text-gray-400  absolute bg-white shadow-gyni w-full"}>
                {
                    isDropDownOpen
                        ?
                        props.series.map((s,i)=>
                            <div onClick={()=>handleSelected(s)} className={props.itemsStyle + " flex  rounded-md  place-items-center h-16 hover:bg-GBaloonDiv"}>
                                {
                                    s === props.selected
                                    ?
                                        <GCheckBox disabled placeInMid  checked={true} text={s}/>
                                    :
                                        <GCheckBox disabled placeInMid  checked={false} text={s}/>

                                }
                            </div>

                        )
                        :
                        null
                }
            </div>

            {
                props.validation
                    ?
                    <GSmallLabel color={"text-red-500 text-0.75rem"} className={"absolute"}>{
                        props.error
                    }</GSmallLabel>
                    :
                    null
            }
        </div>

    )
};
export default GStyledSelect;
