import React, {useContext, useEffect, useState} from 'react'
import {BackendContext} from "../../machines/BackendMachine";
import {useActor, useMachine} from "@xstate/react";
import {LoginContext, LoginMachine} from "../../machines/LoginMachine";
import logo from "../../assets/logo_svg.svg";
import GBigLabel from "../GBigLabel";
import GSmallLabel from "../GSmallLabel";
import GTextInput from "../GTextInput";
import GButton from "../GButton";
import GCarousel from "../GCarousel";
import {useTranslation} from "react-i18next";
import ParseError from "../../services/ErrorParser";
import countryList from 'react-select-country-list'
import { RegionDropdown } from 'react-country-region-selector';

import GSelect from "../GSelect";

const CreateUser=()=>{
    const { t, i18n } = useTranslation()

    const BEContext = useContext(BackendContext)
    let BackendCurrent, BESend;
    [BackendCurrent, BESend] = useActor(BEContext);
    const [Countries,SetCountries] = useState(null)

    const LContext = useContext(LoginContext)
    let lc, LoginSend;
    [lc, LoginSend] = useActor(LContext);

    useEffect(() =>{
        let _countries = []
        let _ctr = countryList().getData()
        _ctr.forEach(c=>{
            _countries.push(c.label)
        })
        SetCountries(_countries)
    },[])
    useEffect(()=>{
        if(BackendCurrent.context.userCreated && BackendCurrent.context.userCreated !== "error"){
            setTimeout(()=>{
                BESend({type:"RESET_USER_CREATED"})
                LoginSend({type:"RESET_MACHINE"})
                LoginSend({type:"NAV_TO_LOGIN"})
            },4000)
        }
    })

    const CheckInputAndSetPage=(page)=>{
        LoginSend({type:"CHECK_MANDATORY_FIELDS",data:{pageNumber:page}})
    }

    const CheckInputAndSignUp=(page)=>{
        LoginSend({type:"CHECK_MANDATORY_FIELDS",data:{pageNumber:page}})
        setTimeout(()=>{
            if(!lc.context.validation_error_step_2 && !lc.context.validation_error && !lc.context.validation_error_step_1){
                BESend({ type : "REQUEST_SIGN_UP" , data:lc.context})
            }
        },200)
    }


    return (
        <div className={" pt-6.994rem "}>

            <img className={"h-3.938rem m-auto"} src={logo} alt={"logo"}/>
            <GBigLabel className={"mt-3.813rem leading-1.914rem text-2rem w-full flex justify-center"}>{t("login.buttons.create-an-account")}</GBigLabel>
            <div className={"mt-2 flex justify-center space-x-0.313rem"}>
                <GSmallLabel className={"leading-1.076rem"}>{t("login.sign-up.question" )}</GSmallLabel>
                <GSmallLabel color={"text-#50C2BC"} bold className={"leading-1.076rem cursor-pointer "} onClick={()=>{
                    LoginSend({type:"RESET_MACHINE"})
                    LoginSend({type:"NAV_TO_LOGIN"})
                }} >{t("login.sign-in")}</GSmallLabel>
            </div>
            <div className={"text-red-500 w-full text-center ml-auto mr-auto absolute"}>
                {
                    BackendCurrent.context.userCreated === "true"
                        ?
                        <GSmallLabel bold className={"text-black"}>{t("login.sign-up.user-created")}</GSmallLabel>
                        :
                        BackendCurrent.context.userCreated === "error"
                            ?
                            <div>{ParseError(BackendCurrent.context.userCreationError)}</div>
                            :
                            null
                }
            </div>
            <GCarousel SetSelectedPage={(p)=>CheckInputAndSetPage(p)} siblingControl={true} selectedPage={lc.context.signUpPage} className={"pb-6.875rem"} size={1}>
                <div className={"mt-3.438rem w-21.875rem ml-auto mr-auto "}>
                    {/*value={TestCurrentState.context.complaints.otherText}  onChange={(e)=>UpdateTestState({type:"INPUT_OTHER_TEXT",value:e.target.value})}*/}
                    <GTextInput
                        max={50}
                        validation
                        error={lc.context.invalid_clinicName}
                        value={lc.context.clinicName}
                        onChange={(e)=>{LoginSend({type:"INPUT_CLINIC_NAME",clinicName:e.target.value})}}
                        className={"bg-gyni-textBox h-3.5rem rounded-lg"} placeHolder={t("login.placeholder.clinic-name")}/>
                    <GTextInput
                        max={50}
                        validation
                        error={lc.context.invalid_clinicAddress}
                        value={lc.context.clinicAddress}
                        onChange={(e)=>{LoginSend({type:"INPUT_CLINIC_ADDRESS",clinicAddress:e.target.value})}}
                        className={"mt-1.875rem bg-gyni-textBox h-3.5rem rounded-lg"} placeHolder={t("login.placeholder.clinic-address")}/>
                    <div className={"mt-1.875rem  flex space-x-1.25rem"}>
                        <GTextInput
                            max={50}
                            validation
                            error={lc.context.invalid_city}
                            value={lc.context.city}
                            onChange={(e)=>{LoginSend({type:"INPUT_CITY",city:e.target.value})}}
                            className={"bg-gyni-textBox h-3.5rem rounded-lg"} placeHolder={t("login.placeholder.clinic-city")}/>
                        <GSelect
                            validation
                            value={lc.context.country}
                            error={lc.context.invalid_country}
                            onChange={(e)=>{LoginSend({type:"INPUT_COUNTRY",country:e})}}
                            className={" max-w-10.313rem bg-gyni-textBox h-3.5rem rounded-lg"}
                            placeHolder={t("login.placeholder.clinic-country")}
                            series={Countries ? Countries : ["PlaceHolder"]}>

                        </GSelect>

                    </div>
                    <div className={"mt-1.875rem mt-1.875rem flex space-x-1.25rem"}>


                        <RegionDropdown
                            defaultOptionLabel={t("login.placeholder.clinic-state")}
                            className={" border-2 rounded-lg border-#1396B433  text-base text-left pl-10  text-gray-400  h-14 pl-0.938rem pr-0.938rem w-10.313rem min-w-10.313rem max-w-10.313rem bg-gyni-textBox h-3.5rem rounded-lg "}
                            country={lc.context.country}
                            value={lc.context.state}
                            onChange={(e)=>{LoginSend({type:"INPUT_STATE",state:e})}}
                        />
                        {/*<GTextInput*/}
                        {/*    validation*/}
                        {/*    error={lc.context.invalid_state}*/}
                        {/*    value={lc.context.state}*/}
                        {/*    onChange={(e)=>{LoginSend({type:"INPUT_STATE",state:e.target.value})}}*/}
                        {/*    className={"bg-gyni-textBox h-3.5rem rounded-lg"} placeHolder={t("login.placeholder.clinic-state")}/>*/}
                        <GTextInput
                            max={50}
                            validation
                            error={lc.context.invalid_zip}
                            value={lc.context.zip}
                            onChange={(e)=>{LoginSend({type:"INPUT_ZIP",zip:e.target.value})}}
                            className={"bg-gyni-textBox h-3.5rem rounded-lg"} placeHolder={t("login.placeholder.clinic-zip")}/>
                    </div>
                    <GTextInput
                        max={50}
                        validation
                        error={lc.context.invalid_phone}
                        value={lc.context.phone}
                        onChange={(e)=>{LoginSend({type:"INPUT_CLINIC_PHONE",phone:e.target.value})}}
                        className={"mt-1.875rem bg-gyni-textBox h-3.5rem rounded-lg"} placeHolder={t("login.placeholder.clinic-phone")}/>
                    <div className={"mt-4.563rem mb-2.25rem"}>
                        <GButton onClick={()=>{
                            CheckInputAndSetPage(1)
                        }} className={"h-10 w-21.875rem ml-auto mr-auto"}>
                            {t("general.next")}
                        </GButton>
                    </div>

                </div>
                <div className={"mt-3.438rem w-21.875rem ml-auto mr-auto"}>

                    <GTextInput
                        max={50}
                        validation
                        error={lc.context.invalid_firstName}
                        value={lc.context.firstName}
                        onChange={(e)=>{LoginSend({type:"INPUT_FIRST_NAME",firstName:e.target.value})}}
                        className={"bg-gyni-textBox h-3.5rem rounded-lg"} placeHolder={t("login.placeholder.first-name")}/>
                    <GTextInput
                        max={50}
                        validation
                        error={lc.context.invalid_lastName}
                        value={lc.context.lastName}
                        onChange={(e)=>{LoginSend({type:"INPUT_LAST_NAME",lastName:e.target.value})}}
                        className={"mt-1.875rem bg-gyni-textBox h-3.5rem rounded-lg"} placeHolder={t("login.placeholder.last-name")}/>

                    <GTextInput
                        max={50}
                        validation
                        error={lc.context.invalid_email}
                        value={lc.context.email}
                        onChange={(e)=>{LoginSend({type:"INPUT_EMAIL",email:e.target.value})}}
                        className={"mt-1.875rem bg-gyni-textBox h-3.5rem rounded-lg"} placeHolder={t("login.placeholder.email")}/>
                    <GTextInput
                        max={50}
                        validation
                        iconPosition={"top-12"}
                        error={lc.context.invalid_password}
                        value={lc.context.password}
                        onChange={(e)=>{LoginSend({type:"INPUT_PASSWORD",password:e.target.value})}}
                        password className={"mt-1.875rem bg-gyni-textBox h-3.5rem rounded-lg"} placeHolder={t("login.placeholder.password")}/>
                    <GTextInput
                        max={50}
                        validation
                        iconPosition={"top-12"}
                        error={lc.context.invalid_rePassword}
                        value={lc.context.rePassword}
                        onChange={(e)=>{LoginSend({type:"INPUT_RE_PASSWORD",rePassword:e.target.value})}}
                        password className={"mt-1.875rem bg-gyni-textBox h-3.5rem rounded-lg"} placeHolder={t("login.placeholder.re-enter-password")}/>
                    <div className={"mt-1.625rem"}>
                        <GSmallLabel className={"text-0.75rem leading-1.076rem"}>{t("login.p1")}</GSmallLabel>
                        <GSmallLabel color={"text-0.75rem  text-#50C2BC cursor-pointer"} bold className={"leading-1.076rem "} onClick={()=>LoginSend({type:"NAV_TO_TERMS"})} >{t("login.p2")}</GSmallLabel>
                    </div>
                    <div className={"mt-0.8rem mb-2.25rem"}>
                        <GButton
                            onClick={()=>{
                                CheckInputAndSignUp(2)
                            }}
                            className={"h-10 w-21.875rem ml-auto mr-auto"}>
                            {t("login.buttons.create-an-account")}
                        </GButton>
                    </div>
                </div>

            </GCarousel>


        </div>
    )
}
export default CreateUser;
