import React, {useContext, useEffect, useState} from "react"
import GBigLabel from "../GBigLabel";
import ReactApexChart from "react-apexcharts";
import GSmallLabel from "../GSmallLabel";
import GButton from "../GButton";
import GResponsiveTest from "../GResponsiveTest";
import GInitialsIcon from "../GInitialsIcon";
import trash from "../../assets/trash.svg";
import roundArrow from "../../assets/round-arrow.svg"
import pencil from "../../assets/pencil.svg"
import GSelect from "../GSelect";
import GCheckBox from "../GCheckBox";
import GTextInput from "../GTextInput";
import GModal from "../GModal";
import arrow from "../../assets/arrow_svg.svg"
import axios from "axios";
import Config from "../../services/Config";
import {BackendContext} from "../../machines/BackendMachine";
import {useActor} from "@xstate/react";
import {useTranslation} from "react-i18next";
import i18n from "../../i18n";
import GStyledSelect from "../GStyledSelect";
const ClinicDetails=()=>{
    const { t, i18n } = useTranslation()
    useEffect(()=>{
        FetchClinicUsers();
        FetchClinicInfo();
    },[])

    const [emailError,setEmailError]=useState("")
    const BEContext = useContext(BackendContext)
    let BackendCurrent, BESend;
    [BackendCurrent, BESend] = useActor(BEContext);

    const SaveClinic=(clinicInfo)=>{
        let req = {
            ClinicID:clinicInfo.ClinicID,
            Location:{
                Address:clinicInfo.Location.Address,
                Country:clinicInfo.Location.Address,
                State:clinicInfo.Location.Address,
                City:clinicInfo.Location.Address
            },
            ClinicName:clinicInfo.ClinicName,
            ClinicZip:"111111",
            ClinicPhone:clinicInfo.ClinicPhone,
        }
        axios.post(`${Config.globalUrl}:${Config.globalPort}/api/v1/webapp/siteadmins/${BackendCurrent.context.profile.userId}/updateMyClinicInfo/`,req,{headers: {'Authorization': BackendCurrent.context.authToken}})
            .then(async (response) => {
                console.log(response)
                FetchClinicInfo();

            })
            .catch( async (error) => {
                console.log(error);
            });
    }
    const SaveUser=(body)=>{

        let tRoles = [];
        body.role.forEach(role=>{
            if(role.constructor === Array){
                role.forEach(x=>{
                    tRoles.push(x)
                })
            }else{
                tRoles.push(role)
            }
        })

       let req = {
           UserID:body.id,
           FirstName:body.firstName,
           LastName:body.lastName,
           Role:adjustRoleNames(tRoles,"TOBE"),
           Email:body.email,
           IsEnabled: body.IsEnabled
       }
        axios.post(`${Config.globalUrl}:${Config.globalPort}/api/v1/webapp/siteadmins/${BackendCurrent.context.profile.userId}/updateMyClinicDoctorsProfile/`,req,{headers: {'Authorization': BackendCurrent.context.authToken}})
            .then(async (response) => {
               console.log(response)
                FetchClinicUsers()
                UpdateUserProfile()
            })
            .catch( async (error) => {
                console.log(error);
            });
    }
    const validateEmail = (email) => {
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email))
        {
            return (true)
        }
        return (false)
    };
    const SaveNewUser=(body)=>{
        let req = {
            ClinicID: editClinicInfo.ClinicID,
            // UserID:body.id,
            FirstName:body.firstName,
            LastName:body.lastName,
            Role: ["User"],
            Email:body.email,
            IsEnabled:  true,
        }

        axios.post(`${Config.globalUrl}:${Config.globalPort}/api/v1/webapp/siteadmins/${BackendCurrent.context.profile.userId}/addDoctorToMyClinic/`,req,{headers: {'Authorization': BackendCurrent.context.authToken}})
            .then(async (response) => {
                console.log(response)
                FetchClinicUsers()
                UpdateUserProfile()
                setAddNewUserModal(false)
            })
            .catch( async (error) => {
                console.log(error);
                if(
                    error &&
                    error.response &&
                    error.response.data &&
                    error.response.data.message &&
                    error.response.data.message.length === 2 &&
                    JSON.parse(error.response.data.message[1]).Email === "unique"
                ){
                    body.emailError = "This email already exists" ;
                    setNewUser(body)
                }
            });
    }
    const adjustRoleNames =(roles,direction)=>{
        let jRoles = [];
        let tRoles = [];

        roles.forEach(role=>{
            if(role.constructor === Array){
                role.forEach(x=>{
                    jRoles.push(x)
                })
            }else{
                jRoles.push(role)
            }
        })

        jRoles.forEach(role=>{



            if(direction === "TOBE"){

                if(role === "User"){
                    role = "Doctor"
                }
                if(role === "Admin"){
                    role = "SiteAdmin"
                }

            }else if(direction === "FROMBE"){

                if(role === "Doctor"){
                    role = "User"
                }
                if(role === "SiteAdmin"){
                    role = "Admin"
                }

            }

            tRoles.push(role)
        })
        return tRoles
    }
    const FetchClinicUsers=()=>{
        axios.get(`${Config.globalUrl}:${Config.globalPort}/api/v1/webapp/siteadmins/${BackendCurrent.context.profile.userId}/getMyClinicDoctors/`,{headers: {'Authorization': BackendCurrent.context.authToken}})
            .then(async (response) => {
                let _users = []
                response.data.forEach(_user => {
                    _users.push(
                        {
                            id:_user.UserID,
                            firstName:_user.FirstName,
                            lastName:_user.LastName,
                            role:_user.Role ? adjustRoleNames(_user.Role,"FROMBE") : "User",
                            email:_user.Email.length > 0 ? _user.Email[0] : "",
                            IsEnabled: _user.IsEnabled.length > 0 ? _user.IsEnabled[0] : _user.Role ? _user.Role.indexOf("Admin") > -1 : true,
                        },
                    )
                });
                setUsers(_users)
            })
            .catch( async (error) => {
                console.log(error);
            });
    }
    const UpdateUserProfile=()=>{
        BESend({type:"UPDATE_USER_PROFILE"})
    }
    const FetchClinicInfo=()=>{
        axios.get(`${Config.globalUrl}:${Config.globalPort}/api/v1/webapp/doctors/${BackendCurrent.context.profile.userId}/getMyClinicInfo/`,     {headers: {'Authorization': BackendCurrent.context.authToken}})
            .then(async (response) => {

                setEditClinicInfo({
                    ClinicID:response.data.ClinicID,
                    ClinicName:response.data.ClinicName,
                    ClinicNameError:null,
                    Location:{
                        Address:response.data.Location.Address,
                        AddressError:null,
                    },

                    Email:response.data.Email,
                    EmailError:null,
                    ClinicPhone:response.data.ClinicPhone,
                    ClinicPhoneError:null,
                })

                console.log(response)
            })
            .catch(async (error) => {
                console.log(error);
            });
    }


    const sortTable=(n)=> {

        var table, rows, switching, i, x, y, shouldSwitch, dir, switchcount = 0;
        table = document.getElementById("users-table");
        switching = true;
        //Set the sorting direction to ascending:
        dir = "asc";
        /*Make a loop that will continue until
        no switching has been done:*/
        while (switching) {
            //start by saying: no switching is done:
            switching = false;
            rows = table.rows;
            /*Loop through all table rows (except the
            first, which contains table headers):*/
            for (i = 1; i < (rows.length - 1); i++) {
                //start by saying there should be no switching:
                shouldSwitch = false;
                /*Get the two elements you want to compare,
                one from current row and one from the next:*/
                x = rows[i].getElementsByTagName("TD")[n];
                y = rows[i + 1].getElementsByTagName("TD")[n];
                /*check if the two rows should switch place,
                based on the direction, asc or desc:*/
                if (dir == "asc") {
                    if (x.innerHTML.toLowerCase() > y.innerHTML.toLowerCase()) {
                        //if so, mark as a switch and break the loop:
                        shouldSwitch= true;
                        break;
                    }
                } else if (dir == "desc") {
                    if (x.innerHTML.toLowerCase() < y.innerHTML.toLowerCase()) {
                        //if so, mark as a switch and break the loop:
                        shouldSwitch = true;
                        break;
                    }
                }
            }
            if (shouldSwitch) {
                /*If a switch has been marked, make the switch
                and mark that a switch has been done:*/
                rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
                switching = true;
                //Each time a switch is done, increase this count by 1:
                switchcount ++;
            } else {
                /*If no switching has been done AND the direction is "asc",
                set the direction to "desc" and run the while loop again.*/
                if (switchcount == 0 && dir == "asc") {
                    dir = "desc";
                    switching = true;
                }
            }
        }
        setNowSorting({
            index: n,
            dir:dir
        })
    }
    const viewThWithArrow=(title,index)=>{
        return(
            <div className={"flex space-x-2"}>
                <div>
                    {
                        title
                    }
                </div>

                {
                    nowSorting.index === index
                        ?
                        <img className={( nowSorting.dir === "asc" ? " transform rotate-180 " : " ")+" select-none w-4 h-4 mt-auto mb-auto"} src={arrow}/>
                        :
                        null
                }
            </div>
        )
    }

    const [addNewUserModal,setAddNewUserModal] = useState(false)
    const [editUserModal,setEditUserModal] = useState(false)
    const [clinicInfoEditModal,setClinicInfoEditModal] = useState(false)
    const [removeUserModal,setRemoveUserModal] = useState(false)
    const [nowSorting , setNowSorting] = useState({
        index:-1,
        dir:""
    })

    const UpdateNewUser =(field,value)=>{
        let _newUser = JSON.parse(JSON.stringify(newUser));
        if(value.length === 0){
            _newUser[field+"Error"] = t("error.cannot-be-empty")
        }else {
            _newUser[field+"Error"] = null
        }
        _newUser[field]=value;
        setNewUser(_newUser)
    }
    const UpdateSelectedUser =(field,value,user=null)=>{
        if(value === t("general.yes")){
            value = true
        }
        if(value === t("general.no")){
            value = false
        }
        let _selectedUser = user ? user : JSON.parse(JSON.stringify(selectedUser));
        if(value.length === 0){
            _selectedUser[field+"Error"] = t("error.cannot-be-empty")
        }else{
            _selectedUser[field+"Error"] = null
        }
        if(field === "role"){
            let roles = [];
            if(value === "Admin"){
                roles.push("Admin")
                roles.push("User")
            }
            if(value === "User"){
                roles.push("User")
            }
            value = roles;
            _selectedUser[field]=value;
            SaveEditedUserAtBE(_selectedUser)
        }
        _selectedUser[field]=value;
        SetSelectedUser(_selectedUser)
    }
    const UpdateClinicInfo =(field,value)=>{
        let splittedFields = [];
        if(field.indexOf(".")>-1){
          splittedFields = field.split(".");
        }
        let _clinicInfo = JSON.parse(JSON.stringify(editClinicInfo));
        if(value.length === 0){
            _clinicInfo[field+"Error"] = t("error.cannot-be-empty")
        }else{
            _clinicInfo[field+"Error"] = null
        }
        if(splittedFields.length>1)
            _clinicInfo[splittedFields[0]][splittedFields[1]] = value;
        else
            _clinicInfo[field]=value;
        setEditClinicInfo(_clinicInfo)
    }

    const SaveNewUserAtBE=()=>{

        if(newUser.firstName.length === 0){
            newUser.firstNameError = t("error.cannot-be-empty")
        }
        if(newUser.lastName.length === 0){
            newUser.lastNameError = t("error.cannot-be-empty")
        }
        if(newUser.email.length === 0){
            newUser.emailError = t("error.cannot-be-empty")
        }
        if(!validateEmail(newUser.email)){
            newUser.emailError = t("error.email-expected")
        }
        setNewUser(JSON.parse(JSON.stringify(newUser)));

        let errorMessage = (
            (newUser.firstNameError?newUser.firstNameError : "") + '\n' +
            (newUser.lastNameError?newUser.lastNameError : "") + '\n' +
            (newUser.emailError?newUser.emailError : "")).trim();

        if(errorMessage.length === 0) {

            newUser.role = ["User"];
            SaveNewUser(newUser)

        }
    }
    const SaveEditedUserAtBE=(user=null)=>{
        let _selectedUser = selectedUser;
        if(user){
            _selectedUser = user;
        }
        if(_selectedUser.firstName.length === 0){
            _selectedUser.firstNameError = t("error.cannot-be-empty")
        }
        if(_selectedUser.lastName.length === 0){
            _selectedUser.lastNameError = t("error.cannot-be-empty")
        }
        if(_selectedUser.email.length === 0){
            _selectedUser.emailError = t("error.cannot-be-empty")
        }
        SetSelectedUser(JSON.parse(JSON.stringify(_selectedUser)));

        let errorMessage = (
            (_selectedUser.firstNameError?_selectedUser.firstNameError : "") + '\n' +
            (_selectedUser.lastNameError?_selectedUser.lastNameError : "") + '\n' +
            (_selectedUser.emailError?_selectedUser.emailError : "")).trim();

        if(errorMessage.length === 0) {
            SaveUser(_selectedUser)
            SetSelectedUser(null)
            setEditUserModal(false)
        }
    }
    const SaveEditedClinicInfoAtBE=()=>{

        if(editClinicInfo.ClinicName.length === 0){
            editClinicInfo.nameError = t("error.cannot-be-empty")
        }
        if(editClinicInfo.Location.Address.length === 0){
            editClinicInfo.addressError = t("error.cannot-be-empty")
        }
        if(editClinicInfo.ClinicPhone.length === 0){
            editClinicInfo.phoneError = t("error.cannot-be-empty")
        }
        setEditClinicInfo(JSON.parse(JSON.stringify(editClinicInfo)));

        let errorMessage = (
            (editClinicInfo.nameError?editClinicInfo.nameError : "") + '\n' +
            (editClinicInfo.addressError?editClinicInfo.addressError : "") + '\n' +
            (editClinicInfo.phoneError?editClinicInfo.phoneError : "")).trim();


        if(errorMessage.length === 0) {
            SaveClinic(editClinicInfo);
            setClinicInfoEditModal(false)
        }
    }

    const RemoveUser=()=>{
        selectedUser.IsEnabled = !selectedUser.IsEnabled;
        SaveUser(selectedUser)
        setRemoveUserModal(false)
    }

    const [selectedUser,setSelectedUser] = useState({
        id:-1,
        firstName:"",
        firstNameError:null,
        lastName:"",
        lastNameError:null,
        role:[],
        email:null,
        emailError:""
    })

    const [editClinicInfo,setEditClinicInfo] = useState({})
    const [newUser,setNewUser] = useState({
        id:-1,
        firstName:"",
        firstNameError:null,
        lastName:"",
        lastNameError:null,
        role:[],
        email:"",
        emailError:null
    })

    const [users,setUsers] = useState([])

    const SetSelectedUser=(user)=>{
        setSelectedUser(user)
    }

    return(
        <div>
            {
                removeUserModal
                    ?
                    <GModal handleClose={()=>setRemoveUserModal(!removeUserModal)}  className={"max-w-28.438rem"}>
                        <div className={"lg:pr-3.125rem lg:pl-3.125rem"}>
                            {/*TITLE*/}
                            <div className={"mb-3.375rem text-center flex  justify-content-center "}>
                                <GBigLabel className={"text-2rem font-1.125rem"}>
                                    Remove User
                                </GBigLabel>
                            </div>
                            <div>
                                {
                                    selectedUser.IsEnabled
                                    ?
                                        <GSmallLabel>{t("clinic.remove-user-disable-message")}</GSmallLabel>
                                    :
                                        <GSmallLabel>{t("clinic.remove-user-enabled-message")}</GSmallLabel>
                                }
                            </div>

                            {/*//{*/}
                            {/* //   selectedUser.IsEnabled*/}
                            {/* //   ?*/}
                                    <div className={" mb-2.937rem pl-2.625rem pr-2.625rem pt-12 space-y-2"}>
                                        <GButton onClick={()=>RemoveUser()} className={"ml-auto mr-auto w-1/2 max-w-10.438rem h-3.125rem"}>
                                            {t("general.yes")}
                                        </GButton>
                                        <GButton textColor={" text-#50C2BC "} customStyle={" bg-white "} onClick={()=>setRemoveUserModal(!removeUserModal)} className={"ml-auto mr-auto  w-1/2 max-w-10.438rem h-3.125rem"}>
                                            {t("general.no")}
                                        </GButton>
                                    </div>
                             {/*//   :*/}
                            {/*//        null*/}
                           {/*// }*/}

                        </div>
                    </GModal>
                    :
                    null
            }
            {
                clinicInfoEditModal
                    ?
                    <GModal handleClose={()=>setClinicInfoEditModal(!clinicInfoEditModal)}  className={"max-w-28.438rem"}>
                        <div className={"lg:pr-3.125rem lg:pl-3.125rem"}>
                            {/*TITLE*/}
                            <div className={"mb-3.375rem text-center flex  justify-content-center "}>
                                <GBigLabel className={"text-2rem font-1.125rem"}>
                                    Edit Clinic
                                </GBigLabel>
                            </div>
                            <div>

                                <div className={"mb-3.75rem"}>
                                    <div className={"space-y-1.25rem"}>

                                        <GTextInput max={50} validation error={editClinicInfo.ClinicNameError} title={t("clinic.name")} value={editClinicInfo.ClinicName}  onChange={(e)=>UpdateClinicInfo("ClinicName",e.target.value)} borderStyle={" rounded-2xl border border-#1396B4 border-1px "} className={"w-18.438rem h-3.125rem"}/>
                                        <GTextInput max={50} validation error={editClinicInfo.Location.AddressError} title={t("clinic.address")} value={editClinicInfo.Location.Address}  onChange={(e)=>UpdateClinicInfo("Location.Address",e.target.value)} borderStyle={" rounded-2xl border border-#1396B4 border-1px "} className={"w-18.438rem h-3.125rem"}/>
                                        <GTextInput max={50} validation error={editClinicInfo.ClinicPhoneError} title={t("clinic.phone")} value={editClinicInfo.ClinicPhone}  onChange={(e)=>UpdateClinicInfo("ClinicPhone",e.target.value)} borderStyle={" rounded-2xl border border-#1396B4 border-1px "} className={"w-18.438rem h-3.125rem"}/>
                                    </div>
                                </div>
                            </div>

                            <div className={" pl-2.625rem pr-2.625rem"}>
                                <GButton onClick={()=>SaveEditedClinicInfoAtBE()} className={"m-auto max-w-10.438rem h-3.125rem"}>
                                    {t("general.save")}
                                </GButton>
                                <GButton textColor={" text-#50C2BC "} customStyle={" bg-white "} onClick={()=>setClinicInfoEditModal(!clinicInfoEditModal)} className={"ml-auto mr-auto  w-1/2 max-w-10.438rem h-3.125rem"}>
                                    {t("general.cancel")}
                                </GButton>
                            </div>
                        </div>
                    </GModal>
                    :
                    null
            }
            {
                editUserModal
                    ?
                    <GModal handleClose={()=>setEditUserModal(!editUserModal)}  className={"max-w-28.438rem"}>
                        <div className={"lg:pr-3.125rem lg:pl-3.125rem"}>
                            {/*TITLE*/}
                            <div className={"mb-3.375rem text-center flex  justify-content-center "}>
                                <GBigLabel className={"text-2rem font-1.125rem"}>
                                    {t("clinic.buttons.edit-user")}
                                </GBigLabel>
                            </div>
                            <div>

                                <div className={"mb-3.75rem"}>
                                    <div className={"space-y-1.25rem"}>

                                        <GTextInput max={50} validation error={selectedUser.firstNameError} title={t("clinic.first-name")} value={selectedUser.firstName}  onChange={(e)=>UpdateSelectedUser("firstName",e.target.value)} borderStyle={" rounded-2xl rounded-2xl border "} className={"w-18.438rem h-3.125rem"}/>
                                        <GTextInput max={50} validation error={selectedUser.lastNameError} title={t("clinic.last-name")} value={selectedUser.lastName}  onChange={(e)=>UpdateSelectedUser("lastName",e.target.value)} borderStyle={" rounded-2xl rounded-2xl border "} className={"w-18.438rem h-3.125rem"}/>
                                        <GTextInput max={50} validation error={selectedUser.emailError} title={t("clinic.email")} value={selectedUser.email}  onChange={(e)=>UpdateSelectedUser("email",e.target.value)} borderStyle={" rounded-2xl rounded-2xl border  "} className={"w-18.438rem h-3.125rem"}/>
                                        <GStyledSelect itemsStyle={" pl-7"} title={t("clinic.buttons.enabled")} selected={selectedUser.IsEnabled ? t("general.yes"):t("general.no")} borderStyle={" rounded-2xl border "} className={"w-18.438rem h-3.125rem"}  onChange={(e)=>{
                                            UpdateSelectedUser("IsEnabled",e)
                                        }} series={[t("general.yes"),t("general.no")]}/>
                                    </div>
                                </div>
                            </div>

                            <div className={"pl-2.625rem pr-2.625rem"}>
                                <GButton onClick={()=>SaveEditedUserAtBE()} className={"m-auto max-w-10.438rem h-3.125rem"}>
                                    {t("clinic.save")}
                                </GButton>
                                <GButton textColor={" text-#50C2BC "} customStyle={" bg-white "} onClick={()=>setEditUserModal(!editUserModal)} className={"ml-auto mr-auto  w-1/2 max-w-10.438rem h-3.125rem"}>
                                    {t("general.cancel")}
                                </GButton>
                            </div>
                        </div>
                    </GModal>
                    :
                    null
            }
            {
                addNewUserModal
                ?
                    <GModal handleClose={()=>setAddNewUserModal(!addNewUserModal)}  className={"max-w-28.438rem"}>
                        <div className={"lg:pr-3.125rem lg:pl-3.125rem"}>
                            {/*TITLE*/}
                            <div className={"mb-3.375rem text-center flex  justify-content-center "}>
                                {
                                    BackendCurrent.context.role.indexOf("SiteAdmin") > -1
                                    ?
                                        <GBigLabel className={"text-2rem font-1.125rem"}>
                                            {t("clinic.add-user")}
                                        </GBigLabel>
                                    :
                                        null
                                }

                            </div>
                            <div>

                                <div className={"mb-3.75rem"}>
                                    <div className={"space-y-1.25rem"}>

                                        <GTextInput max={50} validation error={newUser.firstNameError} title={t("clinic.first-name")} value={newUser.firstName}  onChange={(e)=>UpdateNewUser("firstName",e.target.value)} borderStyle={" rounded-2xl border border-#1396B4 border-1px "} className={"w-18.438rem h-3.125rem"}/>
                                        <GTextInput max={50} validation error={newUser.lastNameError} title={t("clinic.last-name")} value={newUser.lastName}  onChange={(e)=>UpdateNewUser("lastName",e.target.value)} borderStyle={" rounded-2xl border border-#1396B4 border-1px "} className={"w-18.438rem h-3.125rem"}/>
                                        <GTextInput max={50} validation error={newUser.emailError} title={t("clinic.email")} value={newUser.email}  onChange={(e)=>UpdateNewUser("email",e.target.value)} borderStyle={" rounded-2xl border border-#1396B4 border-1px "} className={"w-18.438rem h-3.125rem"}/>
                                    </div>
                                </div>
                            </div>

                            <div className={"mb-2.937rem pl-2.625rem pr-2.625rem"}>
                                <GButton  onClick={()=>SaveNewUserAtBE()} className={"m-auto max-w-10.438rem h-3.125rem"}>
                                    {t("general.add")}
                                </GButton>
                            </div>
                        </div>
                    </GModal>
                    :
                    null
            }
            <div className={"clinic-details-height-responsive pt-20"}>

                <GBigLabel className={"mt-20 flex justify-content-center text-2rem"}>{t("routing.clinic-details")}</GBigLabel>
                <div className={"  ml-10 sm:ml-6.25rem mr-10 sm:mr-6.25rem mt-3.75rem mb-3.75rem shadow-gyni rounded-2xl"}>
                    <div className={"lg:flex"}>
                        <div className={"lg:w-18.375rem sm:pr-2.813rem pt-3.125rem pb-3.125rem  pl-2 pr-2  sm:pl-2.813rem shadow-gyni rounded-2xl"}>
                            <div className={"flex"}>
                                <GBigLabel  className={"md:ml-2.813rem md:mb-3.438rem text-1.375rem"}>{t("clinic.clinic-info")}</GBigLabel>
                                {
                                    BackendCurrent.context.role.indexOf("SiteAdmin") > -1
                                    ?
                                        <img onClick={()=>setClinicInfoEditModal(!clinicInfoEditModal)} className={"cursor-pointer ml-2 mr-2 mt-1 w-6 h-6"} src={pencil} alt={"edit"}/>
                                    :
                                        null
                                }

                            </div>

                            <div className={" md:ml-2.813rem md:mr-4.875rem space-y-2.938rem max-w-10.688rem "}>
                                <div className={""}>
                                    <GSmallLabel color={"text-gray-300"} className={"text-0.875rem"}>{t("clinic.name")}</GSmallLabel>
                                    <GSmallLabel color={"text-gray-500"} className={"text-0.875rem"}>{editClinicInfo.ClinicName}</GSmallLabel>
                                </div>
                                <div className={""}>
                                    <GSmallLabel color={"text-gray-300"} className={"text-0.875rem"}>{t("clinic.address")}</GSmallLabel>
                                    <GSmallLabel color={"text-gray-500"} className={"text-0.875rem"}>{editClinicInfo.Location?.Address}</GSmallLabel>
                                </div>
                                <div className={""}>
                                    <GSmallLabel color={"text-gray-300"} className={"text-0.875rem"}>{t("clinic.phone")}</GSmallLabel>
                                    <GSmallLabel color={"text-gray-500"} className={"text-0.875rem"}>{editClinicInfo.ClinicPhone}</GSmallLabel>
                                </div>
                            </div>
                        </div>
                        <div className={"lg:w-3/4 sm:pr-2.813rem pt-3.125rem pb-3.125rem pl-2 pr-2 sm:pl-2.813rem "}>
                            <div className={"flex md:mb-2.313rem space-x-2"}>
                                <GBigLabel  className={" sm:pl-2.813rem w-full text-1.375rem"}>{t("clinic.users")}</GBigLabel>
                                {
                                    BackendCurrent.context.role.indexOf("SiteAdmin") > -1
                                        ?
                                        <>
                                            <GButton onClick={() => setAddNewUserModal(!addNewUserModal)}
                                                     className={"hidden lg:block whitespace-nowrap pl-2 pr-2 pt-1 pb-1"}>
                                                + {t("clinic.buttons.add-user")}
                                            </GButton>

                                            <GButton onClick={() => setAddNewUserModal(!addNewUserModal)}
                                                     className={"lg:hidden block whitespace-nowrap w-10 "}>
                                                +
                                            </GButton>
                                        </>
                                        :
                                        null
                                }
                            </div>
                            <div className={"w-full sm:pl-2.813rem sm:pr-2.813rem"}>
                                {
                                    users.map(user=>
                                        <div className={user.IsEnabled ? "" : "bg-gray-200"}>
                                            <div className={"visible lg:hidden border rounded-xl flex p-2 mt-2 relative"}>
                                                <div  className={" mt-auto mb-auto ml-2 mr-2 " + (BackendCurrent.context.role.indexOf("SiteAdmin") > -1 ? " cursor-pointer ":" ")}>
                                                    <GInitialsIcon user={user} isClinicalUser={true} isAdmin={user.role.filter(x=>x === "Admin").length > 0}/>
                                                </div>
                                                <div >
                                                    <div className={" space-y-4  w-60"}>
                                                        <div className={"  md:ml-2 md:flex md:justify-start "}>
                                                            <GSmallLabel className={"md:text-start md:w-1/2 pl-1 whitespace-nowrap"} color={"text-gray-400"}>{t("clinic.first-name")}</GSmallLabel>
                                                            <GSmallLabel className={"md:text-start md:w-1/2 pl-1 whitespace-nowrap"} color={"text-gray-700"}>{user.firstName}</GSmallLabel>
                                                        </div>
                                                        <div className={"  md:ml-2 md:flex md:justify-start"}>
                                                            <GSmallLabel className={"md:text-start md:w-1/2 pl-1 whitespace-nowrap"} color={"text-gray-400"}>{t("clinic.last-name")}</GSmallLabel>
                                                            <GSmallLabel className={"md:text-start md:w-1/2 pl-1 whitespace-nowrap"}  color={"text-gray-700"}>{user.lastName}</GSmallLabel>
                                                        </div>
                                                        <div className={"  md:ml-2 md:flex md:justify-start"}>
                                                            <GSmallLabel className={"md:text-start md:w-1/2 pl-1 whitespace-nowrap"} color={"text-gray-400"}>{t("clinic.email")}</GSmallLabel>
                                                            <GSmallLabel className={"md:text-start md:w-1/2 pl-1 whitespace-nowrap"}  color={"text-gray-700"}>{user.email}</GSmallLabel>
                                                        </div>
                                                        <div className={"  md:ml-2 lg:flex  md:justify-start"}>
                                                            <GSmallLabel className={"md:text-start md:w-1/2 pl-1"} color={"text-gray-400"}>{t("clinic.role")}</GSmallLabel>
                                                            {
                                                                BackendCurrent.context.role.indexOf("SiteAdmin") > -1
                                                                ?
                                                                    <GStyledSelect itemsStyle={" pl-7 "} selected={user.role.length === 2 ?  "Admin" : "User"} className={"text-gray-700 lg:w-full"}  onChange={(e)=>{
                                                                        SetSelectedUser(user)
                                                                        UpdateSelectedUser("role",e,user)
                                                                    }} series={["Admin","User"]}/>
                                                                :
                                                                    <GSmallLabel
                                                                        color={"text-gray-700"}>{user.role.length === 2 ? "Admin" : "User"}</GSmallLabel>
                                                            }

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={"flex cursor-pointer mt-auto mb-auto absolute right-2 "}>
                                                    <div className={"flex "}>
                                                        {
                                                            BackendCurrent.context.role.indexOf("SiteAdmin") > -1
                                                            ?
                                                                user.role[0].indexOf("Admin") === -1
                                                                ?
                                                                    <img onClick={()=>{
                                                                        SetSelectedUser(user)
                                                                        setRemoveUserModal(!removeUserModal)
                                                                    }} className={"cursor-pointer w-10 h-10"} src={user.IsEnabled ? trash : roundArrow} alt={"trash"}/>
                                                                :
                                                                    null
                                                            :
                                                                null
                                                        }
                                                        {
                                                            BackendCurrent.context.role.indexOf("SiteAdmin") > -1
                                                            ?
                                                                <img onClick={()=>{
                                                                    SetSelectedUser(user)
                                                                    setEditUserModal(!editUserModal)
                                                                }}
                                                                     className={"cursor-pointer mt-auto mb-auto w-6 h-6"} src={pencil} alt={"edit"}/>
                                                            :
                                                                null
                                                        }

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }

                                        <table id={"users-table"} className={"hidden lg:table w-full"} >
                                            <tr className={"w-full text-gray-400 text-0.875rem  h-4.25rem"}>
                                                <th onClick={()=>sortTable(0)} className={" cursor-pointer hidden lg:block"}>
                                                    {
                                                        viewThWithArrow("",0)
                                                    }
                                                </th>
                                                <th onClick={()=>sortTable(1)} className={" cursor-pointer "} >
                                                    {
                                                        viewThWithArrow(t("clinic.first-name"),1)
                                                    }
                                                </th>
                                                <th onClick={()=>sortTable(2)} className={" cursor-pointer "}>
                                                    {
                                                        viewThWithArrow(t("clinic.last-name"),2)
                                                    }
                                                </th>
                                                <th onClick={()=>sortTable(3)} className={" cursor-pointer "}>
                                                    {
                                                        viewThWithArrow(t("clinic.email"),3)
                                                    }
                                                </th>
                                                <th onClick={()=>sortTable(4)} className={" cursor-pointer "}>
                                                    {
                                                        viewThWithArrow(t("clinic.role"),4)
                                                    }
                                                </th>
                                                <th onClick={()=>sortTable(5)} className={" cursor-pointer "}>

                                                </th>
                                            </tr>
                                            {
                                                users.map(user =>
                                                    <tr  className={(user.IsEnabled ? "" : "bg-gray-200 ")+" border-b border-t h-4.25rem "}>
                                                        <td onClick={()=>{
                                                            if(BackendCurrent.context.role.indexOf("SiteAdmin") > -1) {
                                                                SetSelectedUser(user)
                                                                setEditUserModal(!editUserModal)
                                                            }
                                                        }} className={ (BackendCurrent.context.role.indexOf("SiteAdmin") > -1) ? " cursor-pointer" : " "}>
                                                            <GInitialsIcon
                                                                user={user}
                                                                isClinicalUser={true}
                                                                isAdmin={user.role.filter(x=>x === "Admin").length > 0}
                                                            />
                                                        </td>
                                                        <td>
                                                            <GSmallLabel color={"text-gray-700"}>{user.firstName}</GSmallLabel>
                                                        </td>
                                                        <td>
                                                            <GSmallLabel color={"text-gray-700"}>{user.lastName}</GSmallLabel>
                                                        </td>
                                                        <td>
                                                            <GSmallLabel
                                                                color={"text-gray-700"}>{user.email}</GSmallLabel>
                                                        </td>
                                                        <td>
                                                            {
                                                                BackendCurrent.context.role.indexOf("SiteAdmin") > -1
                                                                    ?
                                                                    <GStyledSelect itemsStyle={" pl-1 pr-1 "}
                                                                                   selected={user.role.length === 2 ? "Admin" : "User"}
                                                                                   className={" text-gray-700 h-2.375rem "}
                                                                                   onChange={(e) => {
                                                                                       SetSelectedUser(user)
                                                                                       UpdateSelectedUser("role", e, user)
                                                                                   }}
                                                                                   series={["Admin", "User"]}/>
                                                                    :
                                                                    <GSmallLabel
                                                                        color={"text-gray-700"}>{user.role.length === 2 ? "Admin" : "User"}</GSmallLabel>
                                                            }
                                                        </td>
                                                        <td >
                                                            <div className={"flex space-x-2 w-10"}>
                                                                {
                                                                    BackendCurrent.context.role.indexOf("SiteAdmin") > -1
                                                                        ?
                                                                        <>
                                                                            <img onClick={() => {
                                                                            SetSelectedUser(user)
                                                                            setEditUserModal(!editUserModal)
                                                                        }}
                                                                               className={"cursor-pointer mt-auto mb-auto w-6 h-6"}
                                                                               src={pencil} alt={"edit"}/>
                                                                            {
                                                                                user.role[0].indexOf("Admin") === -1
                                                                                    ?
                                                                                    <img onClick={() => {
                                                                                        SetSelectedUser(user)
                                                                                        setRemoveUserModal(!removeUserModal)
                                                                                    }}
                                                                                         className={"cursor-pointer w-10 h-10"}
                                                                                         src={user.IsEnabled ? trash : roundArrow}
                                                                                         alt={"trash"}/>
                                                                                    :
                                                                                    null
                                                                            }
                                                                        </>

                                                                    :
                                                                    null
                                                                }
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        </table>
                            </div>

                        </div>
                    </div>

                </div>
            </div>

        </div>

    )
}
export default ClinicDetails;
