import React, {useState} from 'react';
import GSmallLabel from "./GSmallLabel";

const GSelectLabel = (props) => {

    let commonStyle = "h-14 pl-0.938rem pr-0.938rem"
    let fontStyle = ""
    let borderStyle = "";
    const [DropDownIsOpen,toggleDropDown] = useState(false);
    const [selectedItem,setSelectedItem] = useState("")
    return (
        <div tabIndex="0"   onBlur={()=>toggleDropDown(false)} className={"bg-white  select-none "+ props.className}>
            {
                props.icon
                ?
                    <div onClick={()=>toggleDropDown(!DropDownIsOpen)}>
                        {
                            props.icon
                        }
                    </div>
                :
                    <div className={" cursor-pointer flex space-x-0.456rem"} onClick={()=>toggleDropDown(!DropDownIsOpen)}>
                        <GSmallLabel className={""} >
                            {
                                props.placeholder
                            }
                        </GSmallLabel>
                        <div className={"mt-auto mb-auto"}>
                            <svg className={DropDownIsOpen ? "  " :" transform rotate-180 " } width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1.30273 5.77149L6.0371 1.03711L10.7715 5.77148" stroke="#666767" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </div>
                    </div>
            }



            {
                DropDownIsOpen
                ?
                    <div className={"shadow absolute cursor-pointer pb-2.5 pt-2.5 rounded-lg bg-white"}>
                        {
                            props.series.map((s, i) =>
                                <div
                                    onClick={()=>{
                                        setSelectedItem(s.label)
                                        s.handler()
                                    }}
                                    className={"pl-7  h-2.75rem flex hover:bg-#1396B41A "}>
                                    <GSmallLabel color={(selectedItem === s.label ? "text-#50C2BC " : " text-#666767 ")}  className={(selectedItem === s.label ? " font-ghotamBold " : " ") + " mt-auto mb-auto mr-6"} key={"s-opt-" + i} value={s.label}>{s.label}</GSmallLabel>
                                </div>
                            )
                        }
                    </div>
                :null
            }
        </div>

    )
};
export default GSelectLabel;
