import React, { Component } from 'react'
import '../../style/Admin/TrialOverallProgressPage.css'
// import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

import ProgressBar from "react-bootstrap/ProgressBar";
import axios from "axios";
import Config from "../../services/Config";

class TrialOverallProgressPage extends Component {

    constructor(props) {
        super(props);
        this.state={
            data:[],
            globalTrialData: [
                { Name: 'bacterialVaginosis', My: 0, TotalProgress: 0, Target: 0 },
                { Name: 'candidaAlbicans', my: 0, TotalProgress: 0, Target: 0 },
                { Name: 'candidaNonAlbicans', my: 0, TotalProgress: 0, Target: 0 },
                { Name: 'trichomoniasis', my: 0, TotalProgress: 0, Target: 0 },
                { Name: 'atrophicVaginitis', my: 0, TotalProgress: 0, Target: 0 },
                { Name: 'purulentVaginitis', my: 0, TotalProgress: 0, Target: 0 },
                { Name: 'normal', my: 0, TotalProgress: 0, Target: 0 }
            ],
        }
    }
    async componentDidMount() {
        await axios.get(`${Config.globalUrl}:${Config.globalPort}/api/v1/webapp/general/${Config.UserID}/getClinicalTrailData`,Config.token)
            .then(async (response) => {

                response.data.forEach(lab => {
                    var myProgress = lab.DoctorsProgress.filter(x => x.UserID === Config.UserID)[0];
                    if (myProgress) {
                        lab.My = myProgress.ProgressVal;
                    } else {
                        lab.My = 0;
                    }
                    lab.TotalProgress = 0;
                    lab.DoctorsProgress.forEach(doctorProgress=>{
                        lab.TotalProgress += doctorProgress.ProgressVal;
                    })
                });

                await this.setState({ globalTrialData: response.data });


                let maxTrialTarget = Math.max.apply(Math, this.state.globalTrialData.map(function (o) { return o.Target; })); //get maximum target
                var chartValues = this.prepareChartXAxisValues(maxTrialTarget);
                await this.setState({ //set state and update view
                    globalTrialDataMaxValue: maxTrialTarget,
                    chartXAxisValues: chartValues
                });

                // await this.setState({globalTrialData:response.data});


            })
            .catch( async (error) => {
                console.log(error);
            });
    }
    prepareChartXAxisValues(maxval) {
        let _temp = [];
        let _part = maxval / 5;
        if (_part !== 0) {
            for (let i = 0; i <= maxval; i += _part) {
                _temp.push(i);
            }
        }
        return _temp;
    }
    static setBarOpacity(Val){
        switch (Val) {
            case 0:
            default:
                return "1.0";
            case 1:
                return "0.6";
            case 2:
                return "0.8";
        }
    }
    static getBarColor(iter){
        let officialChartColors = [
            '#F78B5D',
            '#EF9F65',
            '#F4C856',
            '#9870EC',
            '#3DD2DB',
            '#3D8FDB',
            '#34BD84',
        ];
        let result = officialChartColors[iter];
        if(iter >= officialChartColors.length){
            result = '#'+Math.floor(Math.random()*16777215).toString(16);
        }
        return result;
    }
    hideAnotherDoctorsPb(d){
    }
    static invertColor(hex) {
        if (hex.indexOf('#') === 0) {
            hex = hex.slice(1);
        }
        // convert 3-digit hex to 6-digits.
        if (hex.length === 3) {
            hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
        }
        if (hex.length !== 6) {

        }
        // invert color components
        var r = (255 - parseInt(hex.slice(0, 2), 16)).toString(16),
            g = (255 - parseInt(hex.slice(2, 4), 16)).toString(16),
            b = (255 - parseInt(hex.slice(4, 6), 16)).toString(16);
        // pad each with zeros and return
        return '#' + TrialOverallProgressPage.padZero(r) + TrialOverallProgressPage.padZero(g) + TrialOverallProgressPage.padZero(b);
    }

    static padZero(str, len) {
        len = len || 2;
        var zeros = new Array(len).join('0');
        return (zeros + str).slice(-len);
    }
    render() {

        const uniqueDoctors = [];

        const getDoctorProgress = function(d,ite){

            ite = 100;
            var d_color = '';
            d.docColor = TrialOverallProgressPage.getBarColor(ite);
            d_color = d.docColor;
            d.ite = ite;
            if(uniqueDoctors.filter(x=>x.UserID === d.UserID).length === 0){
                uniqueDoctors.push(d);
            }else{
                d_color = uniqueDoctors.filter(x=>x.UserID === d.UserID)[0].docColor;
            }

            return (<ProgressBar label={`${d.ProgressVal}`} style={{ fontSize:'10px' ,color:'black',backgroundColor:d_color , opacity:TrialOverallProgressPage.setBarOpacity(1)}} max={100-d.ProgressVal} now={d.ProgressVal} key={d.UserID+'pb'+ite}/>)

            // return (<ProgressBar label={`${d.ProgressVal}`} style={{ fontSize:'16px' ,color:TrialOverallProgressPage.invertColor(d_color),backgroundColor : d_color ,opacity:TrialOverallProgressPage.setBarOpacity(1)}} max={100-d.ProgressVal} now={d.ProgressVal} key={d.UserID+'pb'+ite}/>)
        };
        return (
            <div>
                <label className="title">
                    Trial Overall Progress
                </label>
                <div className = 'graphic' style={{zoom:'100%'}} ref={'graph'}>
                {
                    this.state.globalTrialData.map((element,iter)=>
                        <div key={iter} className='bar-chart-entity2'>
                            <div className='chart-name-label-container'>
                                <label key ={element.Name}>{element.Name}</label>
                            </div>
                            <div className='pb'>
                                <div className='chart-info-label-container-ph'/>
                                <div>
                                    <ProgressBar key={5} className='chart-entity-back'>
                                        {
                                            element.DoctorsProgress?
                                            element.DoctorsProgress.map((d,ite)=>
                                                    getDoctorProgress(d,ite)
                                                )
                                                :
                                                <div></div>
                                        }
                                        <ProgressBar style={{backgroundColor:`${TrialOverallProgressPage.getBarColor(iter)}`,opacity:TrialOverallProgressPage.setBarOpacity(2)}} max={100} now={(element.Target)} key={iter} />
                                    </ProgressBar>
                                </div>
                            </div>
                            <div className='chart-info-label-container'>
                                {/*{iter===0?<label>From you</label>:<div className='chart-info-label-container-ph'/>}*/}
                                {/*<label style={{color : TrialOverallProgressPage.getBarColor(iter)}}>{element.My}</label>*/}
                            </div>
                            <div className='chart-info-label-container'>
                                {iter===0?<label>Progress</label>:<div className='chart-info-label-container-ph'/>}
                                <label style={{color : TrialOverallProgressPage.getBarColor(iter)}}>{element.TotalProgress}</label>
                            </div>
                            <div className='chart-info-label-container'>
                                {iter===0?<label>Target</label>:<div className='chart-info-label-container-ph'/>}
                                <label style={{color : TrialOverallProgressPage.getBarColor(iter)}}>{element.Target}</label>
                            </div>
                        </div>)
                }
                <div className='chart-legend'>
                    {
                        uniqueDoctors.map(doc=>
                            <div onClick={this.hideAnotherDoctorsPb.bind(this,doc)}
                                style={{
                                display:'inline-flex',
                                lineHeight: '0.5',
                                margin: '3%'
                            }}> <div style={{
                                backgroundColor:doc.docColor,
                                borderRadius:'50%',
                                height: '10px',
                                width: '10px',

                            }} className={'chart-legend-circle'}/>{doc.UserID}</div>
                        )
                    }
                </div>
                <div className={'recolor-button'} onClick={this.componentDidMount.bind(this)}>Recolor</div>
            </div>
            </div>
        );
    }
}

export default TrialOverallProgressPage ;

