import React from 'react';
import Button from 'react-bootstrap/Button'
import '../style/ScrollButton.css'
import ReactDOM from 'react-dom'

class ScrollButton extends React.Component {
    constructor() {
      super();
  
      this.state = {
          intervalId: 0
      };

    }
    componentDidMount(){
        let br =  ReactDOM.findDOMNode(this.refs['buttonref']);
          window.onscroll = function() {
              var currentScrollPos = window.pageYOffset;
              if (currentScrollPos >100) {
                if(br) br.classList.remove('hidden');
              }else {
                if(br) br.classList.add('hidden');
              }
          }
    }
    scrollStep() {
      if (window.pageYOffset === 0) {
          clearInterval(this.state.intervalId);
      }
      window.scroll(0, window.pageYOffset - this.props.scrollStepInPx);
    }
    
    scrollToTop() {
      let intervalId = setInterval(this.scrollStep.bind(this), this.props.delayInMs);
      this.setState({ intervalId: intervalId });
    }
    
    render () {
        return <Button ref={'buttonref'} title='Back to top' className='scroll hidden' 
                 onClick={ () => { this.scrollToTop(); }}>
                  <label className='scroll-button-up'>UP</label>
                </Button>;
     }
  } 
  export default ScrollButton;